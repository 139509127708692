import axios from 'axios';

export class DailyBonusService {
  async fetchAll() {
    return (await axios.get('/daily-awards')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/daily-awards/' + id)).data;
  }

  async update(prizeInfo) {
    return (await axios.patch('/daily-awards', prizeInfo)).data;
  }

  async create(prizeInfo) {
    return (await axios.post('/daily-awards', prizeInfo)).data;
  }

  async delete(user) {
    return (await axios.delete('/users/' + user.id)).data;
  }
}
