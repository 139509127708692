import Vue from 'vue';
import VueRouter from 'vue-router';
import SignInView from './views/auth/SignIn';
import NotFound from './views/404.vue';
import Dashboard from './components/Dashboard';

import Stats from './views/stats.vue';

import { usersRouter } from './views/users/router';
import { notificationsRouter } from './views/notifications/router';
import { questionsRouter } from './views/questions/router';
import { eventsRouter } from './views/events/router';
import { offersRouter } from './views/offers/router';
import { flashcardsRouter } from './views/flashcards/router';
import { roadmapRouter } from '@/views/roadmap/router';
import { institutionsRouter } from './views/institutions/router';
import { factsRouter } from './views/facts/router';
import { promoReferRouter } from './views/promo_refer/router';
import { scheduledQOTDRouter } from '@/views/scheduled_qotd/router';
import { studentGroupsRouter } from './views/student_groups/router';
import { videoSessionRouter } from '@/views/video_session/router';
import { popupNotificationsRouter } from './views/popup_notifications/router';
import { passagePromptsRouter } from './views/passage_prompts/router';
import Media from './views/media/Media';
import { getUser } from './utils/local';
import Settings from './views/settings';
import Home from '@/views/Home.vue';
import QuestionStatsView from './views/questionStats/QuestionStatsView.vue';
import AdminUserInvite from './views/invite/AdminUserInvite.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: '/',
        component: Home
      },
      ...usersRouter,
      ...notificationsRouter,
      ...questionsRouter,
      ...eventsRouter,
      ...offersRouter,
      ...flashcardsRouter,
      ...roadmapRouter,
      ...institutionsRouter,
      ...factsRouter,
      ...promoReferRouter,
      ...scheduledQOTDRouter,
      ...videoSessionRouter,
      ...studentGroupsRouter,
      ...popupNotificationsRouter,
      ...passagePromptsRouter,

      // Media Route
      ...[
        getUser()?.scopes.includes('media:view')
          ? {
              name: 'media',
              path: '/media',
              component: Media
            }
          : null,
        getUser()?.scopes.includes('stats:view') ||
        getUser()?.scopes.includes('studentGroups:view')
          ? {
              name: 'stats',
              path: '/stats',
              component: Stats
            }
          : null,
        //TODO : Check component scopes
        getUser()?.scopes.includes('stats:view')
          ? {
              name: 'question-stats',
              path: '/question-stats',
              component: QuestionStatsView
            }
          : null,
        getUser()?.scopes.includes('settings:view')
          ? {
              name: 'settings',
              path: '/settings',
              component: Settings
            }
          : null
      ].filter((route) => route)
    ]
  },
  {
    name: 'SignIn',
    path: '/auth/sign-in',
    component: SignInView
  },
  {
    name: 'AdminUserInvite',
    path: '/auth/invite',
    component: AdminUserInvite
  },
  {
    name: 'NotFound',
    path: '**',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, __, next) => {
  if (!localStorage.getItem('auth_token') && to.path !== '/auth/invite') {
    if (to.path !== '/auth/sign-in') {
      next('/auth/sign-in');
    }
  }

  next();
});

export default router;
