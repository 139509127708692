var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-tabs',{staticClass:"mb-4",attrs:{"color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.types),function(type,index){return _c('v-tab',{key:index},[_vm._v(" "+_vm._s(type)+" ")])}),1),_c('data-table',{key:_vm.tab,attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Categories","allow-add":true,"delete-handler":null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('categories:edit')
          ? _vm.edit
          : null
        : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"subCategories",fn:function(ref){
        var item = ref.item;
return [(item.subCategories && item.subCategories.length > 0)?_c('span',[_vm._v(" "+_vm._s(item.subCategories.map(function (e) { return e.name; }).join(', '))+" ")]):_c('span',{staticStyle:{"color":"#aaa","font-style":"italic"}},[_vm._v(" No Sub Categories ")])]}},{key:"restrictedModes",fn:function(ref){
        var item = ref.item;
return [(item.restrictedModes && item.restrictedModes.length > 0)?_c('div',_vm._l((item.restrictedModes),function(mode,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"primary","outlined":"","small":""}},[_vm._v(" "+_vm._s(mode)+" ")])}),1):_c('v-chip',{staticClass:"ma-1",attrs:{"color":"primary","outlined":"","small":""}},[_vm._v(" No Restricted Modes ")])]}},{key:"isMultiplayer",fn:function(ref){
        var item = ref.item;
return [(item.isMultiplayer)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-check-bold ")]):_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v(" mdi-close ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }