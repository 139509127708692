<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" @change="filterSelectedAttr = { category: '' }">
        <v-tab>Active Categories</v-tab>
        <v-tab>InActive Categories</v-tab>
      </v-tabs>

      <data-table
        :loader="loadData"
        :headers="headers"
        title="Flashcards"
        :allow-add="
          getUser()
            ? getUser().scopes.includes('roadmap-flashcards:view')
            : false
        "
        @add-new="addNew"
        @done="$router.back()"
        allow-filters
        @filter="openFilter"
        :delete-handler="
          getUser()
            ? getUser().scopes.includes('roadmap-flashcards:delete')
              ? service.deleteFlashcard
              : null
            : null
        "
        :edit-handler="
          getUser()
            ? getUser().scopes.includes('roadmap-flashcards:edit')
              ? edit
              : null
            : null
        "
        :key="dataTableKey"
      >
      </data-table>
    </v-card>

    <v-dialog v-model="showFilter" persistent max-width="500">
      <v-card>
        <v-card-title>Filter By Field</v-card-title>
        <div class="px-6 pt-2 pb-4">
          <v-select
            :items="categories"
            v-model="filterSelectedAttr.category"
            clearable
            item-text="name"
            item-value="id"
            dense
            label="Category"
            outlined
            placeholder="Select Category"
          />

          <div class="d-flex justify-space-between">
            <v-btn class="mr-2" @click="cancelFilter" elevation="0"
              >Cancel</v-btn
            >
            <div>
              <v-btn class="mr-2" @click="filterClear" color="primary" outlined
                >Clear</v-btn
              >
              <v-btn
                class="ml-2"
                color="primary"
                @click="applyFilter"
                elevation="0"
                >Apply</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getUser } from '@/utils/local';
import DataTable from '@/components/DataTable.vue';
import { RoadmapModeService } from '@/services/roadmap-mode-service';
import { CategoryService } from '@/services/category-service';

export default {
  name: 'FlashcardView',

  components: { DataTable },

  mounted() {},

  data: () => ({
    dataTableKey: 0,
    tab: 0,
    items: [],
    loading: false,
    service: new RoadmapModeService(),
    categoryService: new CategoryService(),
    categories: [],
    showFilter: false,
    filterQuery: '',
    filterSelectedAttr: {
      category: ''
    },

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Category',
        value: 'category',
        sortable: true
      }
    ]
  }),

  watch: {
    tab: {
      handler() {
        this.dataTableKey++;
      }
    }
  },

  methods: {
    getUser,

    addNew() {
      this.$router.push(
        '/roadmap-flashcard' + (this.tab === 1 ? '?isActiveCategory=false' : '')
      );
    },

    edit(item) {
      this.$router.push(
        `/roadmap-flashcard?id=${item._id}${
          this.tab === 1 ? '&isActiveCategory=false' : ''
        }`
      );
    },

    getCategoryName(id) {
      let category = this.categories.find((category) => {
        return category.id === id;
      });
      return category ? category.name : '';
    },

    async loadData() {
      this.categories =
        this.tab === 0
          ? await this.categoryService.fetchAll()
          : await this.categoryService.fetchAllInactive();
      this.generateFilterQuery();

      let data = await this.service.fetchAllFlashcard(this.filterQuery);
      return data.map((item) => {
        return {
          ...item,
          category: this.getCategoryName(item.category)
        };
      });
    },

    generateFilterQuery() {
      let query = {};
      if (this.filterSelectedAttr.category !== '') {
        query['category'] = this.filterSelectedAttr.category;
      }

      if (this.tab === 1) {
        query['active'] = 0;
      }

      this.filterQuery = query;
    },

    async applyFilter() {
      this.showFilter = false;
      this.generateFilterQuery();
      this.dataTableKey++;
    },

    async filterClear() {
      this.filterSelectedAttr = {
        category: ''
      };
      await this.applyFilter();
    },

    openFilter() {
      this.showFilter = true;
    },

    cancelFilter() {
      this.showFilter = false;
    }
  }
};
</script>

<style scoped></style>
