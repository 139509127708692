var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Passage Question Prompts","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('passage-prompts:new') : false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('passage-prompts:delete')
          ? _vm.service.delete
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('passage-prompts:edit')
          ? _vm.edit
          : null
        : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"prompt",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"800","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.prompt.length > 100 ? item.prompt.substring(0, 100) + '...' : item.prompt)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.prompt))])])]}}])}),_c('loading-dialog',{attrs:{"message":"Sending Notification"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }