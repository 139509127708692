var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Scheduled QOTD","allow-add":false,"delete-handler":null,"edit-handler":_vm.getUser() ? _vm.getUser().scopes.includes('scheduledQotd:edit') ? _vm.edit : null : null},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"day",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-black body-1"},[_vm._v(_vm._s(item.day))])]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category.name)+" ")]}},{key:"subCategory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subCategory.name)+" ")]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }