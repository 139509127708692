<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab>Active Categories</v-tab>
        <v-tab>InActive Categories</v-tab>
      </v-tabs>
      <data-table
        :loader="loadData"
        :headers="headers"
        title="Chapters"
        :allow-add="
          getUser() ? getUser().scopes.includes('roadmap-chapters:view') : false
        "
        @add-new="addNew"
        @done="$router.back()"
        allow-filters
        @filter="openFilter"
        :delete-handler="
          getUser()
            ? getUser().scopes.includes('roadmap-chapters:delete')
              ? service.delete
              : null
            : null
        "
        :edit-handler="
          getUser()
            ? getUser().scopes.includes('roadmap-chapters:edit')
              ? edit
              : null
            : null
        "
        :key="tab"
      >
        <template #category="{ item }">
          {{ getCategoryName(item.category) }}
        </template>
        <template #active="{ item }">
          <v-checkbox dense v-model="item.active" readonly hide-details />
        </template>
        <!-- <template #extra-actions="{ item }">
        <v-icon small color="primary" @click="openDialog(item)"
          >mdi-format-align-middle</v-icon
        >
      </template> -->
      </data-table>

      <v-dialog v-model="showRearragementDialog" width="400">
        <v-card style="padding: 40px">
          <v-form ref="rearragementDialog">
            <v-select
              v-model="newIndex"
              outlined
              dense
              label="Move Chapter at"
              :items="indexes"
              item-text="label"
              :rules="[required()]"
            >
            </v-select>

            <v-btn
              style="width: 100%"
              elevation="0"
              color="primary"
              @click="saveOrder"
              >Submit
            </v-btn>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showFilter" persistent width="40%">
        <v-card>
          <v-card-title>Filter By Field</v-card-title>
          <div class="px-6 pt-2 pb-6">
            <v-select
              :items="categories"
              v-model="filterSelectedAttr.category"
              clearable
              item-text="name"
              item-value="id"
              dense
              label="Category"
              outlined
              placeholder="Select Category"
            />

            <div class="d-flex justify-space-between">
              <v-btn class="mr-2" @click="cancelFilter">Cancel</v-btn>
              <div>
                <v-btn class="mr-2" @click="filterClear">Clear</v-btn>
                <v-btn class="ml-2" color="primary" @click="applyFilter"
                  >Apply
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <loading-dialog v-model="loading" message="Updating ..." />
    </v-card>
  </div>
</template>

<script>
import { getUser } from '@/utils/local';
import DataTable from '@/components/DataTable.vue';
import { RoadmapModeService } from '@/services/roadmap-mode-service';
import LoadingDialog from '@/components/LoadingDialog.vue';
import { required } from '@/utils/validators';
import { CategoryService } from '@/services/category-service';

export default {
  name: 'ChapterView',

  components: { DataTable, LoadingDialog },

  computed: {
    indexes() {
      return this.chapters.map((chapter, index) => {
        return {
          label: index + 1,
          value: index + 1
        };
      });
    }
  },

  data: () => ({
    items: [],
    loading: false,
    service: new RoadmapModeService(),
    categoryService: new CategoryService(),
    showRearragementDialog: false,
    tab: 0,
    selectedChapter: null,
    newIndex: null,
    chapters: [],
    categories: [],
    showFilter: false,
    filterQuery: {},
    filterSelectedAttr: {
      category: ''
    },

    headers: [
      {
        text: 'Category',
        value: 'category',
        sortable: true
      },
      {
        text: 'Chapter #',
        value: 'index',
        sortable: true
      },
      {
        text: 'Title',
        value: 'name',
        sortable: true
      },
      {
        width: 120,
        text: 'Active',
        value: 'active'
      }
    ]
  }),

  async mounted() {
    this.chapters = await this.loadData();
  },

  methods: {
    getUser,
    required,

    getCategoryName(id) {
      let category = this.categories.find((category) => {
        return category.id === id;
      });
      return category ? category.name : '';
    },

    async saveOrder(context) {
      this.loading = true;

      let swappedByChapter = this.chapters.find((chapter) => {
        return chapter.index === this.newIndex;
      });

      if (swappedByChapter) {
        swappedByChapter['index'] = this.selectedChapter.index;
      }
      this.selectedChapter.index = this.newIndex;

      try {
        if (swappedByChapter) {
          await this.service.update(swappedByChapter);
        }
        await this.service.update(this.selectedChapter);
        this.chapters = await this.loadData();

        document.getElementById('refresh').click();
      } catch (e) {
        context.reportError({
          title: 'Error occurred while updating Chapter order',
          description: e.toString()
        });
      }

      this.showRearragementDialog = false;
      this.loading = false;
    },

    async generateFilterQuery() {
      let query = {};
      if (this.filterSelectedAttr.category !== '') {
        query['category'] = this.filterSelectedAttr.category;
      }
      this.filterQuery = query;
    },

    async applyFilter() {
      this.showFilter = false;
      await this.generateFilterQuery();
      await this.loadData();
      this.refresh();
    },

    async filterClear() {
      this.filterSelectedAttr = {
        category: ''
      };
      await this.applyFilter();
    },

    openFilter() {
      this.showFilter = true;
    },

    cancelFilter() {
      this.showFilter = false;
    },

    openDialog(chapter) {
      this.selectedChapter = chapter;
      this.newIndex =
        this.chapters.findIndex((item) => {
          return item._id === chapter._id;
        }) + 1;
      this.showRearragementDialog = true;
    },

    addNew() {
      this.$router.push(
        '/roadmap-chapter' + (this.tab === 1 ? '?isActiveCategory=false' : '')
      );
    },

    edit(item) {
      this.$router.push(
        `/roadmap-chapter?id=${item._id}${
          this.tab === 1 ? '&isActiveCategory=false' : ''
        }`
      );
    },

    refresh() {
      document.getElementById('refresh').click();
    },

    async loadData() {
      if (this.tab === 0) {
        this.categories = await this.categoryService.fetchAll();

        if (this.filterQuery.category) {
          return (await this.service.fetchAll(this.filterQuery)).sort(
            (a, b) => {
              return a.index - b.index;
            }
          );
        }

        return await this.service.fetchAll(this.filterQuery);
      } else {
        this.categories = await this.categoryService.fetchAllInactive();

        if (this.filterQuery.category) {
          return (await this.service.fetchAllInactive(this.filterQuery)).sort(
            (a, b) => {
              return a.index - b.index;
            }
          );
        }

        return await this.service.fetchAllInactive(this.filterQuery);
      }
    }
  }
};
</script>

<style scoped></style>
