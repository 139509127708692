<template>
  <v-row justify="center">
    <v-dialog
      class="overflow-hidden"
      v-model="mediaPickerDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-show="false"
          v-on="on"
          :id="id"
          @click="resetSelectedEditMedia"
        >
          Open Media Picker
        </v-btn>
      </template>
      <v-card class="text-center rounded-0">
        <v-toolbar dark color="primary" elevation="0" dense>
          <!--          <v-btn-->
          <!--              icon-->
          <!--              dark-->
          <!--              @click="SetNewSelectedData"-->
          <!--          >-->
          <!--            <v-icon>mdi-close</v-icon>-->
          <!--          </v-btn>-->
          <v-toolbar-title>Select Media</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="SetNewSelectedData"> Done</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list class="px-4">
          <div class="d-flex justify-end pt-3 pb-1">
            <div class="d-flex">
              <div style="width: 400px; margin-right: 20px; max-width: 100%">
                <v-text-field
                  v-model="search"
                  solo
                  label="Search by Title ..."
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  @click:append="loadData"
                  @change="loadData"
                />
              </div>
              <v-btn
                v-if="!selectCompleteItem"
                @click="toggleModal"
                v-html="newModalOpen ? 'Close' : 'Upload'"
                color="primary"
              ></v-btn>
              <v-btn
                icon
                :style="{ marginLeft: !selectCompleteItem ? '10px' : '0px' }"
              >
                <v-icon @click="openFilter">mdi-filter</v-icon>
              </v-btn>
            </div>
          </div>

          <div
            v-show="newModalOpen"
            @drop.prevent="Drop"
            @dragleave.prevent="Dragleave"
            @dragover.prevent="DragOver"
            class="new-media-container"
          >
            <h3 id="header" class="mb-1">Drop files to upload</h3>
            <span class="mb-5">or</span>
            <input
              multiple
              id="file"
              @change="ChangeInput"
              type="file"
              style="display: none"
            />
            <label id="button" for="file" class="selectBtn">Select Files</label>
            <span class="mb-2 mt-10" style="font-size: 13px"
              >Maximum upload file size: 250MB</span
            >
          </div>

          <v-row class="mt-2" v-if="!loading && data && data.length > 0">
            <v-col :cols="selectedMedia && !selectCompleteItem ? 9 : 12">
              <v-row id="mainDiv" class="px-3 py-2">
                <v-col
                  class="col-6 col-sm-4 col-md-3 col-lg-2 d-flex flex-col items-center"
                  :key="index"
                  v-for="(item, index) in data"
                >
                  <div
                    class="d-flex flex-col items-center mediaCol"
                    :class="item.selected && 'selected'"
                    @click="openEditModal(item)"
                    style="width: 100%"
                  >
                    <div
                      v-if="item.type === 1"
                      style="background-color: white; width: 100%"
                      class="d-flex justify-center relative"
                    >
                      <v-icon
                        class="checkBox"
                        color="#1976D2"
                        v-if="item.selected"
                        >mdi-checkbox-marked
                      </v-icon>
                      <img
                        loading="lazy"
                        class="image"
                        style="object-fit: cover"
                        :src="item.url"
                        alt="Media Image"
                      />
                    </div>

                    <div
                      v-if="item.type === 2"
                      style="background-color: white; width: 100%"
                      class="d-flex justify-center relative"
                    >
                      <v-icon
                        class="checkBox"
                        color="#1976D2"
                        v-if="item.selected"
                        >mdi-checkbox-marked
                      </v-icon>

                      <img
                        loading="lazy"
                        class="image"
                        style="object-fit: cover"
                        :src="item.thumbnailUrl"
                        alt="Video Thumbnail"
                      />
                      <div
                        style="
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.3);
                        "
                      ></div>
                      <img
                        src="../../assets/play-icon.png"
                        class="play-icon"
                        alt=""
                      />
                    </div>

                    <div
                      class="d-flex justify-center relative"
                      :class="item.selected && 'selected'"
                      v-if="item.type === 3"
                      style="background-color: white; width: 100%"
                    >
                      <v-icon
                        class="checkBox"
                        color="#1976D2"
                        v-if="item.selected"
                        >mdi-checkbox-marked
                      </v-icon>
                      <v-icon size="60" class="image" color="#4396e4">
                        mdi-file-document
                      </v-icon>
                    </div>

                    <h5 class="font-weight-light fileName">
                      {{ item.title ? item.title : '----------' }}
                    </h5>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="selectedMedia && !selectCompleteItem" cols="3">
              <v-row class="ma-0">
                <v-col cols="12">
                  <v-text-field
                    hide-details="true"
                    dense
                    outlined
                    label="Title"
                    v-if="selectedEditMedia"
                    v-model="selectedEditMedia.title"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    hide-details="true"
                    dense
                    outlined
                    label="Description"
                    v-if="selectedEditMedia"
                    v-model="selectedEditMedia.description"
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    dense
                    hide-details="true"
                    :items="categories.map((item) => item.name)"
                    label="Category"
                    placeholder="Category"
                    :value="selectedEditMedia.category"
                    @change="selectCategory"
                    clearable
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    dense
                    hide-details="true"
                    :items="subcategories"
                    label="Sub-category"
                    placeholder="Sub-category"
                    :value="selectedEditMedia.sub_category"
                    @change="selectSubCategory"
                    clearable
                    outlined
                  >
                    <template v-slot:no-data>
                      <div class="px-2">
                        <p
                          class="ma-0 text-caption"
                          v-if="selectedEditMedia.category"
                        >
                          No data available
                        </p>
                        <p class="ma-0 text-caption" v-else>
                          Select Category First
                        </p>
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" class="text-end">
                  <!--                  <v-btn-->
                  <!--                      outlined-->
                  <!--                      class="mr-2"-->
                  <!--                      color="red darken-1"-->
                  <!--                      @click="deleteMedia"-->
                  <!--                  >-->
                  <!--                    Delete-->
                  <!--                  </v-btn>-->

                  <a
                    v-show="false"
                    id="link"
                    :href="selectedMedia.url"
                    target="_blank"
                  />

                  <v-btn
                    outlined
                    class="mr-2 ml-2"
                    color="primary darken-1"
                    @click="openInNewTab"
                  >
                    Preview in new Tab
                  </v-btn>
                  <v-btn
                    elevation="0"
                    class="ml-2"
                    color="primary"
                    @click="update"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row id="mainDiv" class="mt-2" v-if="loading">
            <v-col cols="12" sm="4" md="3" lg="2" v-for="i in 18" :key="i">
              <v-skeleton-loader type="image" />
            </v-col>
          </v-row>

          <span
            v-if="!loading && data && data.length <= 0"
            id="mainDiv"
            class="mx-auto d-block py-3"
            >No Media Found</span
          >

          <div class="d-flex justify-end mt-8">
            <div>
              <v-btn
                @click="loadMoreMedia(false)"
                :disabled="currentPage === 0 || data.length <= 0"
                class="mx-2"
                color="primary"
                >Prev
              </v-btn>
              <v-btn
                @click="loadMoreMedia(true)"
                :disabled="currentPage === totalPages || data.length <= 0"
                class="mx-2"
                color="primary"
                >Next
              </v-btn>
            </div>
          </div>
        </v-list>
      </v-card>

      <v-dialog persistent width="40%" v-model="showFilter">
        <v-card>
          <v-card-title>Filter By Field</v-card-title>
          <div class="px-6 pt-2 pb-6">
            <v-select
              dense
              :items="filterAttr.category.map((item) => item.name)"
              label="Category"
              placeholder="Category"
              :value="filterSelectedAttr.category"
              @change="selectFilterCategory"
              clearable
              outlined
            />

            <v-select
              dense
              :items="filterAttr.subcategory"
              label="Sub-category"
              placeholder="Sub-category"
              :value="filterSelectedAttr.subcategory"
              @change="selectFilterSubCategory"
              clearable
              outlined
            >
              <template v-slot:no-data>
                <div class="px-2">
                  <p
                    class="ma-0 text-caption"
                    v-if="filterSelectedAttr.category"
                  >
                    No data available
                  </p>
                  <p class="ma-0 text-caption" v-else>Select Category First</p>
                </div>
              </template>
            </v-select>

            <v-select
              dense
              v-if="!allowedType && !selectCompleteItem"
              :items="filterAttr.type"
              label="Type"
              :value="filterSelectedAttr.type"
              @change="selectFilterType"
              clearable
              outlined
            />

            <div class="d-flex justify-space-between">
              <v-btn @click="cancelFilter" class="mr-2">Cancel</v-btn>
              <div>
                <v-btn @click="filterClear" class="mr-2">Clear</v-btn>
                <v-btn @click="applyFilter" class="ml-2" color="primary"
                  >Apply
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <loading-dialog v-model="loadingDialog" :message="loaderMessage" />
    </v-dialog>
  </v-row>
</template>

<script>
import { MediaGalleryService } from '@/services/media-gallery-service';
import { storage } from '@/plugins/firebase';
import LoadingDialog from '@/components/LoadingDialog';
import { CategoryService } from '@/services/category-service';
import { getVideoDuration } from '@/utils/local';

export default {
  name: 'MediaPicker',
  props: {
    id: {
      type: String,
      default: 'mediaPickerBtn'
    },
    selectedData: Object,
    isActive: {
      type: Boolean,
      default: true
    },
    allowedType: String,
    limit: Number,
    selectCompleteItem: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoadingDialog
  },
  data() {
    return {
      loadingDialog: false,
      queryData: [],
      loading: false,
      showFilter: false,
      search: '',
      filterQuery: '',
      categories: [],
      subcategories: [],
      loaderMessage: 'Loading ...',
      newModalOpen: false,
      dialog: false,
      mediaPickerDialog: false,
      service: new MediaGalleryService(),
      categoryService: new CategoryService(),
      selectedMedia: null,
      selectedEditMedia: null,
      filterAttr: {
        category: [],
        subcategory: [],
        type: ['Images', 'Videos', 'PDFs']
      },
      filterSelectedAttr: {
        category: '',
        subcategory: '',
        type: !this.selectCompleteItem ? null : 'Videos'
      },
      data: [],
      totalPages: 1,
      currentPage: 0,
      perPage: 18
    };
  },

  watch: {
    async mediaPickerDialog(opened) {
      if (opened === true) {
        await this.terminateRequests();
        await this.loadDataOnMount();
      }
    },

    isActive() {
      this.loadCategories();
    }
  },

  async mounted() {
    if (this.allowedType !== '') {
      if (this.allowedType === 'image') {
        this.filterSelectedAttr.type = 'Images';
      } else if (this.allowedType === 'video') {
        this.filterSelectedAttr.type = 'Videos';
      } else if (this.allowedType === 'pdf') {
        this.filterSelectedAttr.type = 'PDFs';
      }
      this.generateFilterQuery();
    }

    this.loadCategories();
  },

  methods: {
    getVideoDuration,
    // ---------------------------------------------------------------------------------------------------------
    // ----------------------------------Drag & Drop Upload-----------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------

    Drop(e) {
      this.uploadFile(e.dataTransfer.files);
    },

    Dragleave() {
      document.getElementById('header').textContent = 'Drop files to upload';
    },

    DragOver() {
      document.getElementById('header').textContent = 'Drop your files here';
    },

    ChangeInput(e) {
      this.uploadFile(e.target.files);
    },

    // ---------------------------------------------------------------------------------------------------------
    // ----------------------------------Drag & Drop Upload-----------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------

    async loadDataOnMount() {
      this.loading = true;
      this.queryData = [
        ...this.selectedData.images,
        ...this.selectedData.videos,
        ...this.selectedData.pdfs
      ];
      await this.loadData();
      this.loading = false;
    },

    SetNewSelectedData() {
      const data = {
        images: this.selectedData.images,
        videos: this.selectedData.videos,
        pdfs: this.selectedData.pdfs
      };

      if (this.selectCompleteItem) data.media = this.selectedData.media;
      this.$emit('SetNewSelectedData', data);

      this.mediaPickerDialog = false;
    },

    selectCategory(value) {
      if (!value) {
        this.subcategories = [];
        this.selectedEditMedia.category = '';
        this.selectedEditMedia.sub_category = '';
      } else {
        this.subcategories = this.categories
          .find((item) => item.name === value)
          .subCategories.map((item) => item.name);
        this.selectedEditMedia.category = value;
      }
    },

    selectSubCategory(value) {
      if (value) {
        this.selectedEditMedia.sub_category = value;
      } else {
        this.selectedEditMedia.sub_category = '';
      }
    },

    selectFilterCategory(value) {
      if (!value) {
        this.filterAttr.subcategory = [];
        this.filterSelectedAttr.category = '';
        this.filterSelectedAttr.subcategory = '';
      } else {
        this.filterAttr.subcategory = this.filterAttr.category
          .find((item) => item.name === value)
          .subCategories.map((item) => item.name);
        this.filterSelectedAttr.category = value;
      }
    },

    selectFilterSubCategory(value) {
      if (value) {
        this.filterSelectedAttr.subcategory = value;
      } else {
        this.filterSelectedAttr.subcategory = '';
      }
    },

    selectFilterType(value) {
      if (value) {
        this.filterSelectedAttr.type = value;
      } else {
        this.filterSelectedAttr.type = null;
      }
    },

    toast(text) {
      this.$toast.error(text, {
        position: 'bottom-left',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      });
    },

    openInNewTab() {
      document.getElementById('link').click();
    },

    openFilter() {
      this.showFilter = true;
    },

    generateFilterQuery() {
      let query = '';
      if (this.filterSelectedAttr.category !== '') {
        query += `&category=${this.filterSelectedAttr.category}`;
      }
      if (this.filterSelectedAttr.subcategory !== '') {
        query += `&sub_category=${this.filterSelectedAttr.subcategory}`;
      }
      if (this.filterSelectedAttr.type !== null) {
        let type = this.filterSelectedAttr.type;
        let code;
        if (type === 'Images') {
          code = 1;
        } else if (type === 'Videos') {
          code = 2;
        } else if (type === 'PDFs') {
          code = 3;
        }
        query += `&type=${code}`;
      }
      this.filterQuery = query;
    },

    async applyFilter() {
      this.showFilter = false;
      await this.generateFilterQuery();
      await this.loadData();
    },

    async filterClear() {
      if (this.allowedType) {
        this.filterSelectedAttr = {
          category: '',
          subcategory: '',
          type: !this.selectCompleteItem
            ? this.filterSelectedAttr.type
            : 'Videos'
        };
      } else {
        this.filterSelectedAttr = {
          category: '',
          subcategory: '',
          type: !this.selectCompleteItem ? null : 'Videos'
        };
      }
      await this.applyFilter();
    },

    cancelFilter() {
      this.showFilter = false;
    },

    toggleModal() {
      this.newModalOpen = !this.newModalOpen;
    },

    openEditModal(item) {
      if (this.allowedType && this.limit && !item.selected) {
        if (
          this.allowedType === 'image' &&
          this.selectedData.images.length >= this.limit
        ) {
          this.toast(`You can only select ${this.limit} images`);
          return;
        } else if (
          this.allowedType === 'video' &&
          this.selectedData.videos.length >= this.limit
        ) {
          this.toast(`You can only select ${this.limit} videos`);
          return;
        } else if (
          this.allowedType === 'pdf' &&
          this.selectedData.pdfs.length >= this.limit
        ) {
          this.toast(`You can only select ${this.limit} pdfs`);
          return;
        }
      }
      this.insertDataToSelectedList(item);
      this.selectedMedia = { ...item };
      this.subcategories = this.categories
        .find((item) => item.name === this.selectedMedia.category)
        ?.subCategories.map((item) => item.name);
      this.selectedEditMedia = { ...item };
    },

    resetSelectedEditMedia() {
      this.selectedMedia = null;
      this.selectedEditMedia = null;
    },

    insertDataToSelectedList(item) {
      const { url, type, selected, id } = item;
      // if (this.selectedMedia === null && selected === true) return

      if (selected) {
        // remove url
        if (type === 1) {
          this.selectedData.images = this.selectedData.images.filter(
            (item) => item !== url
          );
        } else if (type === 2) {
          if (this.selectCompleteItem) {
            this.selectedData.media = this.selectedData.media
              .map((item) =>
                item.id === id
                  ? item.newly_added
                    ? null
                    : { ...item, delete_item: !item.delete_item }
                  : item
              )
              .filter((item) => item !== null);
          }

          this.selectedData.videos = this.selectedData.videos.filter(
            (item) => item !== url
          );
        } else if (type === 3) {
          this.selectedData.pdfs = this.selectedData.pdfs.filter(
            (item) => item !== url
          );
        }
      } else {
        if (type === 1) {
          this.selectedData.images.push(url);
        } else if (type === 2) {
          if (this.selectCompleteItem) {
            const element = this.selectedData.media.find(
              (elem) => elem.id === id
            );
            if (element) {
              element.delete_item = false;
            } else {
              this.selectedData.media.push(item);
            }
          }
          this.selectedData.videos.push(url);
        } else if (type === 3) {
          this.selectedData.pdfs.push(url);
        }
      }
      this.reIncludeSelectedMedia();
    },

    reIncludeSelectedMedia() {
      this.data.forEach((item) => {
        if (this.selectedData?.images.includes(item.url)) {
          item.selected = true;
        } else if (this.selectedData?.videos.includes(item.url)) {
          item.selected = true;
        } else if (this.selectedData?.pdfs.includes(item.url)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    },

    async loadData() {
      try {
        this.loaderMessage = 'Loading ...';
        this.loading = true;

        await this.terminateRequests();

        this.currentPage = 0;
        let res = await this.service.paginateWithSelectedData(
          this.isActive,
          this.queryData,
          this.perPage,
          this.currentPage,
          this.search,
          this.filterQuery
        );
        this.totalPages = res.total_pages;
        if (res.data === null) {
          res.data = [];
        }
        this.data = res.data.map((item) => {
          if (item.type === 2) {
            let url = item.url;
            let parts = url.split('videos%2F');
            item.thumbnailUrl = parts[0] + 'videos%2Fthumbnails%2F' + parts[1];
          }
          return item;
        });

        this.reIncludeSelectedMedia();
        this.loading = false;
      } catch (error) {
        console.log('error', error);
      }
    },

    async terminateRequests() {
      // By setting empty url & thumbnailUrl to the previous images will terminate the loading process.
      this.data = [];
    },

    async loadMoreMedia(towardNext) {
      if (this.currentPage <= this.totalPages) {
        this.loading = true;

        await this.terminateRequests();

        if (towardNext) {
          this.currentPage++;
        } else {
          this.currentPage--;
        }
        let res = await this.service.paginateWithSelectedData(
          this.isActive,
          this.queryData,
          this.perPage,
          this.currentPage,
          this.search,
          this.filterQuery
        );
        let responseData = res.data;
        let data = responseData.map((item) => {
          if (item.type === 2) {
            let url = item.url;
            let parts = url.split('videos%2F');
            item.thumbnailUrl = parts[0] + 'videos%2Fthumbnails%2F' + parts[1];
          }
          return item;
        });

        this.loading = false;
        this.data = data;
        this.reIncludeSelectedMedia();
      }
    },

    async uploadFile(files) {
      this.loaderMessage = 'Uploading ...';
      this.loadingDialog = true;
      for (const file of files) {
        // Set Limit of 250mb
        if (file.size > 262144000) {
          // Alert
          this.toast(file.name + ' is too large');
        } else {
          let type = file.type;
          // Allow all types of images, videos and pdf
          let validExtensions = [
            'image/jpeg',
            'image/png',
            'image/gif',
            'image/jpg',
            'image/heic',
            'video/hevc',
            'video/mp4',
            'application/pdf'
          ];

          if (validExtensions.includes(type)) {
            let fileName = file.name;
            let fileType = type.split('/')[0];

            let newMedia = {
              title: fileName.split('.').slice(0, -1).join(''),
              category: '',
              sub_category: ''
            };
            let folderName;

            if (fileType === 'image') {
              folderName = 'images';
              newMedia.type = 1;
            } else if (fileType === 'video') {
              folderName = 'videos';
              newMedia.type = 2;
            } else if (fileType === 'application') {
              folderName = 'pdfs';
              newMedia.type = 3;
            }

            // Create custom file name
            fileName =
              fileName.split('.').slice(0, -1).join('') +
              '~' +
              new Date().getTime() +
              fileName.split('.').slice(-1)[0];
            // Upload File
            let reference = storage.ref(folderName + '/' + fileName);
            await reference.put(file);

            // Get download url from firebase storage and add to newMedia object
            newMedia.url = await storage
              .ref(folderName)
              .child(fileName)
              .getDownloadURL();

            if (fileType === 'video') {
              const duration = await this.getVideoDuration(file);
              if (duration) {
                newMedia.total_duration = duration;
              }
              // Create Thumbnail
              let thumbnail = await this.generateThumbnail(file);

              // Upload Thumbnail
              let thumbnailReference = storage.ref(
                folderName + '/thumbnails/' + fileName
              );
              await thumbnailReference.put(thumbnail);
            }

            try {
              await this.service.create(newMedia);
            } catch (error) {
              console.log('Error', error);
            }
          } else {
            // Alert
            this.toast(file.name + ' is not a valid file');
          }
        }
      }
      this.loadingDialog = false;
      await this.loadData();
    },

    async generateThumbnail(item) {
      const binaryData = [];
      binaryData.push(item);
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const video = document.createElement('video');
      video.setAttribute('src', URL.createObjectURL(new Blob(binaryData)));
      video.load();
      let thumbnail = await new Promise((resolve) => {
        video.onloadedmetadata = async () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          video.currentTime = video.duration / 2;
          await video.play();
          context.drawImage(video, 0, 0);
          video.pause();
          const blob = await new Promise((resolve) => {
            return canvas.toBlob(function (blob) {
              resolve(blob);
            });
          });
          resolve(blob);
        };
      });
      return thumbnail;
    },
    // async deleteMedia() {
    //   let approved = confirm("Are you sure you want to delete this media? \nIf you delete this media, it will be deleted from all the associated questions.");
    //   if (approved) {
    //     try {
    //       let item = this.selectedEditMedia;
    //       this.loaderMessage = "Deleting ...";
    //       this.loadingDialog = true;
    //       // Delete Data
    //       await this.service.delete(item.id);
    //       this.dialog = false;
    //
    //       this.loadingDialog = false;
    //       await this.loadData();
    //
    //     } catch (error) {
    //       console.log("Error: ", error);
    //     }
    //   }
    //
    // },
    async update() {
      if (this.selectedEditMedia.title.trim() === '') {
        alert('Title is required');
        return;
      }

      try {
        this.loaderMessage = 'Updating ...';
        this.loadingDialog = true;
        // Update Data
        await this.service.update(this.selectedEditMedia);
        this.dialog = false;

        this.loadingDialog = false;
        await this.loadData();
      } catch (error) {
        console.log('error', error);
      }
    },

    async loadCategories() {
      try {
        let res = this.isActive
          ? await this.categoryService.fetchAll()
          : await this.categoryService.fetchAllInactive();
        this.categories = res;
        this.filterAttr.category = res;
      } catch (error) {
        console.log('error', error);
      }
    }
  }
};
</script>

<style scoped>
.new-media-container {
  border-radius: 5px !important;
  border: 2px dashed gray;
  margin: 1rem 0;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.selectBtn {
  /*upload Btn*/
  border: 2px solid #1976d2;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 5px #1976d2;
}

.selectBtn:hover {
  background-color: #1976d2;
  color: white;
}

.mediaCol {
  background-color: #1976d2;
  max-height: fit-content;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: white;
}

.selected {
  border-color: #1976d2;
}

.image {
  height: 170px;
  width: 100%;
}

.editImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.editFileIcon {
  height: 100%;
  background-color: white;
}

.mediaCol:hover {
  box-shadow: 0 0 5px #1976d2;
}

.fileName {
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  color: white;
  line-height: 25px;
  font-size: 12px;
}

.play-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 35%;
  width: 35%;
  object-fit: contain;
}

/*#mainDiv {*/
/*  height: 75vh;*/
/*  overflow: hidden;*/
/*  overflow-y: scroll;*/
/*}*/

#mainDiv {
  height: calc(100vh - 12rem);
  overflow: hidden;
  overflow-y: auto;
}

.checkBox {
  position: absolute;
  background-color: white;
  border: 1px solid #1976d2;
  border-radius: 5px;
  top: -15px;
  right: -15px;
  z-index: 900;
}

/*.checkBox::before {*/
/*  content: "";*/
/*  width: 100px;*/
/*  height: 100px;*/
/*  background-color: red;*/
/*  z-index:-1;*/
/*}*/
</style>
