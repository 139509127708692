<template>
  <SimpleForm
    :onSubmit="submit"
    :disabled="
      disabled ||
      loading ||
      loadingQuestions ||
      loadingCategories ||
      loadingPassageQuestions ||
      loadingFlashcards
    "
  >
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Chapter' : 'Add a new Chapter' }}
    </p>

    <div class="d-flex justify-space-around span-2">
      <div class="d-flex flex-col items-center">
        <h4 class="mb-2">Active Icon</h4>
        <image-upload
          :hasError="showActiveImageError"
          :image_obj="old_image_active"
          @uploadedImage="getUploadedActiveImage"
        />
      </div>

      <div class="d-flex flex-col items-center">
        <h4 class="mb-2">Inactive Icon</h4>
        <image-upload
          :hasError="showInactiveImageError"
          :image_obj="old_image_inactive"
          @uploadedImage="getUploadedInactiveImage"
        />
      </div>
    </div>

    <v-checkbox
      v-model="isActiveCategoryChapter"
      label="Is chapter for active categories?"
      class="span-2"
      color="primary"
      @change="
        loadCategories();
        loadPassageQuestions();
        loadFlashcards();
        chapter.questions = [];
        items = [];
      "
      :disabled="isEdit"
    />

    <v-text-field
      class="span-2"
      v-model="chapter.title"
      outlined
      dense
      label="Title"
      :rules="[required()]"
    />

    <div v-if="!isEdit" class="d-flex justify-space-between span-2">
      <h4>Category</h4>
    </div>
    <div class="span-2 mt-3">
      <v-autocomplete
        v-model="chapter.category"
        label="Select Category"
        item-text="name"
        item-value="id"
        :items="categories"
        :loading="loadingCategories"
        :disabled="isEdit"
        class="span-2"
        :rules="[required('Select a category')]"
        outlined
        dense
      />
    </div>

    <div class="mb-2">
      <v-checkbox v-model="chapter.active" dense label="Active" hide-details />
    </div>

    <div
      class="d-flex items-center align-center justify-space-between span-2 mt-4 mb-2"
    >
      <h2>
        Flashcards
        <span style="font-size: 16px; color: gray">(Drag to reorder)</span>
      </h2>
      <div>
        <v-btn
          elevation="0"
          color="primary"
          @click="addFlashcard"
          small
          class="mt-2"
          :disabled="loadingFlashcards"
        >
          <v-icon small>mdi-plus</v-icon>
          Add Flashcard
        </v-btn>
      </div>
    </div>
    <div class="span-2">
      <Container @drop="onDropFlashcard">
        <Draggable
          v-for="(flashcard, i) in chapter.flashcards"
          :key="i"
          style="padding: 10px 0px; height: 64px"
        >
          <v-autocomplete
            v-model="chapter.flashcards[i]"
            :label="'Select Flashcard #' + (i + 1)"
            item-text="name"
            item-value="_id"
            :items="flashcards"
            :loading="loadingFlashcards"
            class="span-2"
            :rules="[required('Select a flashcard')]"
            outlined
            dense
            @click:append-outer="removeFlashcard(i)"
            :disabled="loadingFlashcards"
          >
            <template v-slot:append-outer>
              <v-icon
                @click="removeFlashcard(i)"
                v-if="chapter.flashcards.length > 1"
                color="red"
                small
                :disabled="loadingFlashcards"
                >mdi-delete
              </v-icon>
              <v-icon
                class="ml-2"
                @click="editFlashcard(flashcard)"
                v-if="chapter.flashcards[i]"
                color="primary"
                small
                :disabled="loadingFlashcards"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-autocomplete>
        </Draggable>
      </Container>
    </div>

    <div
      class="d-flex items-center align-center justify-space-between span-2 mt-4 mb-2"
    >
      <h2>
        Questions
        <span style="font-size: 16px; color: gray">(Drag to reorder)</span>
      </h2>
      <div>
        <v-btn
          elevation="0"
          color="primary"
          @click="addQuestion(null)"
          small
          class="mt-2"
          :disabled="loadingQuestions"
        >
          <v-icon small>mdi-plus</v-icon>
          Add Question
        </v-btn>
      </div>
    </div>
    <div class="span-2">
      <Container @drop="onDropQuestion">
        <Draggable
          v-for="(question, i) of chapter.questions"
          :key="i"
          style="padding: 10px 0px; height: 64px"
        >
          <div class="d-flex" v-if="chapter.questions.length > 0">
            <v-text-field
              :value="question.statement"
              :label="'Select Question # ' + (i + 1)"
              outlined
              dense
              readonly
              @click="addQuestion(i)"
              :disabled="loadingQuestions"
            ></v-text-field>
            <v-icon
              @click="removeQuestion(i)"
              v-if="chapter.questions.length > 1"
              color="red"
              class="mb-7 ml-2"
              small
              :disabled="loadingQuestions"
              >mdi-delete
            </v-icon>
          </div>
        </Draggable>
      </Container>
    </div>

    <div
      class="d-flex items-center align-center justify-space-between span-2 mt-4 mb-2"
    >
      <h2>
        Passage Questions
        <span style="font-size: 16px; color: gray">(Drag to reorder)</span>
      </h2>
      <div>
        <v-btn
          elevation="0"
          color="primary"
          @click="addPassageQuestion(null)"
          small
          class="mt-2"
          :disabled="loadingPassageQuestions"
        >
          <v-icon small>mdi-plus</v-icon>
          Add Passage Question
        </v-btn>
      </div>
    </div>
    <div class="span-2">
      <Container @drop="onDropPassageQuestion">
        <Draggable
          v-for="(question, i) of chapter.passageQuestion"
          :key="i"
          style="padding: 10px 0px; height: 64px"
        >
          <div class="d-flex" v-if="chapter.passageQuestion.length > 0">
            <v-text-field
              :value="question.heading"
              :label="'Select Passage Question #' + (i + 1)"
              outlined
              dense
              readonly
              @click="addPassageQuestion(i)"
              :disabled="loadingPassageQuestions"
              :rules="[required()]"
            ></v-text-field>
            <v-icon
              @click="removePassageQuestion(i)"
              v-if="chapter.passageQuestion.length > 1"
              color="red"
              class="mb-7 ml-2"
              small
              :disabled="loadingPassageQuestions"
              >mdi-delete
            </v-icon>
          </div>
        </Draggable>
      </Container>
    </div>

    <v-dialog v-if="showDialog" v-model="showDialog">
      <v-card
        style="padding: 20px; display: flex; max-height: 90vh; overflow: auto"
      >
        <div style="flex: 1" class="mr-2">
          <QuestionsView
            :isActiveCategory="isActiveCategoryChapter"
            :question-view-modal="true"
            @click:row="handleQuestionSelection"
          />
        </div>
        <v-card
          v-if="selectedQuestionIndex === null"
          min-width="370px"
          style="
            width: 370px;
            max-height: 100%;
            overflow: hidden;
            overflow: auto;
          "
        >
          <v-list three-line>
            <div class="d-flex items-center justify-space-between mx-4 mt-2">
              <h3>Selected Questions</h3>
              <v-btn
                color="primary"
                elevation="0"
                @click="addSelectedQuestions"
                :disabled="items.length === 0"
              >
                Done
              </v-btn>
            </div>
            <div
              class="text-center"
              style="
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              "
            >
              <p v-if="items.length === 0">
                <span class="font-weight-bold">
                  Click on any question to select it for this chapter
                </span>
              </p>
            </div>
            <template>
              <div v-for="(item, index) in items" :key="item.title">
                <v-list-item>
                  <template v-slot:default>
                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        <strong>{{
                          item.category.name || item.categoryName
                        }}</strong>
                        -
                        <span style="font-size: 13px">{{
                          item.subCategory.name || item.subCategoryName
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.statement }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-icon
                      small
                      color="red"
                      @click="removeSelectedQuestion(item)"
                      v-if="items.length > 1"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </div>
            </template>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showPassageQuestionDialog"
      v-model="showPassageQuestionDialog"
    >
      <v-card
        style="display: flex; padding: 10px; max-height: 90vh; overflow: auto"
      >
        <div style="flex: 1" class="mr-2">
          <PassageQuestionsView
            :isActiveCategory="isActiveCategoryChapter"
            @click:row="handlePassageQuestionSelection"
          />
        </div>

        <v-card
          v-if="selectedPassageQuestionIndex === null"
          min-width="370px"
          style="
            width: 370px;
            max-height: 100%;
            overflow: hidden;
            overflow: auto;
          "
        >
          <v-list three-line>
            <div class="d-flex items-center justify-space-between mx-4 mt-2">
              <h3>Selected Passage Questions</h3>
              <v-btn
                color="primary"
                elevation="0"
                @click="showPassageQuestionDialog = false"
                :disabled="chapter.passageQuestion.length === 0"
              >
                Done
              </v-btn>
            </div>
            <div
              class="text-center mt-5"
              v-if="chapter.passageQuestion.length === 0"
            >
              <p>
                <span>
                  Click on any passage question to select it for this chapter
                </span>
              </p>
            </div>
            <template v-else>
              <div
                v-for="(item, index) in chapter.passageQuestion"
                :key="item.title"
              >
                <v-list-item>
                  <template v-slot:default>
                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        <strong>{{ item.category.name }}</strong>
                        -
                        <span style="font-size: 13px">{{
                          item.sub_category.name
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.heading }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-icon
                      small
                      color="red"
                      @click="removeSelectedPassageQuestion(item)"
                      v-if="chapter.passageQuestion.length > 1"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < chapter.passageQuestion.length - 1"
                  :key="index"
                ></v-divider>
              </div>
            </template>
          </v-list>
        </v-card>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" :message="loadingMessage" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '@/components/Form';
import LoadingDialog from '@/components/LoadingDialog.vue';
import { PassageQuestionsService } from '@/services/passage_questions-service';
import { QuestionsService } from '@/services/questions-service';
import { RoadmapModeService } from '@/services/roadmap-mode-service';
import { required } from '@/utils/validators';
import ImageUpload from '@/components/ImageUpload.vue';
import { storage } from '@/plugins/firebase';
import { CategoryService } from '@/services/category-service';
import QuestionsView from '@/views/roadmap/chapters/components/QuestionsViewModal.vue';
import PassageQuestionsView from '@/views/roadmap/chapters/components/PassageQuestionsViewModal.vue';
import { Container, Draggable } from 'vue-smooth-dnd';

export default {
  name: 'ChapterForm',

  components: {
    SimpleForm,
    LoadingDialog,
    ImageUpload,
    QuestionsView,
    PassageQuestionsView,
    Container,
    Draggable
  },

  data: () => ({
    isEdit: false,
    loading: false,
    loadingMessage: 'Loading Chapter...',
    isActiveCategoryChapter: true,

    chapter: {
      title: '',
      category: '',
      flashcards: [''],
      questions: [''],
      passageQuestion: [],
      active: false
    },

    activeImage: null,
    displayImageActive: null,
    showActiveImageError: false,

    inactiveImage: null,
    displayImageInactive: null,
    showInactiveImageError: false,

    disabled: false,

    questions: [],
    loadingQuestions: false,
    categories: [],
    passageQuestions: [],
    loadingPassageQuestions: false,
    loadingCategories: false,
    flashcards: [],
    loadingFlashcards: false,

    service: new RoadmapModeService(),
    categoryService: new CategoryService(),
    questionsService: new QuestionsService(),
    passageQuestionsService: new PassageQuestionsService(),

    // questions
    showDialog: false,
    selected: [],
    items: [],
    selectedQuestionIndex: null,

    // passage questions
    showPassageQuestionDialog: false,
    selectedPassageQuestionIndex: null
  }),

  computed: {
    old_image_active() {
      return {
        url: this.displayImageActive
      };
    },
    old_image_inactive() {
      return {
        url: this.displayImageInactive
      };
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    required,

    onDropFlashcard({ removedIndex, addedIndex }) {
      const movedItem = this.chapter.flashcards.splice(removedIndex, 1)[0];
      this.chapter.flashcards.splice(addedIndex, 0, movedItem);
    },

    onDropQuestion({ removedIndex, addedIndex }) {
      const movedItem = this.chapter.questions.splice(removedIndex, 1)[0];
      this.chapter.questions.splice(addedIndex, 0, movedItem);
    },

    onDropPassageQuestion({ removedIndex, addedIndex }) {
      const movedItem = this.chapter.passageQuestion.splice(removedIndex, 1)[0];
      this.chapter.passageQuestion.splice(addedIndex, 0, movedItem);
    },

    getUploadedActiveImage(_image_obj) {
      if (_image_obj) {
        this.activeImage = _image_obj.file;
        this.showActiveImageError = false;
        this.disabled = false;
      } else {
        this.showActiveImageError = true;
        this.disabled = true;
      }
    },

    getUploadedInactiveImage(_image_obj) {
      if (_image_obj) {
        this.inactiveImage = _image_obj.file;
        this.showInactiveImageError = false;
        this.disabled = false;
      } else {
        this.showInactiveImageError = true;
        this.disabled = true;
      }
    },

    async loadData() {
      if (this.$route.query?.isActiveCategory === 'false') {
        this.isActiveCategoryChapter = false;
      }
      const chapterId = this.$route.query.id;
      if (chapterId) {
        this.isEdit = true;
        this.loading = true;
        this.loadingMessage = 'Loading Chapter...';
        let chapter = await this.service.fetchOne(chapterId);

        this.chapter = {
          title: chapter.name,
          category: chapter.category,
          flashcards: chapter.flashcards || [],
          questions: chapter.questions || [],
          passageQuestion: chapter.passages || [],
          index: chapter.index,
          active: chapter.active,
          createdAt: chapter.createdAt,
          updatedAt: chapter.updatedAt
        };
        this.displayImageActive = chapter.icon.active;
        this.displayImageInactive = chapter.icon.inactive;
        await this.loadQuestionsByIds(this.chapter.category);
        this.loading = false;
      }

      this.loadCategories();
      this.loadPassageQuestions();
      this.loadFlashcards();

      if (!this.isEdit) {
        this.chapter.questions = [];
      }
    },

    async loadPassageQuestions() {
      this.loadingPassageQuestions = true;
      if (this.isEdit) {
        this.chapter.passageQuestion = await Promise.all(
          this.chapter.passageQuestion.map(async (question) => {
            return await this.passageQuestionsService.fetchOne(question);
          })
        );
      }

      this.loadingPassageQuestions = false;
    },

    async loadFlashcards() {
      this.loadingFlashcards = true;
      this.flashcards = this.isActiveCategoryChapter
        ? await this.service.fetchAllFlashcard()
        : await this.service.fetchAllFlashcard({ active: 0 });
      this.loadingFlashcards = false;
    },

    async loadQuestionsByIds() {
      this.loadingQuestions = true;
      this.chapter.questions = await this.questionsService.fetchQuestionByIds(
        this.chapter.questions
      );
      this.loadingQuestions = false;
    },

    async loadCategories() {
      this.loadingCategories = true;
      this.categories = this.isActiveCategoryChapter
        ? await this.categoryService.fetchAll()
        : await this.categoryService.fetchAllInactive();
      this.loadingCategories = false;
    },

    addQuestion(index) {
      if (index !== null) {
        this.selectedQuestionIndex = index;
      } else {
        this.selectedQuestionIndex = null;
      }
      this.showDialog = true;

      this.items = this.chapter.questions;
    },

    removeQuestion(i) {
      if (this.chapter.questions.length > 1) {
        this.chapter.questions.splice(i, 1);
      }
    },

    addPassageQuestion(index) {
      if (index !== null) {
        this.selectedPassageQuestionIndex = index;
      } else {
        this.selectedPassageQuestionIndex = null;
      }
      this.showPassageQuestionDialog = true;
    },

    removePassageQuestion(i) {
      if (this.chapter.passageQuestion.length > 1) {
        this.chapter.passageQuestion.splice(i, 1);
      }
    },

    addFlashcard() {
      this.chapter.flashcards.push('');
    },

    editFlashcard(id) {
      const routeData = this.$router.resolve({
        path: '/roadmap-flashcard',
        query: { id: id, close_tab: 'true' }
      });
      window.open(routeData.href, '_blank');
    },

    removeFlashcard(i) {
      if (this.chapter.flashcards.length > 1) {
        this.chapter.flashcards.splice(i, 1);
      }
    },

    async addSelectedQuestions() {
      this.showDialog = false;
      const questionIds = this.items.map((question) => question.id);
      this.chapter.questions = [
        ...new Set([
          ...this.chapter.questions.map((question) => question.id),
          ...questionIds
        ])
      ];
      await this.loadQuestionsByIds();
    },

    handleQuestionSelection(item) {
      if (!this.items.every((el) => el.id !== item.id)) {
        alert('Question is already selected');
        return;
      }

      if (this.selectedQuestionIndex !== null) {
        this.items[this.selectedQuestionIndex] = item;
        this.chapter.questions[this.selectedQuestionIndex] = item;

        this.selectedQuestionIndex = null;
        this.showDialog = false;
      } else {
        this.items.push(item);
      }
    },

    handlePassageQuestionSelection(item) {
      if (!this.chapter.passageQuestion.every((el) => el.id !== item.id)) {
        alert('Passage Question is already selected');
        return;
      }

      if (this.selectedPassageQuestionIndex !== null) {
        this.chapter.passageQuestion[this.selectedPassageQuestionIndex] = item;

        this.selectedPassageQuestionIndex = null;
        this.showPassageQuestionDialog = false;
      } else {
        this.chapter.passageQuestion.push(item);
      }
    },

    removeSelectedQuestion(item) {
      this.items = this.items.filter((question) => question.id !== item.id);
      this.chapter.questions = this.chapter.questions.filter(
        (question) => question.id !== item.id
      );
    },

    removeSelectedPassageQuestion(item) {
      this.chapter.passageQuestion = this.chapter.passageQuestion.filter(
        (question) => question.id !== item.id
      );
    },

    async submit(context) {
      try {
        if (!this.isEdit) {
          // validate Image
          if (!this.activeImage) {
            this.showActiveImageError = true;
            return false;
          }

          // validate Image
          if (!this.inactiveImage) {
            this.showInactiveImageError = true;
            return false;
          }

          context.changeLoadingMessage('Creating New Chapter');
        } else {
          context.changeLoadingMessage('Updating Chapter');
        }

        if (this.activeImage) {
          let fileName = this.activeImage.name;

          // Create custom file name
          fileName =
            fileName.split('.').slice(0, -1).join('') +
            '~' +
            new Date().getTime() +
            fileName.split('.').slice(-1)[0];

          // Upload File
          let reference = storage.ref('chapters/icon/' + fileName);
          await reference.put(this.activeImage);

          // Get download url from firebase storage and add to newChapter object
          this.displayImageActive = await storage
            .ref('chapters/icon/')
            .child(fileName)
            .getDownloadURL();
        }

        if (this.inactiveImage) {
          let fileName = this.inactiveImage.name;

          // Create custom file name
          fileName =
            fileName.split('.').slice(0, -1).join('') +
            '~' +
            new Date().getTime() +
            fileName.split('.').slice(-1)[0];

          // Upload File
          let reference = storage.ref('chapters/icon/' + fileName);
          await reference.put(this.inactiveImage);

          // Get download url from firebase storage and add to newChapter object
          this.displayImageInactive = await storage
            .ref('chapters/icon/')
            .child(fileName)
            .getDownloadURL();
        }

        const obj = {
          name: this.chapter.title,
          category: this.chapter.category,
          icon: {
            active: this.displayImageActive || '',
            inactive: this.displayImageInactive || ''
          },
          flashcards: this.chapter.flashcards,
          questions: this.chapter.questions?.map((question) => question.id),
          passages: this.chapter.passageQuestion?.map(
            (question) => question.id
          ),
          active: this.chapter.active,
          index: this.chapter.index,
          ...(this.chapter.createdAt && {
            createdAt: this.chapter.createdAt,
            updatedAt: this.chapter.updatedAt
          })
        };

        if (this.isEdit) {
          obj._id = this.$route.query.id;
          await this.service.update(obj);
        } else {
          await this.service.create(obj);
        }
        this.isEdit = false;

        await this.$router.push('/roadmap-chapters');
      } catch (e) {
        this.loading = false;
        context.reportError({
          title: `Error while ${this.isEdit ? 'Updating' : 'Creating'} Chapter`,
          description: e?.data?.error ?? 'Some error occurred'
        });
      }
    }
  }
};
</script>
