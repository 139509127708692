<template>
  <div>
    <v-card class="elevation-0 height-full">
      <div class="d-flex justify-space-between items-center mb-2">
        <v-card-title
          class="d-flex flex-column flex-md-row justify-start align-start"
          :style="$vuetify.breakpoint.mdAndUp ? '' : 'gap: 10px'"
        >
          <span class="data-table__header">Video Section</span>
        </v-card-title>
        <div class="px-4">
          <v-btn
            color="green"
            elevation="0"
            class="mr-2 btn-text"
            :disabled="loading"
            @click="saveArrangedItems"
          >
            <v-icon class="v-btn__pre-icon" small>mdi-content-save </v-icon
            >&nbsp; {{ loading ? 'Saving...' : 'Save Changes' }}
          </v-btn>
          <v-btn color="primary" elevation="0" @click="openMediaPickerModal">
            {{ items.length ? 'Manage Videos' : 'Add Videos' }}
          </v-btn>
          <v-btn icon style="margin-left: 10px">
            <v-icon @click="$router.push('/video-section-modal')"
              >mdi-filter
            </v-icon>
          </v-btn>
        </div>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

      <div class="px-4">
        <div
          class="d-flex items-center justify-space-around border-bottom pb-3 px-2"
        >
          <h2
            class="flex-1 column-header"
            v-for="header in headers"
            :key="header"
          >
            {{ header }}
          </h2>
          <h2 class="column-header">Actions</h2>
        </div>
        <Container @drop="onDrop" v-if="items.length">
          <Draggable v-for="(item, idx) in items" :key="item.id + idx">
            <div
              :class="`${
                item.delete_item
                  ? 'delete-row'
                  : item.newly_added
                  ? 'newly_added'
                  : ''
              }
                  d-flex items-center item-row border-bottom px-2`"
            >
              <p class="flex-1">{{ item.title }}</p>
              <p class="flex-1">{{ category.name }}</p>
              <p class="flex-1">{{ subCategory.name }}</p>
              <div class="mx-3 delete-btn">
                <v-icon
                  small
                  color="green"
                  @click="
                    () => {
                      video = item;
                      show = true;
                    }
                  "
                  >mdi-eye
                </v-icon>
              </div>
            </div>
          </Draggable>
        </Container>
        <div
          v-if="items.length === 0"
          class="d-flex justify-center align-center height-full"
        >
          No Data Found
        </div>
      </div>
    </v-card>
    <v-dialog v-if="video" persistent width="40%" v-model="show">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ video.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <video
            width="100%"
            height="100%"
            controls
            autoplay
            :src="video.url"
          ></video>
        </v-card-text>
      </v-card>
    </v-dialog>

    <MediaPicker
      :id="`mediaPickerBtn`"
      :selectedData="selectedData"
      @SetNewSelectedData="SetNewSelectedData"
      :select-complete-item="true"
    />
  </div>
</template>

<script>
import { VideoSectionService } from '@/services/video-session-service';
import { getUser } from '@/utils/local';
import { Container, Draggable } from 'vue-smooth-dnd';
import MediaPicker from '@/components/media/MediaPicker.vue';

export default {
  components: {
    MediaPicker,
    Container,
    Draggable
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      items: [],
      category: null,
      subCategory: null,
      video: null,
      show: false,
      loading: false,
      service: new VideoSectionService(),
      headers: ['Title', 'Category', 'Sub Category'],
      selectedData: {
        images: [],
        videos: [],
        pdfs: [],
        media: []
      }
    };
  },

  methods: {
    getUser,
    async loadData() {
      try {
        const fetchedItems = await this.service.fetchByCategoryAndSubcategory(
          this.$route.query.category,
          this.$route.query.subcategory
        );

        if (fetchedItems && fetchedItems.length) {
          const [{ category, sub_category, media }] = fetchedItems;

          this.category = category;
          this.subCategory = sub_category;
          this.items = media;
          this.selectedData.videos = media.map((item) => item.url);
          this.selectedData.media = media;
        } else {
          this.items = [];
          this.category = { id: this.$route.query.category };
          this.subCategory = { id: this.$route.query.subcategory };
        }
      } catch (error) {
        this.$toast.error('Something went wrong');
      }
    },
    async saveArrangedItems() {
      try {
        if (this.items.length) {
          this.loading = true;
          const { id: category } = this.category;
          const { id: sub_category } = this.subCategory;

          const data = [
            {
              category,
              sub_category,
              media: this.items.map(
                ({ id, video_section, delete_item = false }) => ({
                  id,
                  video_section,
                  is_deleted: delete_item
                })
              )
            }
          ];

          await this.service.update(data);
          this.selectedData.media = [];
          this.selectedData.videos = [];
          await this.loadData();
          this.loading = false;
          this.$toast.success('Successfully updated');
        } else {
          this.$toast.error('No data to update');
        }
      } catch (error) {
        console.error('Error updating data:', error);
        this.$toast.error('Something went wrong');
      }
    },

    SetNewSelectedData(data) {
      this.selectedData.media = data.media;
      this.items = data.media.map((item) => {
        if (!item.video_section) {
          item.newly_added = true;
          item.video_section = null;
        }
        return item;
      });
    },
    openMediaPickerModal() {
      document.getElementById(`mediaPickerBtn`).click();
    },
    onDrop(dropResult) {
      this.items = this.applyDrag(this.items, dropResult);
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    }
  }
};
</script>
<style scoped>
.column-header {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

.item-row {
  font-size: 0.875rem;
  height: 48px;
}

.border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.flex-1 {
  flex: 1;
}

p {
  margin: 0;
}

.height-full {
  height: 80vh;
}

.data-table__header {
  font-size: 25px;
  font-family: google-sans, sans-serif;
}

.btn-text {
  color: #ffffff;
}

.delete-btn {
  cursor: pointer;
}

.delete-row {
  text-decoration: line-through;
}

.newly_added {
  background-color: rgba(195, 253, 157, 0.4);
}
</style>
