var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Active Categories")]),_c('v-tab',[_vm._v("InActive Categories")])],1),_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Question Media","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('question-media:new') : false,"allow-filters":"","default-footer":false,"show-search":false,"delete-message":"Are you sure you want to delete this question's media? Keep in mind that it will also delete the media files from the gallery.","delete-handler":_vm.getUser()
          ? _vm.getUser().scopes.includes('question-media:delete')
            ? _vm.deleteMedia
            : null
          : null,"edit-handler":_vm.getUser()
          ? _vm.getUser().scopes.includes('question-media:edit')
            ? _vm.edit
            : null
          : null},on:{"filter":function($event){_vm.showFilter = true},"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"primary-action",fn:function(){return [_c('div',{staticStyle:{"width":"400px","margin-right":"20px","max-width":"100%"}},[_c('v-text-field',{attrs:{"label":"Search by keywords","dense":"","hide-details":"","outlined":"","clearable":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.searchData,"change":_vm.searchData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"0","outlined":"","color":"primary"},on:{"click":_vm.reRunAllMedia}},[_vm._v("Re-Run All Media")])]},proxy:true},{key:"extra-actions",fn:function(item){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.reRunMedia(item.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-autorenew")])]}}],null,true)},[_c('span',[_vm._v("Rerun")])])]}},{key:"images",fn:function(ref){
          var item = ref.item;
return [(item.images && item.images.length > 0)?_c('span',[_vm._v(_vm._s(_vm.fetchImage(item.images[0])))]):(item.videos && item.videos.length > 0)?_c('span',[_vm._v(_vm._s(_vm.fetchImage(item.videos[0])))]):(item.pdfs && item.pdfs.length > 0)?_c('span',[_vm._v(_vm._s(_vm.fetchImage(item.pdfs[0])))]):_c('span',[_vm._v("No Media")])]}},{key:"keywords",fn:function(ref){
          var item = ref.item;
return _vm._l((item.keywords.slice(0, 2)),function(keyword,i){return _c('v-chip',{key:i,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(_vm._s(keyword))])})}},{key:"questions",fn:function(ref){
          var item = ref.item;
return [(item.questions)?_c('span',_vm._l((item.questions.slice(0, 1)),function(question,i){return _c('v-chip',{key:i,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(_vm._s(question.statement.substring(0, 75))+" "+_vm._s(question.statement.length > 75 ? '...' : ''))])}),1):_vm._e()]}},{key:"custom-footer",fn:function(){return [_c('div',{staticClass:"v-data-footer d-flex justify-center align-center"},[_c('v-pagination',{staticClass:"my-2",attrs:{"value":_vm.currentPage + 1,"total-visible":"10","prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right","length":_vm.totalPages + 1},on:{"input":_vm.loadMoreMedia}}),_c('v-text-field',{staticStyle:{"max-width":"80px"},attrs:{"type":"number","min":"1","max":_vm.totalPages + 1,"outlined":"","dense":"","hide-details":"","placeholder":"Page #"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.loadMoreMedia(parseInt(_vm.pageInput), true)}},model:{value:(_vm.pageInput),callback:function ($$v) {_vm.pageInput=$$v},expression:"pageInput"}})],1)]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Filter ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","label":"Category","dense":"","outlined":"","return-object":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('v-select',{attrs:{"items":_vm.category && _vm.category.subCategories,"item-text":"name","item-value":"id","label":"Sub Category","dense":"","outlined":"","return-object":"","disabled":!_vm.category},model:{value:(_vm.subCategory),callback:function ($$v) {_vm.subCategory=$$v},expression:"subCategory"}}),_c('v-select',{attrs:{"items":[
            { text: 'Images', value: 1 },
            { text: 'Videos', value: 2 },
            { text: 'PDFs', value: 3 }
          ],"item-text":"text","item-value":"value","label":"Media Type","dense":"","outlined":""},model:{value:(_vm.mediaType),callback:function ($$v) {_vm.mediaType=$$v},expression:"mediaType"}}),_c('v-select',{attrs:{"items":[
            { text: 'None', value: null },
            { text: 'Ascending', value: 'QCountAsc' },
            { text: 'Descending', value: 'QCountDesc' }
          ],"item-text":"text","item-value":"value","label":"Questions Count Sort","dense":"","outlined":""},model:{value:(_vm.QCountSort),callback:function ($$v) {_vm.QCountSort=$$v},expression:"QCountSort"}})],1),_c('v-card-actions',{staticClass:"pb-4 px-6"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v("Clear")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showFilter = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"dark":"","color":"primary","elevation":"0"},on:{"click":function($event){_vm.searchData();
            _vm.showFilter = false;}}},[_vm._v("Apply")])],1)],1)],1),_c('LoadingDialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }