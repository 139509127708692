import axios from 'axios';

export class ScheduledQotdService {
  async fetchAll() {
    return (await axios.get('/question-of-the-day/new')).data;
  }

  async update(data) {
    return (await axios.patch('/question-of-the-day', data)).data;
  }
}
