<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <div>
        <v-row>
          <v-col class="d-flex justify-space-between align-center">
            <v-card-title style="color: #020819"> Roadmap Stats</v-card-title>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card class="ma-2" color="deep-purple lighten-4" elevation="0">
              <v-card-title class="d-flex justify-center">
                Average Passage Percentage
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-if="dataLoading"
                  indeterminate
                  color="deep-purple"
                ></v-progress-circular>
                <v-chip
                  class="ma-2"
                  color="deep-purple"
                  text-color="white"
                  v-else
                >
                  {{ stats.average_passage_percentage }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="ma-2" color="indigo lighten-4" elevation="0">
              <v-card-title class="d-flex justify-center">
                Average Play Time
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-if="dataLoading"
                  indeterminate
                  color="indigo"
                ></v-progress-circular>
                <v-chip class="ma-2" color="indigo" text-color="white" v-else>
                  {{ stats.average_play_time }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="ma-2" color="blue lighten-4" elevation="0">
              <v-card-title class="d-flex justify-center">
                Average Question Percentage
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-if="dataLoading"
                  indeterminate
                  color="blue"
                ></v-progress-circular>
                <v-chip class="ma-2" color="blue" text-color="white" v-else>
                  {{ stats.average_question_percentage }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="ma-2" color="teal lighten-4" elevation="0">
              <v-card-title class="d-flex justify-center">
                Most Used Section
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-if="dataLoading"
                  indeterminate
                  color="teal"
                ></v-progress-circular>
                <v-chip class="ma-2" color="teal" text-color="white" v-else>
                  {{ stats.overall_activity_breakdown }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="ma-2" color="green lighten-4" elevation="0">
              <v-card-title class="d-flex justify-center">
                Total Completed Chapters
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-if="dataLoading"
                  indeterminate
                  color="green"
                ></v-progress-circular>
                <v-chip class="ma-2" color="green" text-color="white" v-else>
                  {{ stats.total_completed_chapters }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="ma-2" color="orange lighten-4" elevation="0">
              <v-card-title class="d-flex justify-center">
                Total Users
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-if="dataLoading"
                  indeterminate
                  color="orange"
                ></v-progress-circular>
                <v-chip class="ma-2" color="orange" text-color="white" v-else>
                  {{ stats.total_users }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <data-table
          title="Chapter Completion"
          :loader="loadData"
          :headers="headers"
          :allow-add="false"
          :allow-refresh="false"
          :defaultFooter="false"
          :key="dataTableKey"
          :show-search="false"
        >
          <template #primary-action>
            <v-text-field
              v-model="filterUserEmail"
              outlined
              dense
              hide-details
              placeholder="Search by user email"
              v-on:keydown.enter="getUserByEmail"
              :append-icon="'mdi-magnify'"
              @click:append="getUserByEmail"
              clearable
              @click:clear="refreshData"
            />
          </template>

          <template v-slot:custom-footer>
            <div class="v-data-footer d-flex justify-center align-center">
              <v-pagination
                :value="currentPage"
                class="my-2"
                @input="changePage"
                total-visible="10"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                :length="totalPages"
              />
              <v-text-field
                v-model="pageInput"
                type="number"
                min="1"
                :max="totalPages"
                outlined
                dense
                hide-details
                style="max-width: 80px"
                placeholder="Page #"
                v-on:keydown.enter="changePage(parseInt(pageInput), true)"
              />
            </div>
          </template>
        </data-table>
      </div>
    </v-card>

    <loading-dialog v-model="loading" message="Please Wait..." />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import LoadingDialog from '@/components/LoadingDialog';
import { RoadmapModeService } from '@/services/roadmap-mode-service';
import DataTable from '@/components/DataTable.vue';
import { users } from '@/plugins/firebase';

export default {
  name: 'StatsDetails',
  components: {
    LoadingDialog,
    DataTable
  },

  data() {
    return {
      dataTableKey: 0,
      filterUserEmail: '',
      loading: false,
      dataLoading: true,
      service: new RoadmapModeService(),
      stats: {},

      // custom pagination
      pageInput: '',
      totalPages: 1,
      currentPage: 1,
      recordsPerPage: 10,
      userId: '',

      headers: [
        {
          text: 'User Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'User Email',
          value: 'email',
          sortable: false
        },
        {
          text: 'Completed Chapters',
          value: 'completed_chapters',
          sortable: false
        },
        {
          text: 'Most Used Section',
          value: 'activity_breakdown',
          sortable: false
        }
      ]
    };
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format('MMMM D, YYYY');
    },

    async loadData() {
      // Fetch route hash for pagination
      this.fetchRouteHash();

      let data = await this.service.fetchStats(
        this.currentPage,
        this.recordsPerPage,
        this.userId
      );
      if (!data) {
        this.$toast.error('No Stats found.');
        return [];
      }
      this.stats = data;

      this.totalPages = Math.ceil(this.stats.total_users / this.recordsPerPage);

      this.dataLoading = false;

      return this.stats.chapter_completed_by_individuals;
    },

    async changePage(page, isInput = false) {
      if (isInput) {
        if (page < 1 || page > this.totalPages) {
          return;
        }
        this.currentPage = page;
      } else {
        this.currentPage = page;
      }

      this.$router.replace({ hash: `#${this.currentPage}` });

      this.dataTableKey++;
    },

    fetchRouteHash() {
      if (this.$route.hash === '') return;

      let hash = parseInt(this.$route.hash.replace('#', ''));
      this.currentPage = hash;
      if (
        hash <= 0 ||
        (this.currentPage > this.totalPages && this.totalPages > 0)
      ) {
        this.$router.replace({ hash: '#1' });
      }
    },

    async getUserByEmail() {
      if (!this.filterUserEmail.includes('@')) {
        return this.$toast.error('Invalid email address.');
      } else if (this.filterUserEmail === '') {
        return this.$toast.error('Please enter an email address.');
      }

      this.loading = true;
      await users
        .where('email', '==', this.filterUserEmail)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.$toast.error('User with this email does not exist.');
            return;
          }

          this.userId = querySnapshot.docs[0].id;
          this.$router.replace({ hash: '#1' });
          this.dataTableKey++;
        })
        .catch((error) => {
          console.error('Error getting documents: ', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refreshData() {
      this.$router.replace({ hash: '#1' });
      this.filterUserEmail = '';
      this.userId = '';
      this.dataTableKey++;
    }
  }
};
</script>
<style scoped></style>
