<template>
  <div>
    <div
      v-if="!loading && selectedFilter !== 5 && selectedFilter !== 6"
      class="d-flex justify-start align-center"
      style="margin-top: 10px; padding-left: 20px"
    >
      <v-icon @click="toggleFilter()">mdi-chevron-left</v-icon>
      <p class="ma-0">
        {{ formatDate(startDate) }}
        {{
          selectedFilter === 1 || selectedFilter === 2
            ? ''
            : ' -  ' + formatDate(endDate)
        }}
      </p>
      <v-icon @click="toggleFilter(true)">mdi-chevron-right </v-icon>
    </div>
    <div v-if="!loading" style="position: absolute; right: 10px; top: 10px">
      <v-menu
        offset-x
        offset-y
        left
        close-on-click
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-filter </v-icon>
        </template>

        <v-card class="pa-3">
          <v-form ref="filterPieForm">
            <v-select
              v-model="selectedFilter"
              dense
              outlined
              label="Filter"
              :items="filters"
              @change="
                () => {
                  applyFilter();
                }
              "
              hide-details
              class="mb-2"
            />
            <v-text-field
              v-model="startDate"
              v-if="selectedFilter === 0"
              label="Start Date"
              type="date"
              outlined
              dense
              :rules="[required()]"
            ></v-text-field>
            <v-text-field
              v-model="endDate"
              v-if="selectedFilter === 0"
              label="End Date"
              type="date"
              outlined
              dense
              :rules="[required()]"
            ></v-text-field>
          </v-form>
        </v-card>
      </v-menu>
    </div>
    <bar-chart
      v-if="!loading"
      class="py-5"
      :chart-data="chartData"
      :options="chartOptions"
      :height="170"
      :key="this.key"
    />
    <div
      style="height: 100%"
      class="d-flex justify-center align-center pa-16"
      v-else-if="loading"
    >
      <v-progress-circular indeterminate />
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import { DashboardService } from '@/services/dashboard-service';
import { required } from '@/utils/validators';
import BarChart from '@/utils/charts/bar-chart';

export default {
  name: 'BarChartUsersRevamp',
  components: { BarChart },
  data: () => ({
    service: new DashboardService(),
    key: 1,
    data: null,
    sgId: null,
    selectedFilter: 1,
    loading: false,
    startDate: null,
    endDate: null,
    filters: [
      {
        text: 'Daily',
        value: 2
      },
      {
        text: 'Weekly',
        value: 3
      },
      {
        text: 'Monthly',
        value: 4
      },
      {
        text: 'Quarterly',
        value: 5
      },
      {
        text: 'Half Yearly',
        value: 6
      },
      {
        text: 'Yearly',
        value: 1
      }
      // {
      //   text: 'Custom',
      //   value: 0
      // }
    ],
    chartOptions: {
      responsive: true,
      title: {
        display: true,
        text: 'Yearly User Analytics',
        fontSize: 18,
        fontColor: '#6b7280'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    },
    chartData: {
      labels: [],
      datasets: [
        {
          label: 'Yearly Active Users',
          backgroundColor: '#495db6',
          data: [],
          fill: true,
          borderWidth: 1
        }
      ]
    }
  }),
  mounted() {
    this.applyFilter();
    this.sgId = this.$route.query.sgId;
  },
  computed: {
    title() {
      return this.filters.find((item) => item.value === this.selectedFilter)
        .text;
    }
  },
  watch: {
    selectedFilter() {
      this.chartOptions.title.text = `${this.title} User Analytics`;
      this.key = Math.random();
    },
    data() {
      if (this.data) {
        this.chartData.labels = this.generateLabels(
          this.startDate,
          this.endDate,
          this.selectedFilter
        );
        this.key = Math.random();
      }
    }
  },
  methods: {
    required,
    formatDate(date, format = 'D MMM YYYY') {
      if (this.selectedFilter === 1) return moment(date).format('YYYY');
      return moment(date).format(format);
    },
    generateLabels(startDate, endDate, filterValue) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      let labels = [];
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      const monthsOfYear = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];

      this.chartData.datasets[0].data = [];

      switch (filterValue) {
        case 2: {
          const hoursIn12HourFormat = (hour) => {
            const suffix = hour >= 12 ? 'PM' : 'AM';
            const formattedHour = hour % 12 || 12; // Converts 0 to 12 for 12 AM
            return `${formattedHour} ${suffix}`;
          };

          Object.keys(this.data).forEach((hour) => {
            const formattedLabel = hoursIn12HourFormat(parseInt(hour));
            labels.push(formattedLabel);
            this.chartData.datasets[0].data.push(this.data[hour]);
          });

          break;
        }
        case 3: {
          // Weekly
          while (start <= end) {
            const day = daysOfWeek[start.getDay()];
            labels.push(day);
            this.chartData.datasets[0].data.push(this.data[day] || 0);
            start.setDate(start.getDate() + 1);
          }
          break;
        }
        case 4: {
          // Monthly
          Object.keys(this.data).forEach((dateString) => {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = monthsOfYear[date.getMonth()];
            const formattedLabel = `${day} ${month}`;

            labels.push(formattedLabel);
            this.chartData.datasets[0].data.push(this.data[dateString]);
          });
          break;
        }
        case 5:
        case 6: {
          // Quarterly
          const sortedData = {};
          monthsOfYear.forEach((month) => {
            const currentMonth = this.data[month.toLowerCase()];
            if (currentMonth !== undefined) {
              sortedData[month] = currentMonth;
            }
          });
          this.chartData.datasets[0].data = Object.values(sortedData);
          labels = Object.keys(sortedData);
          break;
        }

        case 1: {
          // Yearly
          monthsOfYear.forEach((month) => {
            labels.push(month);
            this.chartData.datasets[0].data.push(
              this.data[month.toLowerCase()]
            );
          });
          break;
        }
        case 0: {
          // Custom
          // Custom handling logic if required
          break;
        }
        default:
          console.error('Unknown filter value:', filterValue);
      }

      this.chartData.datasets[0].label = `${this.title} Active Users`;
      return labels;
    },

    async applyFilter(existingPayload = null) {
      let payload = null;
      // let validated = true;

      const filterDateRanges = {
        1: () => {
          // Yearly
          this.startDate = this.endDate = moment().format('YYYY');
          payload = {
            year: this.startDate
          };
        },
        2: () => {
          // Daily
          const date = moment();
          this.startDate = date.subtract(0, 'days').format('YYYY-MM-DD');
          this.endDate = moment().subtract(0, 'days').format('YYYY-MM-DD');
          payload = {
            from: this.endDate,
            to: date.add(1, 'days').format('YYYY-MM-DD')
          };
        },
        3: () => {
          // Weekly
          this.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          this.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
          payload = {
            from: this.startDate,
            to: this.endDate
          };
        },
        4: () => {
          // Monthly
          this.endDate = moment().format('YYYY-MM-DD');
          this.startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
          payload = {
            from: this.startDate,
            to: this.endDate
          };
        },
        0: () => {
          // Custom
          if (this.$refs.filterPieForm.validate()) {
            console.log(this.startDate, this.endDate);
          } else {
            // validated = false;
          }
        }
      };
      const yearlyFilters = [1, 5, 6];
      let index = null;
      if (yearlyFilters.includes(this.selectedFilter)) index = 1;
      if (filterDateRanges[index || this.selectedFilter] && !existingPayload) {
        filterDateRanges[index || this.selectedFilter]();
      }

      this.loading = true;
      this.data = await this.service.fetchUserAnalytics(
        4,
        payload || existingPayload,
        this.selectedFilter,
        this.sgId
      );
      this.$emit('completed', true);
      this.loading = false;
    },

    toggleFilter(next = false) {
      let startDate = moment(this.startDate);
      let endDate = moment(this.endDate);
      let payload = {};

      const adjustDate = (unit, amount) => {
        startDate = next
          ? startDate.add(amount, unit)
          : startDate.subtract(amount, unit);
        endDate = next
          ? endDate.add(amount, unit)
          : endDate.subtract(amount, unit);
      };

      const filterAdjustments = {
        1: () => {
          // Yearly
          adjustDate('year', 1);
          payload = {
            year: startDate.format('YYYY')
          };
        },
        2: () => {
          // Daily
          adjustDate('days', 1);
          const date = moment(startDate);
          payload = {
            from: endDate.format('YYYY-MM-DD'),
            to: date.add(1, 'days').format('YYYY-MM-DD')
          };
        },
        3: () => {
          // Weekly
          adjustDate('week', 1);
          payload = {
            from: startDate.format('YYYY-MM-DD'),
            to: endDate.format('YYYY-MM-DD')
          };
        },
        4: () => {
          // Monthly
          adjustDate('month', 1);
          payload = {
            from: startDate.format('YYYY-MM-DD'),
            to: endDate.format('YYYY-MM-DD')
          };
        },
        5: () => {
          // Quarterly
          adjustDate('months', 3);
          payload = {
            year: startDate.format('YYYY')
          };
        },
        6: () => {
          // Half Yearly
          adjustDate('months', 6);
          payload = {
            year: startDate.format('YYYY')
          };
        }
      };

      if (filterAdjustments[this.selectedFilter]) {
        filterAdjustments[this.selectedFilter]();
      }

      this.startDate = startDate.format('YYYY-MM-DD');
      this.endDate = endDate.format('YYYY-MM-DD');
      this.applyFilter(payload);
    }
  }
};
</script>
