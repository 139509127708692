<template>
  <v-form v-model="valid">
    <data-table
      :key="dataTableKey"
      :loader="loadData"
      :headers="headers"
      title="Offers"
      :allow-add="getUser().scopes.includes('iaps-offers:new')"
      @add-new="addNew"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('iaps-offers:delete')
            ? service.delete
            : null
          : null
      "
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('iaps-offers:edit')
            ? edit
            : null
          : null
      "
      @done="$router.back()"
    >
      <template #primary-action v-if="!($route.query.p && $route.query.p == 1)">
        <v-btn
          color="primary"
          elevation="0"
          class="mr-2"
          outlined
          @click="toggleStatus(false)"
        >
          Disable
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="mr-2"
          outlined
          @click="toggleStatus(true)"
        >
          Enable
        </v-btn>
      </template>
      <template #active="{ item }">
        <v-checkbox dense hide-details v-model="item.active" readonly />
      </template>
      <template #expiry="{ item }">
        {{ formatDate(item.expiry) }}
      </template>
    </data-table>

    <loading-dialog v-model="editLoading" message="Loading..." />
  </v-form>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { getUser } from '@/utils/local';
import { requiredPercentage, required } from '@/utils/validators';
import { IapsOffersService } from '@/services/iaps-offers-service';
import dayjs from 'dayjs';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    editLoading: false,
    valid: false,
    loading: false,
    service: new IapsOffersService(),
    dataTableKey: null,

    items: [],
    headers: [
      {
        text: 'Offer',
        value: 'offer_name'
      },
      {
        text: 'Expiry',
        value: 'expiry'
      },
      {
        text: 'File',
        value: 'filename'
      },
      {
        text: 'Codes Remaining',
        value: 'length'
      },
      {
        text: 'Active',
        value: 'active',
        sortable: false
      }
    ]
  }),

  computed: {
    isPlateformIso() {
      if (
        !(
          this.$route.query &&
          this.$route.query.p &&
          this.$route.query.p === '1'
        )
      ) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    if (this.isPlateformIso) {
      this.headers.splice(1, 0, {
        text: 'Discount Percentage',
        value: 'discount_percentage'
      });

      this.dataTableKey += 1;
    }
  },

  methods: {
    getUser,
    requiredPercentage,
    required,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    addNew() {
      this.$router.push(
        `/iaps/offer?product=${this.$route.query.id}&p=${this.$route.query.p}`
      );
    },
    checkLess(discount, price) {
      //eslint-disable-next-line no-extra-boolean-cast
      if (!!discount) {
        //eslint-disable-next-line no-extra-boolean-cast
        return parseInt(discount) < parseInt(!!price ? price : '0');
      } else {
        return true;
      }
    },
    edit(item) {
      this.$router.push(
        `/iaps/offer?id=${item.id}&product=${this.$route.query.id}&p=${this.$route.query.p}`
      );
    },
    async toggleStatus(status) {
      try {
        this.editLoading = true;
        await this.service.toggleActive(this.$route.query.id, status);
        document.getElementById('refresh').click();
        this.editLoading = false;
      } catch (e) {
        console.log(e);
      }
      this.editLoading = false;
    },
    async loadData() {
      if (!this.isPlateformIso) {
        this.items = Object.values(
          await this.service.fetchAll(this.$route.query.id, this.$route.query.p)
        );
      } else {
        this.items = Object.values(
          await this.service.fetchAll(this.$route.query.id)
        );
      }

      return this.items;
    }
  }
};
</script>
