var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"table",attrs:{"allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('banners:new') : false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('banners:delete')
          ? _vm.deleteHandler
          : null
        : null,"headers":_vm.headers,"loader":_vm.loadBanners,"show-search":false,"title":"Banners"},on:{"add-new":function($event){return _vm.$router.push('/banner')}},scopedSlots:_vm._u([{key:"placementPage",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBannerPage(item.placementPage))+" ")]}},{key:"placementPosition",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBannerPosition(item.placementPosition))+" ")]}},{key:"action",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBannerAction(item.action))+" ")]}},{key:"total-counts",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.stats.totalClickCount)+" ")]}},{key:"ios-counts",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.stats.iosClickCount)+" ")]}},{key:"android-counts",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.stats.androidClickCount)+" ")]}},{key:"web-counts",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.stats.webClickCount)+" ")]}},{key:"active",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":"","readonly":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"ios",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":"","readonly":""},model:{value:(item.ios),callback:function ($$v) {_vm.$set(item, "ios", $$v)},expression:"item.ios"}})]}},{key:"android",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":"","readonly":""},model:{value:(item.android),callback:function ($$v) {_vm.$set(item, "android", $$v)},expression:"item.android"}})]}},{key:"web",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":"","readonly":""},model:{value:(item.web),callback:function ($$v) {_vm.$set(item, "web", $$v)},expression:"item.web"}})]}},{key:"images",fn:function(ref){
        var item = ref.item;
return [(item.image)?_c('img',{style:({
          background: 'white',
          border: '1px solid white',
          borderRadius: '50px',
          objectFit: 'cover'
        }),attrs:{"src":item.image,"alt":"Default Image","height":"40","loading":"lazy","width":"40"}}):_c('p',{staticClass:"ma-0"},[_vm._v("No Image")])]}},{key:"extra-actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.$router.push(("/banner?id=" + (item.id)))}}},[_vm._v("mdi-eye ")]),_c('v-icon',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.$router.push(("/banner?edit=true&id=" + (item.id)))}}},[_vm._v("mdi-pencil ")])]}}])}),_c('loading-dialog',{attrs:{"message":_vm.loadingMessage},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('error-dialog',{attrs:{"error":_vm.errorValue},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }