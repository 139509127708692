<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Questions Stats"
      :showSearch="false"
      :defaultFooter="false"
      :allow-add="false"
      @done="$router.back()"
    >
      <template #averagePlayTime="{ item }">
        {{ convertedPlayTime(item.averagePlayTime) }}
      </template>
      <template #primary-action>
        <div style="width: 400px; margin-right: 20px; max-width: 100%">
          <v-text-field
            v-model="search"
            solo
            label="Search by statement..."
            dense
            hide-details
            append-icon="mdi-magnify"
            @click:append="searchData"
            @change="searchData"
          />
        </div>
      </template>
      <template v-slot:custom-footer>
        <div class="v-data-footer d-flex justify-center align-center">
          <v-pagination
            :value="currentPage + 1"
            class="my-2"
            @input="changePage"
            total-visible="10"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="totalPages + 1"
          />
          <v-text-field
            v-model="pageInput"
            type="number"
            min="1"
            :max="totalPages + 1"
            outlined
            dense
            hide-details
            style="max-width: 80px"
            placeholder="Page #"
            v-on:keydown.enter="changePage(parseInt(pageInput), true)"
          />
        </div>
      </template>
    </data-table>
    <loading-dialog v-model="loading" message="Loading..." />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import { QuestionsService } from '@/services/questions-service';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import LoadingDialog from '@/components/LoadingDialog';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    pageInput: '',
    loading: false,
    service: new QuestionsService(),
    search: '',

    totalPages: 0,
    currentPage: 0,
    recordsPerPage: 10,

    headers: [
      {
        text: 'Question Statement',
        value: 'statement',
        sortable: false
      },
      {
        text: 'Average Time',
        value: 'averagePlayTime',
        width: '200px',
        sortable: true
      }
    ],
    items: []
  }),

  watch: {
    async $route() {
      // Reload Data when route hash change for pagination
      await this.loadData(true);
      this.refreshDataTable();
    }
  },

  methods: {
    getUser,
    required,

    refreshDataTable() {
      document.getElementById('searchDataReload').click();
    },

    convertedPlayTime(time) {
      const seconds = Number(time);
      if (isNaN(seconds)) {
        return 'Invalid time';
      }

      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      if (minutes > 0) {
        return `${minutes} min ${remainingSeconds} sec`;
      } else {
        return `${seconds.toFixed(2)} sec`;
      }
    },

    changePage(value, byPageInput = false) {
      // Update route hash for pagination
      if (value > 0 && value <= this.totalPages + 1) {
        this.$router.replace({ path: '/question-stats', hash: '#' + value });
      }
      if (byPageInput === false) {
        this.pageInput = '';
      }
    },

    async searchData() {
      this.pageInput = '';
      if (this.$route.hash !== '#1')
        this.$router.replace({ path: '/question-stats', hash: '#1' });
      await this.loadData(true);
      this.refreshDataTable();
    },

    async loadData() {
      this.loading = true;

      // Fetch route hash for pagination
      this.fetchRouteHash();

      let res;
      if (this.search) {
        res = await this.service.paginate(
          true,
          this.currentPage,
          this.recordsPerPage,
          this.search,
          null,
          true
        );
      } else {
        res = await this.service.paginate(
          true,
          this.currentPage,
          this.recordsPerPage,
          '',
          null,
          true
        );
      }

      this.items = res.data;
      this.totalPages = res.total_pages;

      this.loading = false;
      return this.items;
    },

    fetchRouteHash() {
      if (this.$route.hash === '') {
        this.currentPage = 0;
      } else {
        let hash = parseInt(this.$route.hash.replace('#', ''));
        this.currentPage = hash - 1;
        if (
          hash <= 0 ||
          (this.currentPage > this.totalPages && this.totalPages > 0)
        ) {
          this.$router.replace({ path: '/question-stats', hash: '#1' });
        }
      }
    }
  }
};
</script>

<style scoped></style>
