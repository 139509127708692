var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Popup Notifications","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('popup-notifications:new') : false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('popup-notifications:delete')
          ? _vm.deleteItem
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('popup-notifications:edit')
          ? _vm.edit
          : null
        : null,"view-handler":_vm.view},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.content[0].title.length > 50 ? item.content[0].title.substring(0, 50) + '...' : item.content[0].title))])]}},{key:"startDate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.schedule.startDate)))])]}},{key:"endDate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.schedule.endDate)))])]}},{key:"ios",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.updateItem(item)}},model:{value:(item.ios),callback:function ($$v) {_vm.$set(item, "ios", $$v)},expression:"item.ios"}})]}},{key:"android",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.updateItem(item)}},model:{value:(item.android),callback:function ($$v) {_vm.$set(item, "android", $$v)},expression:"item.android"}})]}},{key:"web",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.updateItem(item)}},model:{value:(item.web),callback:function ($$v) {_vm.$set(item, "web", $$v)},expression:"item.web"}})]}},{key:"active",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"click":function($event){return _vm.updateItem(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}}])}),_c('loading-dialog',{attrs:{"message":"Updating..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }