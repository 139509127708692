export const androidPlans = [
//   'lifetime_v1',
//   'one_month_pro',
//   'three_months_pro',
//   'six_months_pro',
//   'one_year_pro',
//   'three_months_premium_prepper',
//   'three_months_standard_student',
//   'six_months_standard_student',

  'one_month_premium_prepper',
  'three_months_premium_prepper',
  'six_months_premium_prepper',
  'one_year_premium_prepper',
  'lifetime_v2',
  'one_month_standard_student',
  'three_months_standard_student',
  'six_months_standard_student',
  'one_year_standard_student',
  'one_month_pro',
  'three_months_pro',
  'six_months_pro',
  'one_year_pro'
];

export const iosPlans = [
//   'lifetime_new',
//   'single_month_v2',
//   'three_months_v2',
//   'six_months_v2',
//   'twelve_months_v2.0',
//   'three_month_standard_student',
//   'six_month_standard_student',
//   'three_month_premium_prepper',

  'lifetime_new',
  'single_month_v2',
  'three_months_v2',
  'six_months_v2',
  'twelve_months_v2.0',
  'one_month_standard_student',
  'three_month_standard_student',
  'six_month_standard_student',
  'one_year_standard_student',
  'one_month_premium_prepper',
  'three_month_premium_prepper',
  'six_months_premium_prepper',
  'one_year_premium_prepper'
];

export const commonPlans = [
  'one_month_premium_prepper',
  'six_months_premium_prepper',
  'one_year_premium_prepper',
  'one_month_standard_student',
  'one_year_standard_student'
];
