<template>
  <div>
    <data-table
      :hasElevation="false"
      :allow-add="false"
      :headers="headers"
      :loader="loadData"
      title="All Customers"
      :default-footer="$route.query.studentGroupId ? true : false"
      :show-search="false"
      :key="$route.query.studentGroupId"
    >
      <template #primary-action>
        <v-text-field
          v-model="search"
          label="Search by email or name..."
          dense
          clearable
          outlined
          append-icon="mdi-magnify"
          @click:append="searchData"
          @change="searchData"
        />

        <v-btn
          color="primary"
          elevation="0"
          @click="addCustomerDialog = true"
          class="ml-4"
          outlined
          >Add Customer
        </v-btn>

        <v-btn color="primary mx-3 px-0" elevation="0">
          <label for="file" class="py-2 px-4" style="cursor: pointer">
            <v-icon class="v-btn__pre-icon" size="16">mdi-upload</v-icon>
            Import Customers
          </label>
        </v-btn>
        <v-file-input
          v-model="file"
          id="file"
          accept=".csv"
          class="d-none"
          ref="inputRef"
        ></v-file-input>

        <v-btn
          v-if="
            getUser() ? getUser().scopes.includes('customers:export') : false
          "
          class="mr-3"
          color="primary"
          elevation="0"
          @click="exportCustomers(false)"
          outlined
          :disabled="exportCustomersLoading"
          >Export
        </v-btn>
        <v-btn
          v-if="
            getUser() ? getUser().scopes.includes('customers:export') : false
          "
          class="mr-3"
          color="primary"
          elevation="0"
          @click="confirmationDialog = true"
          :disabled="exportCustomersLoading"
        >
          Export All

          <v-progress-circular
            v-if="exportCustomersLoading"
            indeterminate
            size="20"
            class="ml-4"
          />
        </v-btn>
      </template>
      <template #subscribed_entitlement="{ item }">
        {{ item.modified_package_details }}
      </template>
      <template #is_premium="{ item }">
        <v-checkbox v-model="item.is_premium" dense hide-details readonly />
      </template>
      <template #premium_trial="{ item }">
        <v-checkbox v-model="item.premium_trial" dense hide-details readonly />
      </template>
      <template #actions="{ item }">
        <v-icon
          title="View Details"
          v-if="
            getUser() ? getUser().scopes.includes('customer:detail') : false
          "
          color="primary"
          small
          @click="detail(item)"
          >mdi-eye
        </v-icon>

        <v-icon
          v-if="!item.is_premium"
          title="Enable Premium Trial"
          color="success"
          small
          @click="openPremiumEditor(item)"
          >mdi-check-circle
        </v-icon>

        <v-icon
          v-if="item.is_premium && item.premium_trial"
          color="error"
          small
          @click="toNotPremium(item)"
        >
          mdi-close-circle
        </v-icon>

        <v-icon
          v-if="!item.is_admin"
          title="Enable Admin Access"
          color="success"
          small
          @click="makeAdmin(item)"
        >
          mdi-security
        </v-icon>

        <v-icon
          v-else
          color="red"
          title="Disable Admin Access"
          small
          @click="removeAdmin(item)"
          >mdi-cancel
        </v-icon>
      </template>

      <template v-slot:custom-footer v-if="!$route.query.studentGroupId">
        <div class="v-data-footer pa-3 d-flex justify-end align-center">
          <v-btn
            :disabled="!prevItems || prevItems.length <= 0"
            class="mr-2"
            @click="getData(false)"
            elevation="0"
            color="primary"
            >Prev
          </v-btn>
          <v-btn
            :disabled="!items || items.length < 10"
            @click="getData(true)"
            elevation="0"
            color="primary"
            >Next
          </v-btn>
        </div>
      </template>
    </data-table>

    <v-dialog v-model="toPremium" width="400">
      <v-card style="padding: 40px">
        <v-form ref="makePremium">
          <p class="span-2 form__title mb-0">Premium Trial</p>
          <p class="span-2 mb-6">
            This will set the user's premium status to true till expiry date.
          </p>
          <v-select
            v-model="subscribed_entitlement"
            label="Select Package"
            :items="premium_entitlements"
            item-text="text"
            item-value="value"
            outlined
            dense
            class="span-2"
            :rules="[required()]"
          />
          <v-text-field
            v-model="premium_trial_expiry"
            :rules="[required(), dateValidation]"
            dense
            label="Expiry Date"
            outlined
            type="date"
          />
          <v-btn
            color="primary"
            elevation="0"
            style="width: 100%"
            @click="makePremium"
            >Submit
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addCustomerDialog" width="500">
      <v-card class="pa-6">
        <v-form ref="addCustomerForm">
          <p class="span-2 form__title mb-0">Add Customer</p>

          <v-text-field
            v-model="newCustomer.email"
            :rules="[required(), email()]"
            dense
            label="Email"
            outlined
            type="email"
          />
          <v-checkbox
            v-model="newCustomer.premium_status"
            dense
            hide-details
            @change="newCustomer.expiration_date = ''"
            label="Is Premium"
            class="mb-6 mt-n2"
          />

          <v-text-field
            v-if="newCustomer.premium_status"
            v-model="newCustomer.expiration_date"
            :rules="[required()]"
            :min="new Date().toISOString().split('T')[0]"
            dense
            label="Premium Expiry Date"
            outlined
            type="date"
          />

          <div class="d-flex justify-end gap-4">
            <v-btn color="primary" elevation="0" @click="addCustomer"
              >Submit
            </v-btn>

            <v-btn
              color="error"
              elevation="0"
              @click="addCustomerDialog = false"
              class="ml-4"
              >Close
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Loading..." />
    <error-dialog-vue v-model="error" :error="errorVal" />
    <confirmation-dialog
      v-model="confirmationDialog"
      @confirm="exportCustomers(true)"
      message="Are you sure you want to export?"
    />
  </div>
</template>
<script>
import DataTable from '../DataTable';
import { getUser } from '@/utils/local';
import { users } from '@/plugins/firebase';
import { dateValidation, required, email } from '@/utils/validators';
import LoadingDialog from '@/components/LoadingDialog';
import ErrorDialogVue from '@/components/ErrorDialog.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import moment from 'moment';
import { exportAsCSV } from '@/utils/csv';
import { UsersService } from '@/services/users-service';
import { StudentGroupsService } from '@/services/student-groups-service';

export default {
  name: 'AllCustomers',
  components: { LoadingDialog, ErrorDialogVue, DataTable, ConfirmationDialog },
  props: {
    filterByUserIds: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    file: {
      handler: function (file) {
        if (!file) return;
        this.$papa.parse(file, {
          header: true,
          complete: async (results) => {
            try {
              this.loading = true;
              const users = results.data
                .filter((item) => item.email)
                .map((item) => {
                  return {
                    first_name: item['first_name'] || '',
                    last_name: item['last_name'] || '',
                    email: item['email'] || ''
                  };
                });

              if (users.length > 0 && this.$route.query.studentGroupId) {
                let studentGroup = await this.studentGroupsService.fetchOne(
                  this.$route.query.studentGroupId
                );
                studentGroup.user_ids = [
                  ...new Set([
                    ...studentGroup.user_ids,
                    ...users.map((u) => u.email)
                  ])
                ];

                const res = await this.studentGroupsService.update(
                  studentGroup
                );

                if (res) {
                  this.$toast.success('File Uploaded Successfully');
                }

                this.file = null;
                this.$refs.inputRef.reset();
                this.loading = false;
              } else if (
                users.length > 0 &&
                !this.$route.query.studentGroupId
              ) {
                const res = await this.service.addBulkUsers({
                  data: users
                });

                if (res) {
                  this.$toast.success('File Uploaded Successfully');
                }

                await this.exportAsCSV(res);
                this.file = null;
                this.$refs.inputRef.reset();
                this.loading = false;
              } else {
                this.loading = false;
                this.$toast.error('Something went wrong!');
              }
            } catch (e) {
              this.loading = false;
              this.$toast.error('Something went wrong!');
            }
          }
        });
      }
    }
  },

  data: () => ({
    error: false,
    errorVal: {
      title: 'Error',
      description: 'Something went wrong!'
    },
    addCustomerDialog: false,
    confirmationDialog: false,
    exportCustomersLoading: false,
    items: [],
    prevItems: [],
    loading: false,
    file: null,

    toPremium: false,
    premium_trial_expiry: null,
    subscribed_entitlement: 'MCAT Master',
    user: null,

    service: new UsersService(),
    studentGroupsService: new StudentGroupsService(),
    premium_entitlements: [
      'Standard Student',
      'Premium Prepper',
      'MCAT Master'
    ],

    headers: [
      {
        text: 'Display Name',
        value: 'name'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Subscribed Plan',
        value: 'subscribed_entitlement',
        sortable: false
      },
      {
        width: 120,
        text: 'Is Premium',
        value: 'is_premium',
        sortable: false
      },
      {
        width: 120,
        text: 'Premium Trial',
        value: 'premium_trial',
        sortable: false
      },
      {
        width: 120,
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ],
    page: 1,
    next: false,
    prev: false,
    search: null,

    newCustomer: {
      email: '',
      premium_status: false,
      expiration_date: ''
    }
  }),

  methods: {
    getUser,
    required,
    email,
    dateValidation,
    exportAsCSV,

    async makeAdmin(item) {
      let approved = confirm(
        'Are you sure you want to make this user an admin?'
      );
      if (approved) {
        this.loading = true;
        await users.doc(item.id).update({ is_admin: true });
        this.refresh();
        this.loading = false;
      }
    },

    async removeAdmin(item) {
      let approved = confirm(
        'Are you sure you want to remove admin rights from this user?'
      );
      if (approved) {
        this.loading = true;
        await users.doc(item.id).update({ is_admin: false });
        this.refresh();
        this.loading = false;
      }
    },

    chunkArray(array, size) {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    },

    async loadData() {
      try {
        if (
          this.getUser()?.scopes.includes('customers:view') ||
          this.filterByUserIds.length > 0
        ) {
          if (!this.search || this.search === '') {
            if (this.page <= 1) {
              let snapshot;
              if (this.filterByUserIds.length > 0) {
                const chunkUserIds = this.chunkArray(this.filterByUserIds, 10);
                const promises = chunkUserIds.map(async (ids) => {
                  const snapshot = await users.where('id', 'in', ids).get();
                  const data = [];
                  snapshot.docs.map((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                  });
                  return data;
                });

                snapshot = await Promise.all(promises);
              } else {
                snapshot = await users.orderBy('id').limit(10).get();
              }
              const data = [];

              if (this.filterByUserIds.length > 0) {
                snapshot.map((docs) => {
                  docs.map((doc) => {
                    data.push({ id: doc.id, ...doc });
                  });
                });
              } else {
                snapshot.docs.map((doc) => {
                  data.push({ id: doc.id, ...doc.data() });
                });
              }

              this.items = this.addPackageDetailsToData(data);
              this.page++;
              return this.items;
            } else {
              let lastVisible;
              let snapshot;
              if (this.prev && this.prevItems && this.prevItems.length > 0) {
                lastVisible = { ...this.prevItems.pop() };
                snapshot = await users
                  .orderBy('id')
                  .startAt(lastVisible.id)
                  .limit(10)
                  .get();
              } else if (this.next) {
                lastVisible = { ...this.items[this.items.length - 1] };
                this.prevItems.push(this.items[0]);
                snapshot = await users
                  .orderBy('id')
                  .startAfter(lastVisible.id)
                  .limit(10)
                  .get();
              } else {
                snapshot = await users.orderBy('id').limit(10).get();
              }

              const data = [];
              if (snapshot)
                snapshot.docs.map((doc) => {
                  data.push({ id: doc.id, ...doc.data() });
                });
              this.items = this.addPackageDetailsToData(data);

              return this.items;
            }
          } else {
            this.page = 1;
            this.prevItems = [];
            let snapshot;
            if (this.search.includes('@')) {
              snapshot = await users
                .where('email', '==', this.search)
                .limit(10)
                .get();
            } else {
              snapshot = await users
                .where('name', '==', this.search)
                .limit(10)
                .get();
            }

            const data = [];
            snapshot.docs.map((doc) => {
              data.push({ id: doc.id, ...doc.data() });
            });

            this.items = this.addPackageDetailsToData(data);

            if (this.filterByUserIds.length > 0) {
              this.items = this.items.filter((item) =>
                this.filterByUserIds.includes(item.id)
              );
            }

            return this.items;
          }
        } else {
          this.$router.push('/404');
        }
      } catch (e) {
        console.log(e, 'error');
      }
    },

    addPackageDetailsToData(data) {
      return data.map((item) => {
        if (item.is_premium && item.subscribed_entitlement) {
          item.modified_package_details =
            item.subscribed_entitlement === 'Pro'
              ? 'MCAT Master'
              : item.subscribed_entitlement;
          return item;
        } else if (
          item.is_premium &&
          !item.subscribed_entitlement &&
          item.subscribed_package
        ) {
          switch (item.subscribed_package) {
            case 'one_month_standard_student':
            case 'three_month_standard_student':
            case 'six_month_standard_student':
            case 'one_year_standard_student':
              item.modified_package_details = 'Standard Student';
              return item;
            case 'one_month_premium_prepper':
            case 'three_month_premium_prepper':
            case 'six_month_premium_prepper':
            case 'one_year_premium_prepper':
              item.modified_package_details = 'Premium Prepper';
              return item;
            case 'single_month_v2':
            case 'three_months_v2':
            case 'six_months_v2':
            case 'twelve_months_v2.0':
            case 'lifetime_new':
              item.modified_package_details = 'MCAT Master';
              return item;
          }
        } else if (
          !item.subscribed_entitlement &&
          !item.subscribed_package &&
          item.is_premium
        ) {
          item.modified_package_details = 'MCAT Master';
          return item;
        } else {
          item.modified_package_details = '';
          return item;
        }
      });
    },

    getData(flag) {
      if (flag) {
        this.prev = false;
        this.next = true;
      } else {
        this.prev = true;
        this.next = false;
      }
      document.getElementById('refresh').click();
    },
    searchData() {
      document.getElementById('refresh').click();
    },

    async exportCustomers(flag) {
      this.exportCustomersLoading = true;
      try {
        let url = '/games-stats/prediction/metrics/csv?csv=true';

        if (!flag) {
          const userIds = this.items.map((item) => item.id);
          url += `&limit=10&user_id=${userIds.join('&user_id=')}`;
        }

        const data = (await this.$axios.get(url)).data;
        this.downloadCSV(data, 'customers.csv');
      } catch (e) {
        this.handleError(e);
      } finally {
        this.exportCustomersLoading = false;
      }
    },

    downloadCSV(data, filename) {
      if (!data) return;
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename || 'data.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },

    handleError(e) {
      this.error = true;
      this.errorVal = {
        title: 'Error',
        description: e.message || e.statusText || 'Something went wrong!'
      };
    },

    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'Name,Email\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        if (array[i].name) {
          array[i].name = array[i].name.replaceAll(',', ' ');
          line += array[i].name;
        }
        line += ',' + array[i].email;
        line += '\r\n';

        str += line;
      }

      return str;
    },
    openPremiumEditor(item) {
      this.user = item;
      this.toPremium = true;
    },
    async makePremium() {
      if (this.$refs.makePremium.validate()) {
        this.loading = true;
        let data = {
          is_premium: true,
          premium_trial: true,
          subscribed_entitlement:
            this.subscribed_entitlement === 'MCAT Master'
              ? 'Pro'
              : this.subscribed_entitlement,
          premium_trial_expiry: moment(this.premium_trial_expiry).format(
            'YYYY-MM-DDT00:00'
          )
        };

        await users
          .doc(this.user.id)
          .update(data)
          .then(() => {})
          .catch((e) => {
            window.console.log(e);
          });
        this.toPremium = false;
        this.premium_trial_expiry = null;
        this.user = null;
        this.$refs.makePremium.reset();
        document.getElementById('refresh').click();
        this.loading = false;
      }
    },
    async toNotPremium(item) {
      if (confirm('Are you sure? This will end premium trial.')) {
        this.loading = true;
        let data = {
          is_premium: false,
          premium_trial: false,
          premium_trial_expiry: null
        };

        await users
          .doc(item.id)
          .update(data)
          .then(() => {})
          .catch((e) => {
            window.console.log(e);
          });
        document.getElementById('refresh').click();
        this.loading = false;
      }
    },
    async detail(item) {
      window.open('/customer-detail?id=' + item.id);
    },
    formatDate(date) {
      return moment(date).format('dddd, MMMM Do YYYY');
    },
    refresh() {
      let refresh_btn = document.getElementById('refresh');
      if (refresh_btn) {
        refresh_btn.click();
        return;
      }
      this.loadData();
    },

    async addCustomer() {
      if (this.$refs.addCustomerForm.validate()) {
        this.loading = true;
        await this.service
          .addSingleUser({
            ...this.newCustomer,
            expiration_date: new Date(this.newCustomer.expiration_date)
          })
          .then((res) => {
            if (res && res.status) {
              this.$toast.success(res.status);
            } else {
              this.$toast.success('Customer added successfully');
            }

            this.$refs.addCustomerForm.reset();
            this.addCustomerDialog = false;
          })
          .catch((e) => {
            this.loading = false;
            this.handleError(e);
          });

        this.loading = false;
      }
    }
  }
};
</script>

<style>
.v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  background: transparent !important;
  transform: none;
  transition: none;
}

.v-ripple__container {
  display: none !important;
}

.v-input--selection-controls__ripple,
.v-input--selection-controls .v-input__slot,
.v-input--selection-controls .v-radio {
  cursor: default;
}
</style>
