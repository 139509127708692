<template>
  <div
    class="span-2"
    style="
      border-left: 2px dotted rgba(0, 0, 0, 0.2);
      margin-bottom: 30px;
      padding-top: 10px;
    "
  >
    <v-radio-group
      :rules="[answerRule]"
      :value="selected"
      hide-details
      @change="$emit('selected', $event)"
    >
      <div v-for="(option, key) in options" :key="key" class="flex-row">
        <div class="h-dots" />
        <v-radio :value="key" style="margin-top: 7px" />
        <v-textarea
          v-model="options[key]"
          :label="'Option ' + (key + 1)"
          :readonly="!canPick"
          :rules="[(v) => !!v || 'Enter a valid answer']"
          auto-grow
          dense
          outlined
          persistent-placeholder
          placeholder="Write an Answer here"
          rows="1"
        />
        <v-btn
          v-if="canPick && options.length > 1"
          color="red"
          icon
          @click="removeAnswer(key)"
        >
          <v-icon>mdi-minus-circle</v-icon>
        </v-btn>
        <span
          style="width: 35px; margin-top: 7px"
          :style="{ paddingLeft: pickerType === 'bool' ? '5px' : '' }"
          >{{ isEdit ? Math.round(questionPercentage[key]) : '0' }}%</span
        >
      </div>
    </v-radio-group>

    <div v-if="options.length < 4" class="flex-row" style="position: relative">
      <div class="h-dots" />
      <v-btn
        :disabled="!canPick"
        class="add-btn"
        color="primary"
        text
        @click="addOption"
      >
        <v-icon style="margin-right: 10px">mdi-plus-circle</v-icon>
        Add Option
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnswersPicker',

  model: {
    prop: 'options',
    event: 'options'
  },

  props: {
    selected: {
      type: Number,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    pickerType: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    questionPercentage: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    pickerType() {
      if (this.pickerType === 'bool') {
        this.$emit('options', ['True', 'False']);
      } else if (
        this.pickerType === 'choices' &&
        this.options &&
        this.options.length > 0
      ) {
        this.$emit('options', [...this.options]);
      } else {
        this.$emit('options', ['', '', '', '']);
      }

      this.canPick = this.pickerType === 'choices';
    }
  },

  data: () => ({
    canPick: false
  }),

  methods: {
    answerRule(v) {
      return typeof v === 'number' || 'No answer is marked as correct';
    },
    addOption() {
      const options = [...this.options, ''];
      this.$emit('options', options);
    },
    removeAnswer(index) {
      this.options.splice(index, 1);
      this.$emit('options', this.options);
    }
  }
};
</script>

<style lang="sass" scoped>
.add-btn
  position: absolute

.flex-row
  display: flex
  align-items: flex-start

.h-dots
  border-top: 2px dotted rgba(0, 0, 0, .2)
  width: 15px
  margin-top: 18px
</style>

<style lang="sass">
//.modified-message > .v-messages__message
//  text-align: center !important

.add-btn
  position: absolute

.flex-row
  display: flex
  align-items: flex-start

.h-dots
  border-top: 2px dotted rgba(0, 0, 0, .2)
  width: 15px
  margin-top: 18px
</style>
