<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Fact' : 'Create New Fact' }}
    </p>

    <v-textarea
      v-model="fact.fact"
      label="Fact"
      dense
      :rules="[required('A fact must be provided')]"
      outlined
      persistent-hint
      class="span-2"
    />
    <div class="span-2">
      <v-checkbox v-model="fact.active" dense label="Is active" hide-details />
    </div>

    <loading-dialog v-model="loading" message="Fetching Fact Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import { FactsService } from '@/services/facts-service';
import { required } from '@/utils/validators';
import { getDays } from '@/utils/local';
import moment from 'moment';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new FactsService(),
    fact: {
      active: true
    }
  }),

  mounted() {
    this.loadFact();
  },

  methods: {
    required,
    getDays,
    async loadFact() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.fact = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    preCheck(context) {
      return context.validate();
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          context.changeLoadingMessage('Updating Fact');
          try {
            await this.service.update(this.fact);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating Fact',
              description: e.toString()
            });
            return false;
          }
        } else {
          try {
            context.changeLoadingMessage('Creating A New Fact');
            await this.service.create(this.fact);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating Fact',
              description: e.toString()
            });
            return false;
          }
        }
      }
    },
    formatTime(time) {
      return moment(time, 'HH:mm').format('h:mm A');
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
