var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Roadmap Flagged Questions","allow-add":false,"delete-handler":_vm.getUser()
      ? _vm.getUser().scopes.includes('roadmap-flagged-questions:delete')
        ? _vm.deleteItem
        : null
      : null,"edit-handler":_vm.getUser()
      ? _vm.getUser().scopes.includes('roadmap-flagged-questions:edit')
        ? _vm.editQuestion
        : null
      : null},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"username",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_data.name)+" ")]}},{key:"email",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_data.email)+" ")]}},{key:"reason",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.reason.split('_-_')[0])+" ")]}},{key:"explanation",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.reason.split('_-_')[1])+" ")]}},{key:"is_valid",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.is_valid),callback:function ($$v) {_vm.$set(item, "is_valid", $$v)},expression:"item.is_valid"}})]}},{key:"timestamp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }