<template>
  <v-card>
    <v-tabs class="mb-4" v-model="tab" color="primary">
      <v-tab v-for="(type, index) in types" :key="index">
        {{ type }}
      </v-tab>
    </v-tabs>

    <v-card-text v-if="tab === 0 && !this.$route.query.studentGroupId">
      <v-row>
        <v-col cols="12" md="4">
          <v-card color="indigo lighten-5" elevation="0">
            <v-card-title>IOS Registered Users</v-card-title>
            <v-card-text class="text-h6">{{ customersCount.ios }}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card color="cyan lighten-5" elevation="0">
            <v-card-title>Android Registered Users</v-card-title>
            <v-card-text class="text-h6">{{
              customersCount.android
            }}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card color="green lighten-5" elevation="0">
            <v-card-title>Web Registered Users</v-card-title>
            <v-card-text class="text-h6">{{ customersCount.web }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <all-customers :filterByUserIds="filterByUserIds" />
      </v-tab-item>

      <v-tab-item>
        <on-boarding-customers :filterByUserIds="filterByUserIds" />
      </v-tab-item>

      <v-tab-item>
        <cheating-customers />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AllCustomers from '../../components/customers/AllCustomers';
import OnBoardingCustomers from '../../components/customers/OnBoardingCustomers';
import CheatingCustomers from '../../components/customers/CheatingCustomers';
import { StudentGroupsService } from '@/services/student-groups-service';
import { CustomerService } from '../../services/customer-service';

export default {
  components: {
    AllCustomers,
    OnBoardingCustomers,
    CheatingCustomers
  },
  data: () => ({
    tab: null,
    types: ['All', 'On Boarded', 'Cheaters'],
    filterByUserIds: [],
    studentGroupsService: new StudentGroupsService(),
    customerService: new CustomerService(),

    customersCount: {
      ios: 0,
      android: 0,
      web: 0
    }
  }),

  watch: {
    $route() {
      this.checkQueryParams();
    }
  },

  mounted() {
    this.filterByUserIds = [];
    this.checkQueryParams();
  },

  methods: {
    async fetchStudentGroup() {
      const customers = await this.customerService.fetchRegisteredUsersCount();

      if (customers) {
        this.customersCount.ios = customers[0] || 0;
        this.customersCount.android = customers[1] || 0;
        this.customersCount.web = customers[2] || 0;
      }
    },

    async checkQueryParams() {
      if (this.$route.query.studentGroupId) {
        this.types = ['All', 'On Boarded'];
        this.isStudentGroupId = true;
        this.tab = 2;
        let studentGroup = await this.studentGroupsService.fetchOne(
          this.$route.query.studentGroupId
        );
        this.filterByUserIds = studentGroup.user_ids;
        this.tab = 0;
      }

      if (this.$route.query.on_boarding) {
        this.tab = 1;
      }

      await this.fetchStudentGroup();
    }
  }
};
</script>

<style></style>
