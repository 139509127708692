import FactsView from './FactsView';
import FactForm from './FactForm';
import {getUser} from "@/utils/local";

const factsRoutes = [
  getUser()?.scopes.includes('facts:view') ?
  {
    name: 'Facts',
    path: '/facts',
    component: FactsView
  } : null,
  (getUser()?.scopes.includes('facts:new') || getUser()?.scopes.includes('facts:edit')) ?
  {
    name: 'NewFact',
    path: '/fact',
    component: FactForm
  } : null
];

export const factsRouter = factsRoutes.filter(function(x) {
  return x !== null;
})
