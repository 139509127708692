var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleForm',{attrs:{"onSubmit":_vm.submit},on:{"done":function($event){return _vm.$router.push('/video-section-modal')}}},[_c('p',{staticClass:"span-2 form__title"},[_vm._v("Add New Video Sections")]),_c('div',{staticClass:"span-2 mb-6 text-right"},[_c('v-btn',{staticClass:"add-terms-btn",attrs:{"elevation":"0"},on:{"click":function () {
          _vm.videos.push({
            category: null,
            subCategory: null,
            media: null,
            selectedData: {
              images: [],
              videos: [],
              pdfs: [],
              mediaIds: []
            }
          });
        }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","dark":""}},[_vm._v(" mdi-plus-circle")]),_vm._v(" Add Video Section ")],1)],1),_vm._l((_vm.videos),function(item,idx){return _c('div',{key:String(idx),staticClass:"span-2 add-video-form"},[_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('v-icon',{staticClass:"mr-1 delete-btn",attrs:{"size":"25","color":"red"},on:{"click":function($event){return _vm.removeSectionForm(idx)}}},[_vm._v(" mdi-delete ")])],1),_c('v-select',{staticClass:"span-2",attrs:{"items":_vm.categories,"loading":_vm.loadingCategories,"rules":[_vm.required('Select a category')],"dense":"","item-text":"name","item-value":"id","label":"Select Category","outlined":"","return-object":""},on:{"change":function($event){return _vm.selectCategory(idx)}},model:{value:(item.category),callback:function ($$v) {_vm.$set(item, "category", $$v)},expression:"item.category"}}),_c('v-select',{staticClass:"span-2",attrs:{"disabled":!item.category,"items":item.subCategories,"rules":[_vm.required('Select a sub-category')],"dense":"","item-text":"name","label":"Select Subcategory","outlined":"","return-object":""},model:{value:(item.subCategory),callback:function ($$v) {_vm.$set(item, "subCategory", $$v)},expression:"item.subCategory"}}),_c('div',{staticClass:"mb-4 span-2"},[_c('h5',{staticClass:"mb-2"},[_vm._v("Select Videos:")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0","outlined":""},on:{"click":function($event){return _vm.openMediaPickerModal(idx)}}},[_vm._v(" Select Videos ")])],1),_c('div',{staticClass:"span-2 carousel-display"},[(
          item.selectedData.videos && item.selectedData.videos.length <= 0
        )?_c('h4',{staticClass:"mt-6 text-center font-weight-light mb-4"},[_vm._v(" No Video Selected ")]):_vm._e(),(item.selectedData.videos && item.selectedData.videos.length > 0)?_c('h2',{staticClass:"mt-5 mb-3"},[_vm._v(" "+_vm._s(item.selectedData.videos.length > 1 ? 'Videos' : 'Video')+" ")]):_vm._e(),_c('MediaPicker',{attrs:{"id":("mediaPickerBtn" + (idx + 1)),"selectedData":item.selectedData,"select-media-id":true},on:{"SetNewSelectedData":_vm.SetNewSelectedData}}),(item.selectedData.videos)?_c('div',[(item.selectedData.videos.length > 0)?_c('v-carousel',{staticStyle:{"width":"100%","height":"fit-content"},attrs:{"show-arrows":item.selectedData.videos.length > 1,"hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-left ")])]}},{key:"next",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right ")])]}}],null,true)},_vm._l((item.selectedData.videos),function(url,i){return _c('v-carousel-item',{key:i},[_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":url,"controls":"","height":"auto","width":"auto"}})])}),1):_vm._e()],1):_vm._e()],1)],1)}),_c('loading-dialog',{attrs:{"message":"Loading Video Data"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('ErrorDialog',{attrs:{"error":_vm.errorVal},on:{"value":function($event){return _vm.$router.back()}},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }