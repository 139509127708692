import UserForm from './UserForm';
import BonusForm from './BonusForm';
import UsersView from './UsersView';
import DailyBonus from './DailyBonus';
import CoinsLeaderboard from './CoinsLeaderboard.vue';
import SpinTheWheelCoins from './SpinTheWheelCoins.vue';
import { getUser } from '@/utils/local';
import ReviewsView from '@/views/reviews/ReviewsView';
import AccountDeletionRequestsView from '@/views/users/AccountDeletionRequestsView';
import CustomerDetail from '@/views/users/CustomerDetail';
import CustomersView from './CustomersView';

export const usersRoutes = [
  getUser()?.scopes.includes('account-deletion:view')
    ? {
        name: 'AccountDeletionRequests',
        path: '/account-deletion-requests',
        component: AccountDeletionRequestsView
      }
    : null,
  getUser()?.scopes.includes('daily-bonus:view')
    ? {
        name: 'DailyBonus',
        path: '/daily-bonus',
        component: DailyBonus
      }
    : null,
  getUser()?.scopes.includes('coins-leaderboard:view')
    ? {
        name: 'CoinsLeaderboard',
        path: '/coins-leaderboard',
        component: CoinsLeaderboard
      }
    : null,
  getUser()?.scopes.includes('spin-the-wheel-coins:view')
    ? {
        name: 'SpinTheWheelCoins',
        path: '/spin-the-wheel-coins',
        component: SpinTheWheelCoins
      }
    : null,
  // getUser()?.scopes.includes('daily-bonus:view') ?
  {
    name: 'CreateDailyBonus',
    path: '/bonus',
    component: BonusForm
  },
  getUser()?.scopes.includes('users:view')
    ? {
        name: 'Users',
        path: '/users',
        component: UsersView
      }
    : null,
  getUser()?.scopes.includes('users:new') ||
  getUser()?.scopes.includes('users:edit')
    ? {
        name: 'NewUser',
        path: '/user',
        component: UserForm
      }
    : null,
  getUser()?.scopes.includes('reviews:view')
    ? {
        path: '/reviews',
        name: 'Reviews',
        component: ReviewsView
      }
    : null,
  getUser()?.scopes.includes('customers:view') ||
  getUser()?.scopes.includes('studentGroups:view')
    ? {
        path: '/customers',
        name: 'Customers',
        component: CustomersView
      }
    : null,
  getUser()?.scopes.includes('customer:detail') ||
  getUser()?.scopes.includes('studentGroups:view')
    ? {
        path: '/customer-detail',
        name: 'CustomerDetail',
        component: CustomerDetail,
        props: true
      }
    : null
];

export const usersRouter = usersRoutes.filter(function (x) {
  return x !== null;
});
