<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">
      {{ isEdit ? 'Update User' : 'Create New User' }}
    </p>

    <v-text-field
      v-model="user.displayName"
      label="Display Name"
      :rules="[required('A display name must be provided')]"
      outlined
      class="span-2"
    />
    <v-text-field
      v-model="user.username"
      label="Email"
      dense
      :rules="[required('Username must be provided'), email()]"
      outlined
      persistent-hint
      hint="Must be a unique email"
      class="span-2"
    />

    <!--    <v-text-field-->
    <!--      v-model="user.password"-->
    <!--      label="Password"-->
    <!--      :type="showPassword ? 'text' : 'password'"-->
    <!--      dense-->
    <!--      outlined-->
    <!--    />-->
    <!--    <v-text-field-->
    <!--      v-model="confirmPassword"-->
    <!--      label="Confirm Password"-->
    <!--      :rules="-->
    <!--        user.password-->
    <!--          ? [(v) => (v && v === user.password) || 'Passwords does not match']-->
    <!--          : []-->

    <!--      "-->
    <!--      :type="showPassword ? 'text' : 'password'"-->
    <!--      dense-->
    <!--      outlined-->
    <!--    />-->
    <!--    <v-checkbox-->
    <!--      v-model="showPassword"-->
    <!--      label="Show Password"-->
    <!--      style="margin-top: -15px"-->
    <!--    />-->

    <p class="span-2">Select User Scopes</p>

    <v-treeview
      v-model="user.scopes"
      :items="items"
      selectable
      style="text-align: left"
      class="span-2"
    />

    <loading-dialog v-model="loading" message="Fetching User Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { UsersService } from '@/services/users-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, email } from '@/utils/validators';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    showPassword: false,
    service: new UsersService(),
    confirmPassword: '',
    user: {
      scopes: []
    },

    items: [
      {
        id: 'setting',
        name: 'Settings',
        children: [
          { id: 'settings:view', name: 'View Settings' },
          { id: 'settings:edit', name: 'Edit Settings' }
        ]
      },
      {
        id: 'events',
        name: 'Events',
        children: [
          { id: 'events:new', name: 'Create Events' },
          { id: 'events:view', name: 'View Events' },
          { id: 'events:edit', name: 'Edit Events' },
          { id: 'events:delete', name: 'Delete Events' }
        ]
      },
      {
        id: 'notifications',
        name: 'Notifications',
        children: [
          { id: 'notifications:new', name: 'Create Notifications' },
          { id: 'notifications:view', name: 'View Notifications' },
          { id: 'notifications:edit', name: 'Edit Notifications' },
          { id: 'notifications:delete', name: 'Delete Notifications' }
        ]
      },
      {
        id: 'facts',
        name: 'Facts',
        children: [
          { id: 'facts:new', name: 'Create Facts' },
          { id: 'facts:view', name: 'View Facts' },
          { id: 'facts:edit', name: 'Edit Facts' },
          { id: 'facts:delete', name: 'Delete Facts' }
        ]
      },
      {
        id: 'popups',
        name: 'Popups',
        children: [
          { id: 'popups:new', name: 'Create Popups' },
          { id: 'popups:view', name: 'View Popups' },
          { id: 'popups:edit', name: 'Edit Popups' },
          { id: 'popups:delete', name: 'Delete Popups' }
        ]
      },
      {
        id: 'banners',
        name: 'Banners',
        children: [
          { id: 'banners:new', name: 'Create Banners' },
          { id: 'banners:view', name: 'View Banners' },
          { id: 'banners:delete', name: 'Delete Banners' }
        ]
      },
      {
        id: 'ads',
        name: 'Ads',
        children: [
          { id: 'ads:new', name: 'Create Ads' },
          { id: 'ads:view', name: 'View Ads' },
          { id: 'ads:edit', name: 'Edit Ads' },
          { id: 'ads:delete', name: 'Delete Ads' }
        ]
      },
      {
        id: 'iaps',
        name: 'In App Purchases',
        children: [
          { id: 'iaps:view', name: 'View In App Purchases' },
          { id: 'iaps:edit', name: 'Edit In App Purchases' },
          { id: 'iaps-offers:view', name: 'View In App Purchases Offers' },
          { id: 'iaps-offers:new', name: 'Create In App Purchases Offers' },
          { id: 'iaps-offers:edit', name: 'Edit In App Purchases Offers' },
          { id: 'iaps-offers:delete', name: 'Delete In App Purchases Offers' }
        ]
      },
      {
        id: 'web-aps',
        name: 'Web App Purchases',
        children: [
          { id: 'web-aps:view', name: 'View Web App Purchases' },
          { id: 'web-aps-coupons:new', name: 'Create Web App Coupons' },
          { id: 'web-aps-coupons:edit', name: 'Edit Web App Coupons' },
          { id: 'web-aps-coupons:view', name: 'View Web App Coupons' },
          { id: 'web-aps-coupons:delete', name: 'Delete Web App Coupons' },
          { id: 'web-aps-prices:edit', name: 'Edit Web App Prices' },
          { id: 'web-aps-prices:view', name: 'View Web App Prices' }
        ]
      },
      {
        id: 'institutions',
        name: 'Institutions',
        children: [
          { id: 'institutions:new', name: 'Create Institutions' },
          { id: 'institutions:view', name: 'View Institutions' },
          { id: 'institutions:edit', name: 'Edit Institutions' },
          { id: 'institutions:delete', name: 'Delete Institutions' }
        ]
      },
      {
        id: 'questions',
        name: 'Questions',
        children: [
          { id: 'questions:new', name: 'Create Questions' },
          { id: 'questions:view', name: 'View Questions' },
          { id: 'questions:edit', name: 'Edit Questions' },
          { id: 'questions:import', name: 'Import Questions' },
          { id: 'questions:export', name: 'Export Questions' },
          { id: 'questions:delete', name: 'Delete Questions' }
        ]
      },
      {
        id: 'comments',
        name: 'Comments',
        children: [
          { id: 'comments:view', name: 'View Comments' },
          { id: 'comments:edit', name: 'Edit Comments' },
          { id: 'comments:delete', name: 'Delete Comments' }
        ]
      },
      {
        id: 'flagged-questions',
        name: 'Flagged Questions',
        children: [
          { id: 'flagged-questions:view', name: 'View Flagged Questions' },
          { id: 'flagged-questions:edit', name: 'Edit Flagged Questions' },
          { id: 'flagged-questions:delete', name: 'Delete Flagged Questions' }
        ]
      },
      {
        id: 'passage-questions',
        name: 'Passage Questions',
        children: [
          { id: 'passage-questions:view', name: 'View Passage Questions' },
          { id: 'passage-questions:new', name: 'Create Passage Questions' },
          { id: 'passage-questions:edit', name: 'Edit Passage Questions' },
          { id: 'passage-questions:import', name: 'Import Passage Questions' },
          { id: 'passage-questions:export', name: 'Export Passage Questions' },
          { id: 'passage-questions:delete', name: 'Delete Passage Questions' }
        ]
      },
      {
        id: 'passage-prompts',
        name: 'Passage Prompts',
        children: [
          { id: 'passage-prompts:view', name: 'View Passage Prompts' },
          { id: 'passage-prompts:new', name: 'Create Passage Prompts' },
          { id: 'passage-prompts:edit', name: 'Edit Passage Prompts' },
          { id: 'passage-prompts:delete', name: 'Delete Passage Prompts' }
        ]
      },
      {
        id: 'categories',
        name: 'Categories',
        children: [
          { id: 'categories:new', name: 'Create  Categories' },
          { id: 'categories:view', name: 'View  Categories' },
          { id: 'categories:edit', name: 'Edit  Categories' },
          { id: 'categories:delete', name: 'Delete  Categories' }
        ]
      },
      {
        id: 'sub-categories',
        name: 'Sub-Categories',
        children: [
          { id: 'sub-categories:new', name: 'Create Sub-Categories' },
          { id: 'sub-categories:view', name: 'View Sub-Categories' },
          { id: 'sub-categories:edit', name: 'Edit Sub-Categories' },
          { id: 'sub-categories:delete', name: 'Delete Sub-Categories' }
        ]
      },
      {
        id: 'question-media',
        name: 'Question Media',
        children: [
          { id: 'question-media:view', name: 'View Question Media' },
          { id: 'question-media:new', name: 'Create Question Media' },
          { id: 'question-media:edit', name: 'Edit Question Media' },
          { id: 'question-media:delete', name: 'Delete Question Media' }
        ]
      },
      {
        id: 'user-contributions',
        name: 'User Contributions',
        children: [
          { id: 'user-contributions:view', name: 'View User Contributions' },
          {
            id: 'user-contributions:edit',
            name: 'Edit, Approve and Reject User Contributions'
          }
        ]
      },
      {
        id: 'decks',
        name: 'Decks',
        children: [
          { id: 'decks:new', name: 'Create  Decks' },
          { id: 'decks:view', name: 'View  Decks' },
          { id: 'decks:edit', name: 'Edit  Decks' },
          { id: 'decks:delete', name: 'Delete  Decks' }
        ]
      },
      {
        id: 'flashcards',
        name: 'Flashcards',
        children: [
          { id: 'flashcards:new', name: 'Create Flashcards' },
          { id: 'flashcards:view', name: 'View Flashcards' },
          { id: 'flashcards:edit', name: 'Edit Flashcards' },
          { id: 'flashcards:delete', name: 'Delete Flashcards' }
        ]
      },
      {
        id: 'roadmap',
        name: 'Roadmap',
        children: [
          { id: 'roadmap-chapters:new', name: 'Create Chapters' },
          { id: 'roadmap-chapters:view', name: 'View Chapters' },
          { id: 'roadmap-chapters:edit', name: 'Edit Chapters' },
          { id: 'roadmap-chapters:delete', name: 'Delete Chapters' },
          { id: 'roadmap-flashcards:new', name: 'Create Flashcards' },
          { id: 'roadmap-flashcards:view', name: 'View Flashcards' },
          { id: 'roadmap-flashcards:edit', name: 'Edit Flashcards' },
          { id: 'roadmap-flashcards:delete', name: 'Delete Flashcards' },
          {
            id: 'roadmap-flagged-questions:view',
            name: 'View Flagged Questions'
          },
          {
            id: 'roadmap-flagged-questions:edit',
            name: 'Edit Flagged Questions'
          },
          {
            id: 'roadmap-flagged-questions:delete',
            name: 'Delete Flagged Questions'
          },
          { id: 'roadmap-suggestion:view', name: 'View Suggestions' },
          { id: 'roadmap-suggestion:edit', name: 'Edit Suggestions' },
          { id: 'roadmap-stats:view', name: 'View Stats' }
        ]
      },
      {
        id: 'users',
        name: 'Users',
        children: [
          { id: 'users:new', name: 'Create  Users' },
          { id: 'users:view', name: 'View  Users' },
          { id: 'users:edit', name: 'Edit  Users' },
          { id: 'users:delete', name: 'Delete  Users' }
        ]
      },
      {
        id: 'stats',
        name: 'Stats',
        children: [{ id: 'stats:view', name: 'View  Stats' }]
      },
      {
        id: 'account-deletion',
        name: 'Account Deletion Requests',
        children: [
          {
            id: 'account-deletion:view',
            name: 'View Account Deletion Requests'
          }
        ]
      },

      {
        id: 'customers',
        name: 'Customers',
        children: [
          { id: 'customers:view', name: 'View Customers' },
          { id: 'customers:export', name: 'Export Customers' },
          { id: 'customer:detail', name: 'View Customer Detail' },
          { id: 'customer:unban', name: 'Unban Customer' }
        ]
      },

      {
        id: 'media',
        name: 'Media',
        children: [
          { id: 'media:new', name: 'Create Media' },
          { id: 'media:view', name: 'View Media' },
          { id: 'media:edit', name: 'Edit Media' },
          { id: 'media:delete', name: 'Delete Media' }
        ]
      },

      {
        id: 'videoSection',
        name: 'Video Sections',
        children: [
          { id: 'video:view', name: 'View Video Sections' },
          { id: 'video:edit', name: 'Edit Edit Video Sections' }
        ]
      },

      {
        id: 'reviews',
        name: 'Reviews',
        children: [{ id: 'reviews:view', name: 'View  Reviews' }]
      },
      {
        id: 'promoRefer',
        name: 'Promo Refers',
        children: [{ id: 'promoRefer:view', name: 'View  Promo Refers' }]
      },
      {
        id: 'scheduledQotd',
        name: 'Scheduled QOTD',
        children: [
          { id: 'scheduledQotd:view', name: 'View Scheduled QOTD' },
          { id: 'scheduledQotd:edit', name: 'Edit Scheduled QOTD' }
        ]
      },
      {
        id: 'studentGroups',
        name: 'Student Groups',
        children: [
          { id: 'studentGroups:all', name: 'All Student Groups' },
          { id: 'studentGroups:new', name: 'Create Student Groups' },
          { id: 'studentGroups:view', name: 'View Student Groups' },
          { id: 'studentGroups:edit', name: 'Edit Student Groups' },
          { id: 'studentGroups:delete', name: 'Delete Student Groups' }
        ]
      },
      {
        id: 'popupNotifications',
        name: 'Popup Notifications',
        children: [
          { id: 'popup-notifications:new', name: 'Create Popup Notifications' },
          { id: 'popup-notifications:view', name: 'View Popup Notifications' },
          { id: 'popup-notifications:edit', name: 'Edit Popup Notifications' },
          {
            id: 'popup-notifications:delete',
            name: 'Delete Popup Notifications'
          }
        ]
      },
      {
        id: 'dailyBonus',
        name: 'Daily Bonus/Currency',
        children: [{ id: 'daily-bonus:view', name: 'View Daily Bonus' }]
      },
      {
        id: 'coinsLeaderboard',
        name: 'Coins Leaderboard',
        children: [
          { id: 'coins-leaderboard:view', name: 'View Coins Leaderboard' }
        ]
      },
      {
        id: 'spinTheWheel',
        name: 'Spin The Wheel Coins',
        children: [
          { id: 'spin-the-wheel-coins:view', name: 'View Spin The Wheel Coins' }
        ]
      }
    ]
  }),

  mounted() {
    this.loadUser();
  },

  methods: {
    required,
    email,
    async loadUser() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.user = await this.service.fetchOne(this.$route.query.id);
      // this.confirmPassword = this.user.password;
      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating User');
        try {
          // if (!this.user.password) {
          //   delete this.user.password;
          // }

          await this.service.update(this.user);
          this.$toast.success('User updated successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating User',
            description: e.response.data.error
          });

          return false;
        }
      } else {
        context.changeLoadingMessage('Creating A New User');
        try {
          await this.service.create(this.user);
          this.$toast.success(
            'Admin invite email sent successfully. Admin will set their password upon clicking the invitation and logging in.'
          );

          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating User',
            description: e.response.data.error
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
