var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleForm',{attrs:{"onSubmit":_vm.submit},on:{"done":function($event){_vm.isEdit ? _vm.reload() : _vm.$router.push('/question-medias')}}},[_c('div',{staticClass:"d-flex align-center justify-space-between span-2 mt-3 mb-8"},[(_vm.isEdit)?_c('v-btn',{attrs:{"fab":"","icon":"","elevation":"0","color":"primary","disabled":!_vm.previous,"small":"","outlined":""},on:{"click":function($event){return _vm.loadMoreMedia(false)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e(),_c('p',{staticClass:"text-h5 font-weight-bold ma-0 text-center flex-grow-1"},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Update Question Media' : 'Add New Question Media')+" ")]),(_vm.isEdit)?_c('v-btn',{attrs:{"fab":"","icon":"","elevation":"0","color":"primary","small":"","outlined":"","disabled":!_vm.next},on:{"click":function($event){return _vm.loadMoreMedia(true)}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1):_vm._e()],1),_c('v-checkbox',{staticClass:"span-2",attrs:{"label":"Is question media for active categories?","color":"primary"},on:{"change":function($event){_vm.loadCategories();
      _vm.questionMedia.categories = [];
      _vm.questionMedia.subCategories = [];}},model:{value:(_vm.isActiveQuestionMedia),callback:function ($$v) {_vm.isActiveQuestionMedia=$$v},expression:"isActiveQuestionMedia"}}),_c('v-combobox',{staticClass:"span-2",attrs:{"multiple":"","label":"Keywords","delimiters":[','],"append-icon":"","rules":[_vm.requiredArray()],"chips":"","deletable-chips":"","outlined":"","search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":[function($event){_vm.search=$event},_vm.getKeywords]},model:{value:(_vm.questionMedia.keywords),callback:function ($$v) {_vm.$set(_vm.questionMedia, "keywords", $$v)},expression:"questionMedia.keywords"}}),_c('v-select',{staticClass:"span-2",attrs:{"label":"Select Category","item-text":"name","item-value":"id","items":_vm.categories,"loading":_vm.loadingCategories,"multiple":"","outlined":""},on:{"change":_vm.filterSubCategories},model:{value:(_vm.questionMedia.categories),callback:function ($$v) {_vm.$set(_vm.questionMedia, "categories", $$v)},expression:"questionMedia.categories"}}),_c('v-select',{staticClass:"span-2",attrs:{"label":"Select Sub Category","item-text":"name","item-value":"id","items":_vm.subCategories,"loading":_vm.loadingSubCategories,"multiple":"","outlined":""},model:{value:(_vm.questionMedia.subCategories),callback:function ($$v) {_vm.$set(_vm.questionMedia, "subCategories", $$v)},expression:"questionMedia.subCategories"}}),_c('v-divider',{staticClass:"span-2",staticStyle:{"margin-bottom":"20px"}}),_c('div',{staticClass:"mb-4 d-flex justify-space-between items-center span-2"},[_c('h3',[_vm._v("Select Media")]),_c('v-btn',{attrs:{"outlined":"","elevation":"0","color":"primary"},on:{"click":_vm.openMediaPickerModal}},[_vm._v("Select")])],1),_c('MediaPicker',{attrs:{"selectedData":_vm.selectedData,"isActive":_vm.isActiveQuestionMedia},on:{"SetNewSelectedData":_vm.setSelectedData}}),_c('div',{staticClass:"span-2 carousel-display"},[(
        _vm.selectedData.images.length <= 0 &&
        _vm.selectedData.videos.length <= 0 &&
        _vm.selectedData.pdfs.length <= 0
      )?_c('h4',{staticClass:"mt-3 text-center font-weight-light"},[_vm._v(" No Media Selected ")]):_vm._e(),(_vm.selectedData.images.length === 1)?_c('h2',{staticClass:"mt-2 mb-3"},[_vm._v("Image")]):_vm._e(),(_vm.selectedData.images.length > 1)?_c('h2',{staticClass:"mt-2 mb-3"},[_vm._v("Images")]):_vm._e(),(_vm.selectedData.images.length > 0)?_c('v-carousel',{staticStyle:{"width":"100%","height":"fit-content"},attrs:{"show-arrows":_vm.selectedData.images.length > 1,"hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-left ")])]}},{key:"next",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right ")])]}}],null,false,2164461060)},_vm._l((_vm.selectedData.images),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item}})}),1):_vm._e(),(_vm.selectedData.videos.length === 1)?_c('h2',{staticClass:"mt-2 mb-3"},[_vm._v("Video")]):_vm._e(),(_vm.selectedData.videos.length > 1)?_c('h2',{staticClass:"mt-2 mb-3"},[_vm._v("Videos")]):_vm._e(),(_vm.selectedData.videos.length > 0)?_c('v-carousel',{staticStyle:{"width":"100%","height":"fit-content"},attrs:{"show-arrows":_vm.selectedData.videos.length > 1,"hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-left ")])]}},{key:"next",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right ")])]}}],null,false,2164461060)},_vm._l((_vm.selectedData.videos),function(item,i){return _c('v-carousel-item',{key:i},[_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"width":"auto","height":"auto","src":item,"controls":""}})])}),1):_vm._e(),(_vm.selectedData.pdfs.length === 1)?_c('h2',{staticClass:"mt-2 mb-3 float-start"},[_vm._v(" PDF ")]):_vm._e(),(_vm.selectedData.pdfs.length > 1)?_c('h2',{staticClass:"mt-2 mb-3 float-start"},[_vm._v(" PDFs ")]):_vm._e(),(_vm.selectedData.pdfs.length > 0)?_c('a',{staticClass:"text-center float-end text-decoration-none",attrs:{"href":_vm.selectedData.pdfs[_vm.displayedPdfIndex],"target":"_blank"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"small":"","elevation":"0","color":"black"}},[_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v(" mdi-arrow-expand-all ")])],1)],1):_vm._e(),(_vm.selectedData.pdfs.length > 0)?_c('v-carousel',{staticStyle:{"width":"100%","height":"fit-content"},attrs:{"show-arrows":_vm.selectedData.pdfs.length > 1,"hide-delimiters":""},on:{"change":_vm.pdfChange},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-left ")])]}},{key:"next",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"10px","background-color":"white","border-radius":"50%"},attrs:{"color":"#4396e4"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right ")])]}}],null,false,2164461060)},_vm._l((_vm.selectedData.pdfs),function(item,i){return _c('v-carousel-item',{key:i},[_c('iframe',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item}})])}),1):_vm._e()],1),(_vm.isEdit && _vm.questionMedia.questions)?_c('v-divider',{staticClass:"span-2",staticStyle:{"margin-bottom":"20px"}}):_vm._e(),(
      _vm.isEdit && _vm.questionMedia.questions && _vm.questionMedia.questions.length > 0
    )?_c('div',{staticClass:"span-2 pa-2 mb-2 rounded",staticStyle:{"gap":"30px","border":"1px solid black"}},[_c('p',[_vm._v("Questions")]),_vm._l((_vm.questionMedia.questions),function(question,i){return _c('v-chip',{key:i,staticClass:"mb-2",attrs:{"close":""},on:{"click":function($event){return _vm.openQuestion(question)},"click:close":function($event){return _vm.excludeQuestion(question)}}},[_vm._v(" "+_vm._s(question.statement.substring(0, 50))+_vm._s(question.statement.length > 50 ? '...' : '')+" ")])})],2):_vm._e(),(_vm.isEdit && _vm.questionMedia.excluded && _vm.questionMedia.excluded.length)?_c('div',{staticClass:"span-2 pa-2 mb-2 rounded",staticStyle:{"gap":"30px","border":"1px solid black"}},[_c('p',[_vm._v("Excluded Questions")]),_vm._l((_vm.questionMedia.excluded),function(question,i){return _c('v-chip',{key:i,staticClass:"mb-2",on:{"click":function($event){return _vm.openQuestion(question)}}},[_vm._v(" "+_vm._s(question.statement.substring(0, 50))+_vm._s(question.statement.length > 50 ? '...' : '')+" ")])})],2):_vm._e(),_c('loading-dialog',{attrs:{"message":"Loading Question Media"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }