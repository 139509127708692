var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":_vm.getTitle(this.status) +  ' User Contributions',"allow-add":false,"delete-handler":null,"edit-handler":_vm.getUser() ? _vm.getUser().scopes.includes('user-contributions:edit') ? _vm.edit : null : null},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [(_vm.getUser() && _vm.getUser().scopes.includes('user-contributions:edit') && _vm.status === 0)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.changeStatus(1, item)}}},[_vm._v("mdi-check-circle")]):_vm._e(),(_vm.getUser() && _vm.getUser().scopes.includes('user-contributions:edit') && _vm.status === 0)?_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.changeStatus(2, item)}}},[_vm._v("mdi-close-circle")]):_vm._e()]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category.name)+" ")]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.type))+" ")]}},{key:"user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}}])}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }