<template>
  <v-dialog persistent width="40%" v-model="showFilter">
    <v-card>
      <v-card-title class="ml-2">Filter By Field</v-card-title>

      <div class="px-6 pt-2 pb-6">
        <v-form ref="filterForm">
          <v-select
            dense
            :items="filterAttr.category.map((item) => item.name)"
            label="Category"
            placeholder="Category"
            :value="filterSelectedAttr.category"
            :rules="[required('Select a category')]"
            @change="selectFilterCategory"
            clearable
            outlined
          />

          <v-select
            dense
            :items="filterAttr.subcategory"
            label="Sub-category"
            placeholder="Sub-category"
            :rules="[required('Select a sub-category')]"
            :value="filterSelectedAttr.subcategory"
            @change="selectFilterSubCategory"
            clearable
            outlined
          >
            <template v-slot:no-data>
              <div class="px-2">
                <p class="ma-0 text-caption" v-if="filterSelectedAttr.category">
                  No data available
                </p>
                <p class="ma-0 text-caption" v-else>Select Category First</p>
              </div>
            </template>
          </v-select>
        </v-form>

        <div class="d-flex justify-space-between">
          <v-btn @click="$router.push('/')">Cancel</v-btn>
          <div>
            <v-btn @click="filterClear" class="mr-2">Clear</v-btn>
            <v-btn @click="applyFilter" class="ml-2" color="primary"
              >Apply
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { CategoryService } from '@/services/category-service';
import { required } from '@/utils/validators';

export default {
  name: 'VideoSectionModal',
  async mounted() {
    let categoryService = new CategoryService();
    categoryService
      .fetchAll()
      .then((res) => {
        this.filterAttr.category = res;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      showFilter: true,
      filterAttr: {
        category: [],
        subcategory: []
      },
      filterSelectedAttr: {
        category: '',
        subcategory: ''
      }
    };
  },
  methods: {
    required,
    selectFilterCategory(value) {
      if (!value) {
        this.filterAttr.subcategory = [];
        this.filterSelectedAttr.category = '';
        this.filterSelectedAttr.subcategory = '';
      } else {
        this.filterAttr.subcategory = this.filterAttr.category
          .find((item) => item.name === value)
          .subCategories.map((item) => item.name);
        this.filterSelectedAttr.category = value;
      }
    },

    selectFilterSubCategory(value) {
      if (value) {
        this.filterSelectedAttr.subcategory = value;
      } else {
        this.filterSelectedAttr.subcategory = '';
      }
    },

    async applyFilter() {
      const isCategorySelected = this.filterSelectedAttr.category !== '';
      const isSubcategorySelected = this.filterSelectedAttr.subcategory !== '';
      if (isCategorySelected && isSubcategorySelected) {
        this.showFilter = false;

        const category = this.filterAttr.category.find(
          (item) => item.name === this.filterSelectedAttr.category
        );
        const subcategory = category.subCategories.find(
          (item) => item.name === this.filterSelectedAttr.subcategory
        );

        await this.$router.push(
          `/video-sections?category=${category.id}&subcategory=${subcategory.id}`
        );
      } else {
        this.$refs.filterForm.validate();
      }
    },

    async filterClear() {
      if (this.allowedType) {
        this.filterSelectedAttr = {
          category: '',
          subcategory: ''
        };
      } else {
        this.filterSelectedAttr = {
          category: '',
          subcategory: ''
        };
      }
    }
  }
};
</script>
