<template>
  <div></div>
</template>

<script>
export default {
  name: 'InAppPurchaseView'
};
</script>

<style scoped></style>