var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Facts","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('facts:new') : false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('facts:delete')
          ? _vm.service.delete
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('facts:edit')
          ? _vm.edit
          : null
        : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"active",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}}])}),_c('loading-dialog',{attrs:{"message":"Sending Notification"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }