<template>
  <div>
    <div class="dashboard-cards">
      <stat-card
        class="gameCard"
        title="Total Games"
        :value="games"
        color="secondary"
        :loading="gamesLoading"
        filter
        @applyFilter="applyFilterGames"
      />

      <stat-card
        title="Total Multiplayer Games"
        :value="dashboardCards.multiplayer_games"
        color="purple"
        :loading="loading"
      />
      <stat-card
        title="Multiplayer Games Won"
        :value="dashboardCards.win"
        color="green"
        :loading="loading"
      />
      <stat-card
        title="Total Questions Answered"
        :value="dashboardCards.totalQuestionAnswered"
        color="orange"
        :loading="loading"
      />
      <stat-card
        title="Multiplayer Games Lost"
        :value="dashboardCards.lost"
        color="pink"
        :loading="loading"
      />
      <stat-card
        @cardClick="toCustomer"
        class="pointer"
        title="On Boarded Customers"
        :value="dashboardCards.on_boarding_users"
        color="blue"
        :loading="loading"
      />
      <stat-card
        title="Total Customers"
        :value="dashboardCards.total_number_of_users"
        color="orange"
        :loading="loading"
      />
    </div>

    <div class="dashboard-graphs">
      <v-card class="dashboard-graph">
        <PieChartComp :fetch="fetchQuestions" @completed="fetchGames = true" />
      </v-card>
      <v-card class="dashboard-graph">
        <bar-chart-games :fetch="fetchGames" @completed="fetchUsers = true" />
      </v-card>
    </div>
    <div class="dashboard-graphs-one">
      <!-- <v-card class="dashboard-graph">
        <bar-chart-users :fetch="fetchUsers" @completed="fetchModes = true" />
      </v-card> -->
      <v-card class="dashboard-graph" v-if="!sgId">
        <bar-chart-users-revamp @completed="fetchModes = true" />
      </v-card>
      <v-card class="dashboard-graph">
        <LineChartComp :fetch="sgId ? true : fetchModes" />
      </v-card>
    </div>
  </div>
</template>

<script>
import StatCard from '@/components/dashboard/StatCard';
import { DashboardService } from '@/services/dashboard-service';
import PieChartComp from '@/components/dashboard/charts/pie-chart';
import BarChartGames from '@/components/dashboard/charts/bar-chart-games';
// import BarChartUsers from '@/components/dashboard/charts/bar-chart-users';
import BarChartUsersRevamp from '@/components/dashboard/charts/bar-chart-users-revamp';
import LineChartComp from '@/components/dashboard/charts/line-chart';
import { oneSignalApiKey } from '@/utils/local';
import crypto from 'crypto';
import { getUser } from '@/utils/local';

export default {
  name: 'Stats',
  components: {
    LineChartComp,
    // BarChartUsers,
    BarChartUsersRevamp,
    BarChartGames,
    PieChartComp,
    StatCard
  },
  data: () => ({
    loading: false,
    gamesLoading: false,
    loadingDialog: false,
    dashboardCards: {},
    games: 0,
    service: new DashboardService(),
    fetchQuestions: false,
    fetchGames: false,
    fetchUsers: false,
    fetchModes: false,
    sgId: null
  }),
  mounted() {
    if (this.$route.query.sgId) {
      this.sgId = this.$route.query.sgId;
    } else {
      if (!this.getUser()?.scopes.includes('stats:view')) {
        this.$router.push('/404');
      }
    }

    try {
      const token = localStorage.getItem('auth_token');
      const user = token ? JSON.parse(localStorage.getItem('auth_user')) : null;
      this.$OneSignal
        .init({
          appId: 'f42c2e97-47b8-46a6-aa4a-3be8f56d9d1c',
          safari_web_id:
            'web.onesignal.auto.1ee85315-99d2-4859-abe2-d0d2d86b4cd7',
          promptOptions: {
            siteName: 'King of the Curve',
            actionMessage:
              "We'd like to show you notifications for the latest updates.",
            exampleNotificationTitle: 'Flagged Question',
            exampleNotificationMessage:
              'User johndoa123@gmail.com  has flagged a question.',
            exampleNotificationCaption: 'You can unsubscribe anytime',
            acceptButtonText: 'ALLOW',
            cancelButtonText: 'NO THANKS',
            autoAcceptTitle: 'Click Allow',
            slidedown: {
              prompts: [
                {
                  type: 'push',
                  autoPrompt: true,
                  text: {
                    actionMessage:
                      "We'd like to show you notifications for the latest updates.",
                    acceptButton: 'Allow',
                    cancelButton: 'No Thanks'
                  },
                  delay: {
                    pageViews: 1,
                    timeDelay: 5
                  }
                }
              ]
            }
          },
          welcomeNotification: {
            disable: false,
            title: 'Welcome to KOTC admin',
            message: 'Thanks for subscribing. Now you can stay up to date.'
          },
          notifyButton: {
            enable: true,
            size: 'medium',
            showCredit: false
          },
          subdomainName: 'kotc'
        })
        .then(() => {
          const hmac = crypto
            .createHmac('sha256', oneSignalApiKey)
            .update(user.id.toString());
          this.$OneSignal
            .setExternalUserId(user.id.toString(), hmac.digest('hex'))
            .then(() => {
              console.log('user id set');
            })
            .catch((e) => {
              console.log('id set error');
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
    // this.getData()
    this.getDashboardData();
  },

  methods: {
    getUser,

    // async uploadData() {
    //
    //   for (let i = 0; i < this.videos.length; i++) {
    //     const blob = await this.generateThumbnail(this.videos[i]);
    //     let thumbRef = storage.ref('videos/thumbnails/' + blob.name);
    //     let thumbTask = thumbRef.put(blob.file);
    //     await thumbTask.then(() => {
    //       window.console.log('thumbnail posted', i)
    //     }).catch((e) => window.console.log('uploading image error => ', e))
    //   }
    //
    //   console.log('done')
    // },
    // async generateThumbnail(url) {
    //   const urlObject = decodeURIComponent(url)
    //   let fileName = urlObject.split('videos/').pop()
    //   fileName = fileName.split('?').shift()
    //
    //   const canvas = document.createElement('canvas')
    //   const context = canvas.getContext('2d')
    //   const video = document.createElement('video')
    //   video.setAttribute('src', url)
    //   video.setAttribute('crossorigin', 'anonymous')
    //   video.load()
    //   let thumbnail = await new Promise((resolve) => {
    //     video.onloadedmetadata = async () => {
    //       canvas.width = video.videoWidth
    //       canvas.height = video.videoHeight
    //       video.currentTime = video.duration / 2
    //       await video.play()
    //       context.drawImage(video, 0, 0)
    //       video.pause()
    //       const blob = await new Promise((resolve) => {
    //         return canvas.toBlob(function (blob) {
    //           resolve(blob)
    //         })
    //       })
    //       resolve(blob)
    //     }
    //   })
    //
    //   return {
    //     name: fileName,
    //     file: thumbnail
    //   }
    // },
    async getDashboardData() {
      this.loading = true;
      await this.applyFilterGames();
      this.dashboardCards = await this.service.fetch(0, null, this.sgId);
      const totalQuestionAnswered = await this.service.fetch(
        7,
        null,
        this.sgId
      );
      this.dashboardCards.totalQuestionAnswered = totalQuestionAnswered.Count;
      this.loading = false;
      this.fetchQuestions = true;
    },
    async applyFilterGames(data = null) {
      this.gamesLoading = true;
      const games = await this.service.fetch(5, data, this.sgId);
      this.games = games.games;
      this.gamesLoading = false;
    },
    toCustomer() {
      this.$router.push('/customers?on_boarding=true');
    }
  }
};
</script>

<style scoped>
.dashboard-cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-bottom: 30px;
}

.dashboard-graphs {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-bottom: 30px;
}

.dashboard-graphs-one {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-bottom: 30px;
}

@media screen and (min-width: 580px) {
  .dashboard-cards {
    grid-template-columns: 1fr 1fr;
  }

  .gameCard {
    grid-column: 1 / -1;
  }

  .dashboard-graphs {
    grid-template-columns: 1fr 1fr;
  }

  .dashboard-graphs-one {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1000px) {
  .dashboard-cards {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .gameCard {
    grid-column: 1;
  }

  .dashboard-graphs {
    grid-template-columns: 1fr 2fr;
  }

  /*.dashboard-graphs-one {*/
  /*  grid-template-columns: 2fr 1fr;*/
  /*}*/
}
</style>
