<template>
  <div>
    <v-row v-if="!loading">
      <v-col cols="12" height="100%" md="4" sm="6">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #da57a7; color: white"
          >
            <v-icon class="mr-2" color="white" size="28">mdi-account</v-icon>
            <h5 class="text-h5">Personal Info</h5>
          </div>

          <v-card-text class="px-6">
            <div v-if="customer_details">
              <div v-if="customer_details.name">
                <p class="text-subtitle-1 mb-0">
                  <b>Name</b> : {{ customer_details.name }}
                </p>
              </div>

              <div v-if="customer_details.email">
                <p class="text-subtitle-1 mb-0">
                  <b>Email</b> : {{ customer_details.email }}
                </p>
              </div>
            </div>
            <span v-else class="px-6">Please wait... </span>

            <div v-if="customer_details && customer_details.on_boarding">
              <div v-if="customer_details.on_boarding.real_name">
                <p class="text-subtitle-1 mb-0">
                  <b>Real Name</b> :
                  {{ customer_details.on_boarding.real_name }}
                </p>
              </div>

              <div v-if="customer_details.on_boarding.user_name">
                <p class="text-subtitle-1 mb-0">
                  <b>Username</b> : {{ customer_details.on_boarding.user_name }}
                </p>
              </div>

              <div v-if="customer_details.on_boarding.gender">
                <p class="text-subtitle-1 mb-0">
                  <b>Gender</b> : {{ customer_details.on_boarding.gender }}
                </p>
              </div>

              <div v-if="customer_details.on_boarding.age">
                <p class="text-subtitle-1 mb-0">
                  <b>Age</b> : {{ customer_details.on_boarding.age }} Years
                </p>
              </div>
              <div v-if="customer_details.on_boarding.household_income">
                <p class="text-subtitle-1 mb-0">
                  <b>House Hold Income</b> :
                  {{ customer_details.on_boarding.household_income }}
                </p>
              </div>
              <div v-if="customer_details.on_boarding.employment_status">
                <p class="text-subtitle-1 mb-0">
                  <b>Employment Status</b> :
                  {{
                    customer_details.on_boarding.employment_status
                      .employment_type === 'Other (please specify)'
                      ? customer_details.on_boarding.employment_status
                          .other_employment
                      : customer_details.on_boarding.employment_status
                          .employment_type
                  }}
                </p>
              </div>
              <div v-if="customer_details.on_boarding.level_of_education">
                <p class="text-subtitle-1 mb-0">
                  <b>Level of Education</b> :
                  {{ customer_details.on_boarding.level_of_education }}
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="4" sm="6">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #70aad5; color: white"
          >
            <v-icon class="mr-2" color="white" size="28"
              >mdi-clipboard-search-outline</v-icon
            >
            <h5 class="text-h5">Previous MCAT</h5>
          </div>

          <v-card-text
            v-if="customer_details && customer_details.on_boarding"
            class="px-6"
          >
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('is_mcat_taken_before') }}
              </p>
              <p
                v-if="
                  customer_details.on_boarding.is_mcat_taken_before !== null
                "
                class="text-subtitle-1 mb-0"
              >
                {{
                  customer_details.on_boarding.is_mcat_taken_before
                    ? 'Yes'
                    : 'No'
                }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('mcat_previous_test_date') }}
              </p>
              <p
                v-if="
                  customer_details.on_boarding.mcat_previous_test_date !==
                    null &&
                  customer_details.on_boarding.mcat_previous_test_date !== ''
                "
                class="text-subtitle-1 mb-0"
              >
                {{
                  formatDate(
                    customer_details.on_boarding.mcat_previous_test_date
                  )
                }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('mcat_previous_test_score') }}
              </p>
              <p
                v-if="customer_details.on_boarding.mcat_previous_test_score"
                class="text-subtitle-1 mb-0"
              >
                {{ customer_details.on_boarding.mcat_previous_test_score }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('last_practice_score') }}
              </p>
              <p
                v-if="customer_details.on_boarding.last_practice_score"
                class="text-subtitle-1 mb-0"
              >
                {{ customer_details.on_boarding.last_practice_score }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4"
            >No data found for previous MCAT!</v-card-text
          >
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="4">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #ee8782; color: white"
          >
            <v-icon class="mr-2" color="white" size="28"
              >mdi-clipboard-text-clock</v-icon
            >
            <h5 class="text-h5">Upcoming Test</h5>
          </div>

          <v-card-text
            v-if="customer_details && customer_details.on_boarding"
            class="px-6"
          >
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('mcat_goal_score') }}
              </p>
              <p class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.mcat_goal_score }}
              </p>
            </div>

            <div v-if="customer_details.on_boarding.mcat_test_date">
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('mcat_test_date') }}
              </p>
              <p class="text-subtitle-1 mb-0">
                {{ formatDate(customer_details.on_boarding.mcat_test_date) }}
              </p>
            </div>

            <div v-if="customer_details.on_boarding.year_of_school">
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('school_year') }}
              </p>
              <p class="text-subtitle-1 mb-0">
                {{ getSchoolYear(customer_details.on_boarding.year_of_school) }}
              </p>
            </div>

            <div v-if="customer_details.on_boarding.institutional_link">
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('school_go_to') }}
              </p>
              <p class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.institutional_link }}
              </p>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4"
            >No data found for Upcoming Test!</v-card-text
          >
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="6">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #495db7; color: white"
          >
            <v-icon class="mr-2" color="white" size="28"
              >mdi-clipboard-pulse-outline</v-icon
            >
            <h5 class="text-h5">Study Analysis</h5>
          </div>

          <v-card-text
            v-if="customer_details && customer_details.on_boarding"
            class="px-6"
          >
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('is_remember_study') }}
              </p>
              <p class="text-subtitle-1 mb-0">
                {{
                  customer_details.on_boarding.is_remember_study ? 'Yes' : 'No'
                }}
              </p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('subject_difficulty_ranking') }}
              </p>
              <div
                v-if="customer_details.on_boarding.subject_difficulty_ranking"
              >
                <div
                  class="d-flex justify-start align-center"
                  style="gap: 10px"
                  v-for="(subject, s) in sortByRanking(
                    customer_details.on_boarding.subject_difficulty_ranking
                  )"
                  :key="s"
                >
                  <p class="text-subtitle-1 mb-0">
                    {{
                      capitalize(
                        subject[0].replace('_', ' ') !== 'psychology'
                          ? subject[0].replace('_', ' ')
                          : 'general chemistry'
                      ) +
                      ' (' +
                      subject[1] +
                      ')'
                    }}
                  </p>
                  <img
                    :src="getEmojiSrc(subject[1])"
                    :alt="`${subject[1]} image`"
                    height="20px"
                  />
                </div>
              </div>
              <p v-else>N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('mcat_daily_study') }}
              </p>
              <div v-if="customer_details.on_boarding.mcat_daily_study">
                <p class="text-subtitle-1 mb-0">
                  {{ customer_details.on_boarding.mcat_daily_study }}
                </p>
              </div>
              <p v-else>N/A</p>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4"
            >No Study Analysis done yet!</v-card-text
          >
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="6">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #f5c187; color: white"
          >
            <v-icon color="white" size="28">mdi-head-question</v-icon>
            <h5 class="text-h5">Study Habits</h5>
          </div>

          <v-card-text
            v-if="customer_details && customer_details.on_boarding"
            class="px-6"
          >
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('is_understand_study_habits') }}
              </p>
              <p
                v-if="
                  customer_details.on_boarding.is_understand_study_habits !==
                  null
                "
                class="text-subtitle-1 mb-0"
              >
                {{
                  customer_details.on_boarding.is_understand_study_habits
                    ? 'Yes'
                    : 'No'
                }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('study_habits_importance') }}
              </p>
              <p
                v-if="customer_details.on_boarding.study_habits_importance"
                class="text-subtitle-1 mb-0"
              >
                {{ customer_details.on_boarding.study_habits_importance }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('study_timing') }}
              </p>
              <p
                v-if="customer_details.on_boarding.study_timing"
                class="text-subtitle-1 mb-0"
              >
                {{ customer_details.on_boarding.study_timing }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('commitment') }}
              </p>
              <p
                v-if="customer_details.on_boarding.commitment"
                class="text-subtitle-1 mb-0"
              >
                {{ customer_details.on_boarding.commitment ? 'Yes' : 'No' }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('study_material') }}
              </p>
              <p
                v-if="
                  customer_details.on_boarding.study_material &&
                  customer_details.on_boarding.study_material.length > 0
                "
                class="text-subtitle-1 mb-0"
              >
                {{ customer_details.on_boarding.study_material.join(',') }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">
                {{ getQuestion('mcat_improvements') }}
              </p>
              <p
                v-if="
                  customer_details.on_boarding.mcat_improvements &&
                  customer_details.on_boarding.mcat_improvements.length > 0
                "
                class="text-subtitle-1 mb-0"
              >
                {{
                  customer_details.on_boarding.mcat_improvements
                    .filter((s_m) => s_m && s_m !== '')
                    .join(',')
                }}
              </p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4"
            >No data found for Study Habits!</v-card-text
          >
        </v-card>
      </v-col>

      <v-col v-if="customer_stats" cols="12" height="100%">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #9e7fd7; color: white"
          >
            <v-icon class="mr-2" color="white" size="28">mdi-chart-bar</v-icon>
            <h5 class="text-h5">User Stats</h5>
          </div>

          <v-card-text class="px-6">
            <div class="d-flex">
              <p class="text-subtitle-1 font-weight-bold mb-0">
                Total Questions :
              </p>
              <p class="text-subtitle-1 mb-0 ml-4">
                {{ customer_stats.total_questions }}
              </p>
            </div>
            <div class="d-flex">
              <p class="text-subtitle-1 font-weight-bold mb-0">
                Right Answers :
              </p>
              <p class="text-subtitle-1 mb-0 ml-4">
                {{ customer_stats.total_right_questions }}
              </p>
            </div>
            <div class="d-flex">
              <p class="text-subtitle-1 font-weight-bold mb-0">
                Wrong Answers :
              </p>
              <p class="text-subtitle-1 mb-0 ml-4">
                {{ customer_stats.total_wrong_questions }}
              </p>
            </div>
          </v-card-text>

          <v-expansion-panels class="px-6">
            <v-expansion-panel
              v-for="(categoryKey, i) in Object.keys(customer_stats.data)"
              :key="i"
            >
              <v-expansion-panel-header class="font-weight-bold">
                {{ capitalizeEachWord(categoryKey) }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex">
                  <p class="text-subtitle-1 mb-0">Total Questions :</p>
                  <p class="text-subtitle-1 mb-0 ml-4">
                    {{ customer_stats.data[categoryKey].total }}
                  </p>
                </div>
                <div class="d-flex">
                  <p class="text-subtitle-1 mb-0">Right Questions :</p>
                  <p class="text-subtitle-1 mb-0 ml-4">
                    {{ customer_stats.data[categoryKey].right }}
                  </p>
                </div>
                <div class="d-flex">
                  <p class="text-subtitle-1 mb-0">Wrong Questions :</p>
                  <p class="text-subtitle-1 mb-0 ml-4">
                    {{ customer_stats.data[categoryKey].wrong }}
                  </p>
                </div>
                <div class="d-flex">
                  <p class="text-subtitle-1 mb-0">Percentage :</p>
                  <p class="text-subtitle-1 mb-0 ml-4">
                    {{ customer_stats.data[categoryKey].percentage }} %
                  </p>
                </div>

                <v-expansion-panels class="pt-4">
                  <v-expansion-panel
                    v-for="(subCategoryKey, i) in Object.keys(
                      customer_stats.data[categoryKey].sub_categories
                    )"
                    :key="i"
                  >
                    <v-expansion-panel-header class="font-weight-bold">
                      {{ capitalizeEachWord(subCategoryKey) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="d-flex">
                        <p class="text-subtitle-1 mb-0">Total Questions :</p>
                        <p class="text-subtitle-1 mb-0 ml-4">
                          {{
                            customer_stats.data[categoryKey].sub_categories[
                              subCategoryKey
                            ].total
                          }}
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="text-subtitle-1 mb-0">Right Questions :</p>
                        <p class="text-subtitle-1 mb-0 ml-4">
                          {{
                            customer_stats.data[categoryKey].sub_categories[
                              subCategoryKey
                            ].right
                          }}
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="text-subtitle-1 mb-0">Wrong Questions :</p>
                        <p class="text-subtitle-1 mb-0 ml-4">
                          {{
                            customer_stats.data[categoryKey].sub_categories[
                              subCategoryKey
                            ].wrong
                          }}
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="text-subtitle-1 mb-0">Percentage :</p>
                        <p class="text-subtitle-1 mb-0 ml-4">
                          {{
                            customer_stats.data[categoryKey].sub_categories[
                              subCategoryKey
                            ].percentage
                          }}
                          %
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-card-text v-if="customer_stats" class="px-6">
            <h3 class="mb-2">Games Played</h3>

            <div
              class="d-flex flex"
              v-for="(key, q) in Object.keys(customer_stats.games_played)"
              :key="q"
            >
              <div
                class="d-flex"
                v-if="key !== 'Question of the day Mode' && key !== ''"
              >
                <p class="text-subtitle-1 mb-0">{{ key }} :</p>
                <p class="text-subtitle-1 mb-0 ml-4">
                  {{ customer_stats.games_played[key] }}
                </p>
              </div>
            </div>
          </v-card-text>

          <v-card-text class="px-6">
            <div class="d-flex align-center">
              <h3 class="mb-2">Prediction Score Details</h3>

              <v-btn
                color="primary"
                outlined
                class="ml-auto"
                @click="
                  predictionScoreError ? (predictionScoreDialog = true) : null
                "
              >
                {{
                  predictionScoreData
                    ? `Percentile Rank: ${predictionScoreData.percentile_rank}`
                    : 'No Prediction Score'
                }}
              </v-btn>
            </div>

            <div v-if="predictionScoreData">
              <div class="d-flex">
                <p class="text-subtitle-1 mb-0">Total Predictive Score:</p>
                <p class="text-subtitle-1 mb-0 ml-4">
                  {{ predictionScoreData.prediction_score }}
                </p>
              </div>
              <h3 class="mt-5">Score in each Section</h3>
              <div class="d-flex">
                <p class="text-subtitle-1 mb-0">
                  Biological and Biochemical Foundations of Living Systems:
                </p>
                <p class="text-subtitle-1 mb-0 ml-4">
                  {{ predictionScoreData.bb.prediction }}
                </p>
              </div>
              <div class="d-flex">
                <p class="text-subtitle-1 mb-0">
                  Chemical and Physical Foundations of Biological Systems:
                </p>
                <p class="text-subtitle-1 mb-0 ml-4">
                  {{ predictionScoreData.cp.prediction }}
                </p>
              </div>
              <div class="d-flex">
                <p class="text-subtitle-1 mb-0">
                  Psychological, Social and Biological Foundations of Behavior:
                </p>
                <p class="text-subtitle-1 mb-0 ml-4">
                  {{ predictionScoreData.ps.prediction }}
                </p>
              </div>
              <div class="d-flex">
                <p class="text-subtitle-1 mb-0">
                  Critical Analysis and Reasoning Skills:
                </p>
                <p class="text-subtitle-1 mb-0 ml-4">
                  {{ predictionScoreData.cars.prediction }}
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" height="100%">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #7fd7b6; color: white"
          >
            <v-icon class="mr-2" color="white" size="28"
              >mdi-clipboard-edit-outline</v-icon
            >
            <h5 class="text-h5">Test Survey</h5>
          </div>

          <v-card-text v-if="customer_metrics" class="px-6">
            <div v-for="(question, q) in customer_metrics.questions" :key="q">
              <div>
                <p class="text-subtitle-1 font-weight-bold mb-0">
                  {{ question.question }}
                </p>
                <p class="text-subtitle-1 mb-0">{{ question.answer }}</p>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4">
            No data found for Impact Metrics!</v-card-text
          >
        </v-card>
      </v-col>

      <v-col v-if="display_cheating_stats" cols="12" height="100%">
        <v-card height="100%">
          <div
            class="d-flex align-center pa-4"
            style="background-color: #ee8782; color: white"
          >
            <v-icon class="mr-2" color="white" size="28"
              >mdi-fullscreen-exit</v-icon
            >
            <h5 class="text-h5">Screenshots</h5>
          </div>

          <v-card-text v-if="customer_prohibitions_stats" class="px-6">
            <div
              v-for="(screenshot, q) in customer_prohibitions_stats.screenshot"
              :key="q"
            >
              <div>
                <p
                  class="text-subtitle-1 font-weight-bold mb-0 float-start me-5"
                >
                  {{ q + 1 }}
                </p>
                <p class="text-subtitle-1 mb-5">
                  {{ screenshot.screenshot_taken }}
                </p>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4">
            No data found for Screenshots</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="predictionScoreDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Prediction Score</v-card-title>
        <v-card-subtitle class="pt-1 pb-2">
          for prediction score remaining questions are:
        </v-card-subtitle>
        <v-card-text>
          <p>{{ predictionScoreError }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="predictionScoreDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoadingDialog v-model="loading" message="Loading..." />
  </div>
</template>

<script>
import { CustomerService } from '../../services/customer-service';
import moment from 'moment';
import LoadingDialog from '../../components/LoadingDialog.vue';

export default {
  name: 'CustomersDetail',
  components: { LoadingDialog },
  data() {
    return {
      loading: false,
      customer_details: null,
      customer_metrics: null,
      customer_stats: null,
      customer_prohibitions_stats: null,
      customer_service: new CustomerService(),
      display_cheating_stats: false,
      predictionScoreData: null,
      predictionScoreError: null,
      predictionScoreDialog: false
    };
  },
  mounted() {
    this.loadData();

    this.getPredictionScore();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        let _id = this.$route.query.id;
        let cheating_stats = this.$route.query.cheating_stats;
        this.customer_details = await this.customer_service.fetchCustomerStats(
          _id
        );
        this.customer_metrics =
          await this.customer_service.fetchCustomerMetrics(_id);
        this.customer_stats = await this.customer_service.customerStats(_id);
        if (cheating_stats) {
          this.display_cheating_stats = true;
          this.customer_prohibitions_stats =
            await this.customer_service.getProhibitionsStats(_id);
          // Date Conversion
          this.customer_prohibitions_stats.screenshot =
            this.customer_prohibitions_stats.screenshot.map((screenshot) => {
              // date month year
              let today = new Date(screenshot.screenshot_taken);
              let dd = String(today.getDate()).padStart(2, '0');
              let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = today.getFullYear();
              let hour = today.getHours();
              let min = today.getMinutes();
              let sec = today.getSeconds();
              screenshot.screenshot_taken =
                dd +
                '-' +
                mm +
                '-' +
                yyyy +
                ' -- ' +
                hour +
                ':' +
                min +
                ':' +
                sec;
              return screenshot;
            });
        }
        if (
          this.customer_details &&
          this.customer_details.on_boarding &&
          this.customer_details.on_boarding.study_material?.length > 0
        ) {
          this.customer_details.on_boarding.study_material =
            this.customer_details.on_boarding.study_material.filter(
              (m) => m && m !== ''
            );
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    async getPredictionScore() {
      try {
        if (!this.$route.query.id) return;

        const data = await this.customer_service
          .getPredictionScore(this.$route.query.id)
          .catch((e) => {
            this.predictionScoreError = e?.data?.error;
          });

        if (data) {
          this.predictionScoreData = data;
        }
      } catch (e) {
        console.log(e);
      }
    },

    formatDate(date) {
      return moment(date).format('dddd, MMMM Do YYYY');
    },
    capitalize(word) {
      return word
        .toLowerCase()
        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
    },

    capitalizeEachWord(str) {
      str = str.replaceAll('_', ' ');

      let splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    },

    sortByRanking(obj) {
      const sortable = Object.entries(obj);
      return sortable.sort((a, b) => a[1] - b[1]);
    },
    getEmojiSrc(item) {
      switch (item) {
        case 1:
          return require('../../assets/emoji/emoji_one.png');
        case 2:
          return require('../../assets/emoji/emoji_two.png');
        case 3:
          return require('../../assets/emoji/emoji_three.png');
        case 4:
          return require('../../assets/emoji/emoji_four.png');
        case 5:
          return require('../../assets/emoji/emoji_five.png');
        case 6:
          return require('../../assets/emoji/emoji_six.png');
        case 7:
          return require('../../assets/emoji/emoji_seven.png');
        case 8:
          return require('../../assets/emoji/emoji_eight.png');
        case 9:
          return require('../../assets/emoji/emoji_nine.png');
        case 10:
          return require('../../assets/emoji/emoji_ten.png');
        default:
          return require('../../assets/emoji/emoji_one.png');
      }
    },
    getSchoolYear(year) {
      switch (year) {
        case 1:
          return '1st Year';
        case 2:
          return '2nd Year';
        case 3:
          return '3rd Year';
        case 4:
          return '4th Year';
        case 5:
          return '5th Year';
        case 6:
          return 'High School';
      }
    },
    getQuestion(item) {
      switch (item) {
        case 'real_name':
          return 'Real Name';
        case 'user_name':
          return 'Username';
        case 'mcat_goal_score':
          return 'What is your MCAT score goal?';
        case 'mcat_test_date':
          return 'When you are going to take the MCAT?';
        case 'is_mcat_taken_before':
          return 'Have you taken the MCAT before?';
        case 'mcat_previous_test_date':
          return 'When was your last MCAT?';
        case 'mcat_previous_test_score':
          return 'What was your MCAT score?';
        case 'last_practice_score':
          return "If you haven't taken the MCAT, what was your last practice score?";
        case 'gender':
          return 'What is your gender?';
        case 'is_remember_study':
          return 'Do you want to remember more from what you study?';
        case 'subject_difficulty_ranking':
          return 'What was your most difficult subject in MCAT? Rank them in the order of your most difficult to least difficult?';
        case 'mcat_daily_study':
          return 'How much will you study with KOTC for the MCAT daily?';
        case 'age':
          return 'What is your age?';
        case 'is_understand_study_habits':
          return 'Do you want to understand your study habits?';
        case 'study_habits_importance':
          return 'How well do you want to understand your study habits?';
        case 'commitment':
          return 'Is contract accepted?';
        case 'study_timing':
          return 'When do you like to study most?';
        case 'study_material':
          return 'What study materials do you use?';
        case 'mcat_improvements':
          return 'Which of these would you like to improve? Select all and apply?';
        case 'school_year':
          return 'What year are you in school?';
        case 'school_go_to':
          return 'What school do you go to?';
        case 'last_step':
          return 'last_step';
        default:
          return null;
      }
    }
  }
};
</script>
<style scoped></style>
