import { render, staticRenderFns } from "./FlashcardsView.vue?vue&type=template&id=bf1f890e&scoped=true&"
import script from "./FlashcardsView.vue?vue&type=script&lang=js&"
export * from "./FlashcardsView.vue?vue&type=script&lang=js&"
import style0 from "./FlashcardsView.vue?vue&type=style&index=0&id=bf1f890e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf1f890e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VCard,VCardTitle,VChip,VDialog,VFileInput,VForm,VProgressCircular})
