var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleForm',{attrs:{"onSubmit":_vm.submit},on:{"done":function($event){return _vm.$router.back()}}},[_c('p',{staticClass:"span-2 form__title"},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Update Category' : 'Add a new Category')+" ")]),(_vm.single_icon || _vm.category.single_icon)?_c('v-img',{staticClass:"span-2 mb-6",attrs:{"src":_vm.singelImgObjectUrl() ? _vm.singelImgObjectUrl() : _vm.category.single_icon,"max-height":"200px","contain":""},on:{"load":function($event){_vm.singleImgLoading = false}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"w-full h-full d-flex justify-center align-center"},[(_vm.singleImgLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}}):_vm._e()],1)]},proxy:true}],null,false,1698100417)}):_vm._e(),_c('v-file-input',{staticClass:"span-2",attrs:{"accept":"image/png, image/jpeg, image/bmp","outlined":"","dense":"","label":_vm.category.single_icon
        ? _vm.category.single_icon
            .split('%2F')
            .pop()
            .split('?')[0]
            .replace(/%20/g, ' ')
        : 'Select Icon',"prepend-icon":"mdi-image","placeholder":"Select an icon","rules":!_vm.isEdit &&
      (_vm.category.isMultiplayer ||
        (_vm.restrictions &&
          (_vm.restrictions.includes(6) || _vm.restrictions.includes(9))))
        ? [_vm.required('You must select an icon')]
        : []},model:{value:(_vm.single_icon),callback:function ($$v) {_vm.single_icon=$$v},expression:"single_icon"}}),_c('v-card-text',{staticClass:"span-1"},[_c('p',[_vm._v("Multiplayer:")])]),_c('v-checkbox',{staticClass:"span-1",attrs:{"label":"Yes","color":"primary","hide-details":""},model:{value:(_vm.category.isMultiplayer),callback:function ($$v) {_vm.$set(_vm.category, "isMultiplayer", $$v)},expression:"category.isMultiplayer"}}),_c('div',{staticClass:"span-2"},[_c('v-select',{attrs:{"items":_vm.restrictionGameModes,"label":"Select game mode restrictions","multiple":"","small-chips":"","outlined":"","disabled":!_vm.restrictionGameModes},model:{value:(_vm.restrictions),callback:function ($$v) {_vm.restrictions=$$v},expression:"restrictions"}})],1),(_vm.category.isMultiplayer)?_c('div',{staticClass:"span-2"},[_c('p',[_vm._v("Multiplayer Icons:")]),_c('v-img',{staticClass:"span-1 mb-6",attrs:{"src":_vm.multiple_icons[0]
          ? _vm.createImgObjectUrl(_vm.multiple_icons[0])
          : _vm.category.multiple_icons[0],"max-height":"200px","contain":""},on:{"load":function($event){_vm.multipleImg1Loading = false}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"w-full h-full d-flex justify-center align-center"},[(_vm.multipleImg1Loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}}):_vm._e()],1)]},proxy:true}],null,false,1539647834)}),_c('v-file-input',{attrs:{"accept":"image/png, image/jpeg, image/bmp","outlined":"","dense":"","label":"Locked Icon","prepend-icon":"mdi-image","placeholder":"Select Locked Icon","rules":_vm.isEdit ? [] : [_vm.required('You must select an icon')]},model:{value:(_vm.multiple_icons[0]),callback:function ($$v) {_vm.$set(_vm.multiple_icons, 0, $$v)},expression:"multiple_icons[0]"}}),_c('v-img',{staticClass:"span-1 mb-6",attrs:{"src":_vm.multiple_icons[1]
          ? _vm.createImgObjectUrl(_vm.multiple_icons[1])
          : _vm.category.multiple_icons[1],"max-height":"200px","contain":""},on:{"load":function($event){_vm.multipleImg2Loading = false}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"w-full h-full d-flex justify-center align-center"},[(_vm.multipleImg2Loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}}):_vm._e()],1)]},proxy:true}],null,false,35950041)}),_c('v-file-input',{attrs:{"accept":"image/png, image/jpeg, image/bmp","outlined":"","dense":"","label":"Selected Icon","prepend-icon":"mdi-image","placeholder":"Select selected icon","rules":_vm.isEdit ? [] : [_vm.required('You must select an icon')]},model:{value:(_vm.multiple_icons[1]),callback:function ($$v) {_vm.$set(_vm.multiple_icons, 1, $$v)},expression:"multiple_icons[1]"}}),_c('v-img',{staticClass:"span-1 mb-6",attrs:{"src":_vm.multiple_icons[2]
          ? _vm.createImgObjectUrl(_vm.multiple_icons[2])
          : _vm.category.multiple_icons[2],"max-height":"200px","contain":""},on:{"load":function($event){_vm.multipleImg3Loading = false}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"w-full h-full d-flex justify-center align-center"},[(_vm.multipleImg3Loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}}):_vm._e()],1)]},proxy:true}],null,false,3484402264)}),_c('v-file-input',{attrs:{"accept":"image/png, image/jpeg, image/bmp","outlined":"","dense":"","label":"Unlocked Icon","prepend-icon":"mdi-image","placeholder":"Select unlocked icon","rules":_vm.isEdit ? [] : [_vm.required('You must select an icon')]},model:{value:(_vm.multiple_icons[2]),callback:function ($$v) {_vm.$set(_vm.multiple_icons, 2, $$v)},expression:"multiple_icons[2]"}})],1):_vm._e(),_c('v-text-field',{staticClass:"span-2",attrs:{"dense":"","label":"Category Name","rules":[_vm.required('You must write a name')],"outlined":""},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}}),_c('sub-category-picker',{staticStyle:{"margin-top":"-25px","margin-left":"25px"},model:{value:(_vm.category.subCategories),callback:function ($$v) {_vm.$set(_vm.category, "subCategories", $$v)},expression:"category.subCategories"}}),_c('v-card-text',{staticClass:"span-1"},[_c('p',[_vm._v("Category Status:")])]),_c('v-checkbox',{staticClass:"span-1",attrs:{"label":"Active","color":"primary","hide-details":""},model:{value:(_vm.category.status),callback:function ($$v) {_vm.$set(_vm.category, "status", $$v)},expression:"category.status"}}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }