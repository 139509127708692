import axios from 'axios';

export class FlashcardsService {
  async fetchAll() {
    return (await axios.get('/flashcards/admin/all')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/flashcards/' + id)).data;
  }

  async create(flashcard) {
    return (await axios.post('/flashcards', flashcard)).data;
  }

  async delete(flashcard) {
    return (await axios.delete('/flashcards/' + flashcard.id)).data;
  }

  async update(flashcard) {
    return (await axios.patch('/flashcards', flashcard)).data;
  }
}
