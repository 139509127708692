import {getUser} from "@/utils/local";
import Promo_refer from "@/views/promo_refer/promo_refer";

export const promoReferRoutes = [
    getUser()?.scopes.includes('promoRefer:view') ?
        {
            name: 'PromoRefers',
            path: '/promo-refers',
            component: Promo_refer
        } : null,
];

export const promoReferRouter = promoReferRoutes.filter(function (x) {
    return x !== null;
})

