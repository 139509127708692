<template>
  <div>
    <div v-if="coins_average" class="coins_average">
      <stat-card
        class="gameCard"
        title="Average Curve Coins"
        :value="Math.round(coins_average.average_total_curve_coins)"
        color="green"
      />
    </div>
    <data-table
      :loader="loadData"
      :headers="headers"
      :defaultFooter="false"
      title="Coins Leaderboard"
      :allow-add="false"
      :showSearch="false"
      :key="dataTableKey"
      @custom-sort="customSort($event)"
    >
      <template v-slot:custom-footer>
        <div class="v-data-footer d-flex justify-center align-center">
          <v-pagination
            :value="currentPage"
            class="my-2"
            @input="changePage"
            total-visible="10"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="totalPages"
          />
          <v-text-field
            v-model="pageInput"
            type="number"
            min="1"
            :max="totalPages"
            outlined
            dense
            hide-details
            style="max-width: 80px"
            placeholder="Page #"
            v-on:keydown.enter="changePage(parseInt(pageInput), true)"
          />
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import { CoinsLeaderboardService } from '../../services/coins-leaderboard-service';
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import StatCard from '@/components/dashboard/StatCard';

export default {
  name: 'CoinsLeaderboard',
  components: { DataTable, StatCard },

  async mounted() {
    this.coins_average = await this.service.fetchCoinsAverage();
  },

  data: () => ({
    dataTableKey: 0,
    items: [],
    coins_average: null,
    loading: false,
    service: new CoinsLeaderboardService(),

    // sorting
    selectedSorting: 'coins',

    pageInput: '',
    totalPages: 0,
    currentPage: 1,
    recordsPerPage: 10,

    headers: [
      {
        text: 'Rank',
        value: 'rank',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false,
        width: 200
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false,
        width: 300
      },
      {
        text: 'Total coins',
        value: 'total_curve_coins',
        sortable: true
      },
      {
        text: 'Purchased avatar count',
        value: 'total_avatar_counts',
        sortable: true
      },
      {
        text: 'Purchased spin the wheel',
        value: 'paid_spin_wheel_count',
        sortable: true
      }
    ]
  }),

  watch: {
    async $route() {
      // Reload Data when route hash change for pagination
      await this.loadData(true);
      this.refreshDataTable();
    }
  },

  methods: {
    getUser,
    refreshDataTable() {
      document.getElementById('searchDataReload').click();
    },
    changePage(value, byPageInput = false) {
      // Update route hash for pagination
      if (value > 0 && value <= this.totalPages) {
        this.$router.replace({ path: '/coins-leaderboard', hash: '#' + value });
      }
      if (byPageInput === false) {
        this.pageInput = '';
      }
    },
    async loadData() {
      this.loading = true;

      // Fetch route hash for pagination
      this.fetchRouteHash();
      const items = await this.service.fetchAll(
        this.currentPage,
        10,
        this.selectedSorting
      );
      this.totalPages = items.total_pages;
      return items.data.map((item, index) => {
        return {
          rank:
            this.currentPage === 1
              ? index + 1
              : (this.currentPage - 1) * 10 + (index + 1),
          ...item
        };
      });
    },
    fetchRouteHash() {
      if (this.$route.hash === '') {
        this.currentPage = 1;
      } else {
        let hash = parseInt(this.$route.hash.replace('#', ''));
        this.currentPage = hash;
        if (
          hash <= 0 ||
          (this.currentPage > this.totalPages && this.totalPages > 0)
        ) {
          this.$router.replace({ path: '/coins-leaderboard', hash: '#1' });
        }
      }
    },
    customSort(event) {
      if (!event && !event.sortBy.length) return;

      if (event.sortBy[0] === 'total_curve_coins') {
        this.selectedSorting = 'coins';
        this.dataTableKey++;
      } else if (event.sortBy[0] === 'total_avatar_counts') {
        this.selectedSorting = 'avatar';
        this.$router.replace({ hash: '#1' });
        this.dataTableKey++;
      } else if (event.sortBy[0] === 'paid_spin_wheel_count') {
        this.selectedSorting = 'spin_wheel_count';
        this.dataTableKey++;
      }
    }
  }
};
</script>

<style scoped>
.coins_average {
  width: 12rem;
  margin-bottom: 1rem;
}
</style>
