import View from './View';
import Form from './Form';
import { getUser } from '@/utils/local';

const passagePromptsRoutes = [
  getUser()?.scopes.includes('passage-prompts:view')
    ? {
        name: 'PassagePrompts',
        path: '/passage-prompts',
        component: View
      }
    : null,
  getUser()?.scopes.includes('passage-prompts:new') ||
  getUser()?.scopes.includes('passage-prompts:edit')
    ? {
        name: 'NewPassagePrompt',
        path: '/passage-prompt',
        component: Form
      }
    : null
];

export const passagePromptsRouter = passagePromptsRoutes.filter(function (x) {
  return x !== null;
});
