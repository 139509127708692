<template>
  <div v-if="payload && payload.passage" class="preview">
    <div class="preview__details preview__form">
      <span class="preview__details__title">
        {{ title }}
      </span>
      <span
        v-for="passage in payload.passage"
        :key="passage"
        class="preview__details__content"
      >
        {{ passage }}
      </span>
      <v-btn
        color="primary"
        class="preview__details__button"
        min-width="300px"
        elevation="0"
        @click="proceedQuestion"
        >Proceed</v-btn
      >
    </div>

    <v-dialog
      :width="$vuetify.breakpoint.mdAndUp ? '800px' : '90%'"
      v-model="showPassageQuestionDialog"
    >
      <PassageQuestionFormComponent
        is-modal
        @close="closeModal"
        :initial-data="passageQuestion"
      />
    </v-dialog>

    <loading-dialog v-model="loading" message="Generating Description" />
  </div>
</template>

<script>
import PassageQuestionFormComponent from '@/components/questions/PassageQuestionForm.vue';
import LoadingDialog from '@/components/LoadingDialog.vue';

export default {
  name: 'PassageQuestionsPreview',

  components: { PassageQuestionFormComponent, LoadingDialog },

  props: {
    payload: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    title: '',
    passage: '',
    config: null,
    showPassageQuestionDialog: false,
    passageQuestion: null,
    loading: false
  }),

  watch: {
    payload() {
      this.setData();
    }
  },

  mounted() {
    this.setData();
  },

  methods: {
    closeModal() {
      this.showPassageQuestionDialog = false;
    },

    setData() {
      this.config = this.query;
      this.title = this.config?.topic;

      if (this.payload && this.payload.passage) {
        this.passage = this.payload.passage[0];
      }
    },

    async proceedQuestion() {
      this.passageQuestion = {
        heading: this.title,
        passage: this.payload.passage,
        questions: this.payload.questions
      };

      this.showPassageQuestionDialog = true;
    }
  }
};
</script>

<style scoped lang="scss">
.preview {
  display: grid;
  max-width: 100%;
  margin-top: 20px;

  &__form {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    width: 100%;
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
    margin-top: 20px;

    &__title {
      font-weight: normal !important;
      font-size: 22px;
      padding-bottom: 10px;
      text-align: center !important;
      font-family: google-sans, sans-serif;
    }

    &__content {
      text-align: justify;
    }

    &__button {
      margin: 20px auto 0;
    }
  }
}
</style>
