var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"table",attrs:{"title":"Coupons","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('web-aps-coupons:new') : false,"loader":_vm.loadCoupons,"headers":_vm.headers,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('web-aps-coupons:delete')
          ? _vm.deleteCoupon
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('web-aps-coupons:edit')
          ? _vm.editCoupon
          : null
        : null,"show-search":false},on:{"add-new":_vm.showNewDialog},scopedSlots:_vm._u([{key:"percent_off",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.percent_off && item.percent_off + '%')+" ")]}},{key:"primary-action",fn:function(){return [_c('v-checkbox',{staticClass:"mt-1 mr-4",attrs:{"color":"primary","label":"Show Promo Codes Popup"},on:{"change":_vm.updateAppStatus},model:{value:(_vm.appStatusData.enablePromoCode),callback:function ($$v) {_vm.$set(_vm.appStatusData, "enablePromoCode", $$v)},expression:"appStatusData.enablePromoCode"}})]},proxy:true}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticStyle:{"padding":"40px"}},[_c('v-form',{ref:"coupon"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Coupon Name","rules":[_vm.required()]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(!_vm.isEdit)?_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Percentage Off (%)","rules":[_vm.requiredPercentage()],"type":"number"},model:{value:(_vm.percent_off),callback:function ($$v) {_vm.percent_off=$$v},expression:"percent_off"}}):_vm._e(),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.saveCoupon}},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Update' : 'Create')+" Coupon ")])],1)],1)],1),_c('loading-dialog',{attrs:{"message":_vm.loadingMessage},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('error-dialog',{attrs:{"error":_vm.errorValue},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }