<template>
  <v-card>
    <v-tabs v-model="tab" class="mb-4" color="primary">
      <v-tab v-for="(type, index) in types" :key="index">
        {{ type }}
      </v-tab>
    </v-tabs>
    <v-form v-model="valid">
      <data-table
        :key="dataTableKey"
        :allow-add="false"
        :headers="headers"
        :loader="loadData"
        title="In App Products"
        @done="$router.back()"
      >
        <template #primary-action>
          <v-btn
            :disabled="!getUser().scopes.includes('iaps:edit')"
            color="primary"
            elevation="0"
            @click="save"
          >
            <v-icon>mdi-disk</v-icon>
            Save
          </v-btn>
          <v-btn
            v-if="tab === 1"
            :disabled="!getUser().scopes.includes('iaps:edit')"
            class="ml-2"
            color="primary"
            elevation="0"
            @click="mobileAppBannerDialoge = true"
            >Mobile App Banner
            <v-icon
              :color="
                mobileAppBanner &&
                mobileAppBanner.is_active === true &&
                mobileAppBanner.sale_period > mobileAppBanner.currentDate
                  ? 'yellow'
                  : 'red'
              "
              class="ml-2"
              x-small
            >
              mdi-circle
            </v-icon>
          </v-btn>
        </template>
        <template #sku="{ item }">
          {{ item.product.sku }}
        </template>
        <template #title="{ item }">
          {{ item.product.listings['en-US'].title }}
        </template>
        <template #description="{ item }">
          {{ item.product.listings['en-US'].description }}
        </template>
        <template #price="{ item }">
          <v-text-field
            v-model="item.price"
            :rules="[(v) => (!!v && +v > 0) || 'Price must be provided']"
            dense
            outlined
            suffix="USD"
            type="number"
          />
        </template>
        <template v-if="tab === 1" #discount="{ item }">
          <v-text-field
            v-model="item.discountPrice"
            :rules="[
              (v) =>
                checkLess(v, item.price) || 'Discount must be less than Price'
            ]"
            dense
            outlined
            suffix="USD"
            type="number"
          />
        </template>

        <template v-if="tab === 1" #discountMode="{ item }">
          <v-checkbox v-model="item.discountMode" hide-details />
        </template>

        <template v-if="tab === 0" #discountPriceAndroid="{ item }">
          <v-text-field
            v-model="item.discountPriceAndroid"
            :rules="[
              (v) =>
                checkLess(v, item.price) || 'Discount must be less than Price'
            ]"
            dense
            outlined
            suffix="USD"
            type="number"
          />
        </template>

        <template v-if="tab === 0" #discountModeAndroid="{ item }">
          <v-checkbox v-model="item.discountModeAndroid" hide-details />
        </template>

        <template #mobile="{ item }" v-if="tab === 2"
          >{{ item.value }}%</template
        >
        <template #mobile="{ item }" v-else-if="tab === 3">{{
          item.value
        }}</template>

        <template #hide="{ item }">
          <v-checkbox
            v-model="item.isActive"
            color="primary"
            label="Active"
          ></v-checkbox>
        </template>

        <template #action="{ item }">
          <v-icon
            v-if="
              !(item.sku === 'lifetime_v1') &&
              !(item.sku === 'lifetime_new') &&
              getUser().scopes.includes('iaps-offers:view')
            "
            color="green"
            small
            @click="edit(item)"
            >mdi-pencil
          </v-icon>
        </template>
      </data-table>

      <v-dialog v-model="mobileAppBannerDialoge" width="500">
        <v-card class="py-2">
          <v-card-title class="mb-4">Mobile App Banner</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="mobileAppBanner.name"
              :rules="[required()]"
              dense
              label="Name"
              outlined
            />
            <v-text-field
              v-model="mobileAppBanner.description"
              :rules="[required()]"
              dense
              label="Description"
              outlined
            />
            <v-text-field
              v-model="mobileAppBanner.percentage_off"
              :rules="[required()]"
              dense
              label="Percentage Off"
              outlined
            />

            <DateTimePicker
              v-model="mobileAppBanner.sale_period"
              :rules="[required()]"
              label="Sale Period"
              timeFormat="hh:mm:ss a"
            >
            </DateTimePicker>

            <v-checkbox
              v-model="mobileAppBanner.is_active"
              :label="'Active'"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="primary" @click="submitMobileBanner()" elevation="0"
              >Save</v-btn
            >
            <v-btn color="primary" @click="closeMobileBannerModal" outlined
              >Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <loading-dialog v-model="editLoading" message="Updating Products" />
      <loading-dialog v-model="loading" message="Please wait..." />
    </v-form>
  </v-card>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { IapService } from '@/services/iap-service';
import { getUser } from '@/utils/local';
import DateTimePicker from '@/components/settings/DateTimePicker';
import { required } from '@/utils/validators';
import { androidPlans, iosPlans } from '@/views/offers/plans_data/plans';

const { format, parseISO } = require('date-fns');

const commonHeaders = [
  {
    text: 'SKU',
    value: 'sku',
    sortable: false
  },
  {
    text: 'Title',
    value: 'title',
    sortable: false
  },
  {
    text: 'Description',
    value: 'description',
    sortable: false
  },
  {
    text: 'Price',
    value: 'price'
  }
];

export default {
  components: { LoadingDialog, DataTable, DateTimePicker },

  data: () => ({
    editLoading: false,
    editDialog: false,

    dataTableKey: null,

    mobileAppBannerDialoge: false,
    mobileAppBanner: {},

    tab: null,
    types: ['Android', 'IOS', 'Spin the Wheel(IOS)', 'Spin the Wheel(Android)'],

    valid: false,
    loading: false,
    service: new IapService(),

    items: [],
    headers: [],
    spinTheWheelData: {}
  }),

  mounted() {
    this.$axios
      .get('/sales')
      .then((response) => {
        this.setBannersDetails(response);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  watch: {
    tab(value) {
      this.dataTableKey += 1;
      if (value === 0) {
        this.headers = [...commonHeaders];
        this.headers.push(
          {
            text: 'Discounted Price',
            value: 'discountPriceAndroid'
          },
          {
            width: 100,
            text: 'Discount Active',
            value: 'discountModeAndroid'
          },
          {
            text: 'Action',
            value: 'action',
            sortable: false
          }
        );
      } else if (value === 1) {
        this.headers = [...commonHeaders];
        this.headers.push(
          {
            text: 'Discounted Price',
            value: 'discount'
          },
          {
            width: 100,
            text: 'Discount Active',
            value: 'discountMode'
          },
          {
            text: 'Action',
            value: 'action',
            sortable: false
          }
        );
      } else if (value === 2) {
        this.headers = [
          {
            text: 'Percentage',
            value: 'mobile',
            sortable: false
          },
          {
            text: 'Action',
            value: 'hide',
            sortable: false
          }
        ];
      } else if (value === 3) {
        this.headers = [
          {
            text: 'Days',
            value: 'mobile',
            sortable: false
          },
          {
            text: 'Action',
            value: 'hide',
            sortable: false
          }
        ];
      }
    }
  },

  methods: {
    required,
    getUser,
    closeMobileBannerModal() {
      this.$axios
        .get('/sales')
        .then((response) => {
          this.setBannersDetails(response);
        })
        .catch((error) => {
          console.error(error);
        });

      this.mobileAppBannerDialoge = false;
    },
    formateDate(inputDate, outputFormat) {
      const dateObj1 = parseISO(inputDate);
      const formattedDate = format(dateObj1, outputFormat);
      return formattedDate;
    },
    setBannersDetails(response) {
      const mobileBannerIndex = response.data.sales.findIndex(
        (sale) => sale.platform === 0
      );

      if (mobileBannerIndex !== -1) {
        const mobileBanner = response.data.sales[mobileBannerIndex];
        this.mobileAppBanner.id = mobileBanner.id;
        this.mobileAppBanner.name = mobileBanner.name;
        this.mobileAppBanner.description = mobileBanner.description;
        this.mobileAppBanner.percentage_off = mobileBanner.percentage_off;
        this.mobileAppBanner.is_active = mobileBanner.is_active;
        this.mobileAppBanner.createdAt = mobileBanner.createdAt;
        this.mobileAppBanner.sale_period = this.formateDate(
          mobileBanner.sale_period,
          'yyyy-MM-dd hh:mm:ss a'
        );

        this.mobileAppBanner.currentDate = new Date().toISOString();
        this.mobileAppBanner.currentDate = this.formateDate(
          this.mobileAppBanner.currentDate,
          'yyyy-MM-dd hh:mm:ss a'
        );

        this.dataTableKey += 1;
      }
    },
    async submitMobileBanner() {
      this.loading = true;
      const date = new Date(this.mobileAppBanner.sale_period);
      const clonedBanner = {
        ...this.mobileAppBanner,
        sale_period: date.toISOString(),
        platform: 0
      };

      // const itemsExceptLifeTime = this.items.filter((item) => item.product.sku !== 'lifetime_new' && item.product.listings['en-US'].title !== 'Lifetime');

      this.items = this.items.map((item) => {
        return {
          ...item,
          discountMode:
            item.product.sku !== 'lifetime_new' &&
            item.product.listings['en-US'].title !== 'Lifetime'
              ? clonedBanner.is_active
              : item.discountMode,
          discountPrice:
            item.product.sku !== 'lifetime_new' &&
            item.product.listings['en-US'].title !== 'Lifetime'
              ? item.price - (item.price * clonedBanner.percentage_off) / 100
              : item.discountPrice
        };
      });

      // console.log('lifetime: ', this.items.filter((item) => item.product.sku === 'lifetime_new' && item.product.listings['en-US'].title === 'Lifetime' ));

      try {
        await this.$axios.patch('/sales', clonedBanner);

        this.$toast.success(
          'Sales Banner and Discount except Lifetime updated successfully!',
          {
            position: 'bottom-left',
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: 'button',
            icon: true,
            rtl: false
          }
        );

        this.dataTableKey += 1;
      } catch (error) {
        this.toast('Error occurred while updating Sales Banner');
      }

      // let shouldReturn = true;
      // this.mobileAppBannerDialoge = false;
      // this.loading = false;
      // if (shouldReturn) return;

      console.log('this.items after sales patches: ', this.items);

      try {
        for (const item of this.items) {
          if (!item.offers) {
            item.offers = [];
          }

          await this.service.update(item);
        }

        this.closeMobileBannerModal();

        this.$toast.success('Products updated successfully', {
          position: 'bottom-left',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
          rtl: false
        });

        this.dataTableKey += 1;
        document.getElementById('refresh').click();
      } catch (error) {
        this.toast('Error occurred while updating products');
      }

      this.loading = false;

      this.$axios
        .get('/sales')
        .then((response) => {
          this.setBannersDetails(response);
        })
        .catch((error) => {
          console.error(error);
        });
      this.dataTableKey += 1;
    },
    toast(text) {
      this.$toast.error(text, {
        position: 'bottom-left',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      });
    },
    checkLess(discount, price) {
      //eslint-disable-next-line no-extra-boolean-cast
      if (!!discount) {
        //eslint-disable-next-line no-extra-boolean-cast
        return parseInt(discount) < parseInt(!!price ? price : '0');
      } else {
        return true;
      }
    },
    edit(item) {
      // for android
      if (this.tab === 0) this.$router.push(`/iaps/offers?id=${item.sku}&p=1`);

      // for iso
      if (this.tab === 1) this.$router.push(`/iaps/offers?id=${item.sku}&p=0`);
    },
    async save() {
      if (this.tab === 2) {
        this.editLoading = true;
        let filterItems = this.items
          .filter((item) => item.isActive === true)
          .map((item) => item.value);

        if (filterItems.length < 3) {
          this.$toast.error(
            'Please select at least 3 percentages for spin the wheel',
            {
              position: 'bottom-left'
            }
          );
          this.editLoading = false;
          return;
        }

        this.spinTheWheelData.mobile = filterItems;

        await this.$axios
          .patch('/app-status/spin-the-wheel-percentage', this.spinTheWheelData)
          .then(() => {
            this.$toast.success(
              'Spin the wheel percentages updated successfully'
            );
          })
          .catch((e) => {
            console.log(e);
          });

        this.editLoading = false;

        return;
      } else if (this.tab === 3) {
        this.editLoading = true;
        let filterItems = this.items
          .filter((item) => item.isActive === true)
          .map((item) => item.value);

        if (filterItems.length < 3) {
          this.$toast.error(
            'Please select at least 3 percentages for spin the wheel',
            {
              position: 'bottom-left'
            }
          );
          this.editLoading = false;
          return;
        }

        this.spinTheWheelData.mobile = filterItems;

        await this.$axios
          .patch(
            '/app-status/spin-the-wheel-percentage?platform=1',
            this.spinTheWheelData
          )
          .then(() => {
            this.$toast.success(
              'Spin the wheel percentages updated successfully'
            );
          })
          .catch((e) => {
            console.log(e);
          });

        this.editLoading = false;

        return;
      }

      for (const item of this.items) {
        if (item.sku === 'lifetime_new' || item.sku === 'lifetime_v1') {
          item.price = +item.price;

          if (this.tab === 0 && item.sku === 'lifetime_v1') {
            item.discountPriceAndroid = +item.discountPriceAndroid;
          }
          if (this.tab === 1 && item.sku === 'lifetime_new') {
            item.discountPrice = +item.discountPrice;
          }

          if (!item.offers) {
            item.offers = [];
          }

          await this.service.update(item);
        }
      }

      this.$toast.success('Life time Products updated successfully');

      if (this.mobileAppBanner && this.mobileAppBanner.is_active === false) {
        this.editLoading = true;
        for (const item of this.items) {
          item.price = +item.price;

          if (this.tab === 0) {
            item.discountPriceAndroid = +item.discountPriceAndroid;
          } else {
            item.discountPrice = +item.discountPrice;
          }

          if (!item.offers) {
            item.offers = [];
          }

          await this.service.update(item);
        }

        this.editLoading = false;
      } else if (this.tab === 0) {
        this.editLoading = true;
        for (const item of this.items) {
          item.price = +item.price;

          if (this.tab === 0) {
            item.discountPriceAndroid = +item.discountPriceAndroid;
          } else {
            item.discountPrice = +item.discountPrice;
          }

          if (!item.offers) {
            item.offers = [];
          }

          await this.service.update(item);
        }

        this.editLoading = false;
      } else {
        this.$toast.error(
          'To edit the discount price of every package manually except lifetime package, kindly deactivate the Banner sales first.'
        );
      }
    },

    async loadData() {
      // Andriod Tab
      if (this.tab === 0) {
        this.items = Object.values(await this.service.fetchAll(1));

        // console.log('item before filter: ', this.items.map(i => i.sku));

        this.items = this.items.map((item) => {
          return {
            ...item,
            discountPrice: Math.floor(item.discountPrice * 100) / 100
          };
        });

        this.items.sort((a, b) => {
          if (a.sku.includes('lifetime_new')) {
            return -1;
          } else if (b.sku.includes('lifetime_new')) {
            return 1;
          } else if (a.sku.includes('standard_student')) {
            return -1;
          } else if (b.sku.includes('standard_student')) {
            return 1;
          } else if (a.sku.includes('premium_prepper')) {
            return -1;
          } else if (b.sku.includes('premium_prepper')) {
            return 1;
          } else if (a.sku.includes('v2')) {
            return -1;
          } else if (b.sku.includes('v2')) {
            return 1;
          } else {
            return 0;
          }
        });

        // console.log('item after filter: ', this.items.filter((o) => androidPlans.includes(o.sku)).map(i => i.sku));

        return this.items.filter((o) => androidPlans.includes(o.sku));
      }

      // ISO Tab
      if (this.tab === 1) {
        this.items = Object.values(await this.service.fetchAll());

        this.items = this.items.map((item) => {
          return {
            ...item,
            discountPrice: Math.floor(item.discountPrice * 100) / 100
          };
        });

        this.items.sort((a, b) => {
          if (a.sku.includes('lifetime_new')) {
            return -1;
          } else if (b.sku.includes('lifetime_new')) {
            return 1;
          } else if (a.sku.includes('standard_student')) {
            return -1;
          } else if (b.sku.includes('standard_student')) {
            return 1;
          } else if (a.sku.includes('premium_prepper')) {
            return -1;
          } else if (b.sku.includes('premium_prepper')) {
            return 1;
          } else if (a.sku.includes('v2')) {
            return -1;
          } else if (b.sku.includes('v2')) {
            return 1;
          } else {
            return 0;
          }
        });

        return this.items.filter((o) => iosPlans.includes(o.sku));
      }

      // STW (ISO)
      if (this.tab === 2) {
        let spinTheWheelData = (
          await this.$axios.get('/app-status/spin-the-wheel-percentage')
        ).data;
        this.spinTheWheelData = spinTheWheelData;

        let data = spinTheWheelData.mobile;

        if (!data) {
          data = [];
          for (let i = 10; i <= 70; i += 10) {
            data.push({
              value: i,
              isActive: false
            });
          }

          return (this.items = data);
        }

        data = data.map((item) => {
          return {
            value: item,
            isActive: true
          };
        });

        for (let i = 10; i <= 70; i += 10) {
          const index = data.findIndex((item) => item.value === i);
          if (index === -1) {
            data.push({
              value: i,
              isActive: false
            });
          }
        }

        data.sort((a, b) => {
          return a.value - b.value;
        });

        return (this.items = data);
      }

      // STW (ANDROID)
      if (this.tab === 3) {
        let spinTheWheelData = (
          await this.$axios.get(
            '/app-status/spin-the-wheel-percentage?platform=1'
          )
        ).data;
        this.spinTheWheelData = spinTheWheelData;

        let data = spinTheWheelData.mobile;

        if (!data) {
          data = [];
          for (let i = 3; i <= 15; i += 2) {
            data.push({
              value: i,
              isActive: false
            });

            return (this.items = data);
          }
        }

        data = data.map((item) => {
          return {
            value: item,
            isActive: true
          };
        });

        for (let i = 3; i <= 15; i += 2) {
          const index = data.findIndex((item) => item.value === i);
          if (index === -1) {
            data.push({
              value: i,
              isActive: false
            });
          }
        }

        data.sort((a, b) => {
          return a.value - b.value;
        });

        return (this.items = data);
      }
    }
  }
};
</script>
