import axios from 'axios';

export class CategoryService {
  async fetchAll() {
    return (await axios.get('/categories/new')).data;
  }

  async fetchAllInactive() {
    return (await axios.get('/categories/new/inactive')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/categories/new/' + id)).data;
  }

  async create(category) {
    return (await axios.post('/categories/new', category)).data;
  }

  async update(category) {
    return (await axios.patch('/categories/new', category)).data;
  }

  // async delete(category) {
  //   return (await axios.delete('/categories/new/' + category.id)).data;
  // }

  async getFalseStatus() {
    return (await axios.get('/categories/new/filters?status=false')).data;
  }
}
