<template>
  <div>
    <data-table
      ref="table"
      title="Prices"
      :loader="loadPrices"
      :headers="headers"
      :allow-add="false"
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('web-aps-prices:edit')
            ? editCoupon
            : null
          : null
      "
      :show-search="false"
    >
      <template #coupon="{ item }">
        {{ getCouponName(item.metadata.coupon) }}
      </template>

      <template #unit_amount="{ item }">
        $ {{ item.unit_amount / 100 }}
      </template>
    </data-table>
    <v-dialog v-model="showDialog" width="400">
      <v-card style="padding: 40px">
        <v-form ref="price">
          <v-select
            v-model="selectedCoupon"
            outlined
            dense
            label="Coupon"
            clearable
            :items="coupons_list"
            item-text="label"
          />
          <v-btn
            style="width: 100%"
            elevation="0"
            color="primary"
            @click="saveCoupon"
          >
            Update price
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" :message="loadingMessage" />
    <error-dialog v-model="error" :error="errorValue" />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { getUser } from '@/utils/local';
import { required, requiredPercentage } from '@/utils/validators';
import { WebAppOfferService } from '@/services/web-app-offers-service';

export default {
  name: 'PriceView',
  components: { ErrorDialog, LoadingDialog, DataTable },

  data: () => ({
    id: '',
    selectedCoupon: '',
    showDialog: false,
    service: new WebAppOfferService(),
    coupons: [],
    headers: [
      {
        value: 'nickname',
        text: 'Name'
      },
      {
        value: 'unit_amount',
        text: 'Amount'
      },
      {
        value: 'coupon',
        text: 'Discount'
      }
    ],

    desktopSalesBanner: {},

    isEdit: false,
    error: false,
    errorValue: {},
    loading: false,
    loadingMessage: ''
  }),

  computed: {
    coupons_list() {
      return this.coupons.map((coupon) => {
        return {
          label: coupon.name,
          value: coupon.id
        };
      });
    }
  },

  methods: {
    getUser,
    required,
    requiredPercentage,

    setBannersDetails(response) {
      const desktopBannerIndex = response.data.sales.findIndex(
        (sale) => sale.platform === 1
      );

      // Check if desktop banner exists and populate desktopSalesBanner
      if (desktopBannerIndex !== -1) {
        const desktopBanner = response.data.sales[desktopBannerIndex];
        this.desktopSalesBanner = desktopBanner;
      }
    },

    async loadPrices() {
      await this.$axios
        .get('/sales')
        .then((response) => {
          this.setBannersDetails(response);
        })
        .catch((error) => {
          console.error(error);
        });
      this.coupons = (await this.service.fetchAllCoupon()).data;
      return (await this.service.fetchPackagesPrices(this.$route.params.id))
        .data;
    },

    getCouponName(id) {
      const coupon = this.coupons.find((coupon) => coupon.id === id);
      return coupon ? `${coupon.name} (${coupon.percent_off}%)` : '';
    },

    async saveCoupon() {
      if (this.$refs.price.validate()) {
        try {
          this.loadingMessage = 'Updating Price';

          let coupon = {};

          if (this.selectedCoupon === null) {
            coupon = {
              metadata: {
                is_discount: '0',
                coupon: ''
              }
            };
          } else {
            coupon = {
              metadata: {
                is_discount: '1',
                coupon: this.selectedCoupon
              }
            };
          }

          await this.service.updatePrice(this.id, coupon);

          this.refresh();

          this.showDialog = false;
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.error = true;
          this.errorValue = {
            title: 'Error while Updating Price',
            description: e?.data?.error ?? 'Some error occurred'
          };
        }
      }
    },

    refresh() {
      let btns = document.getElementsByClassName('refresh');
      for (let i = 0; i < btns.length; i++) {
        btns[i].click();
      }
    },

    editCoupon(item) {
      if (item.nickname === 'MCAT Master access for lifetime') {
        this.id = item.id;
        this.selectedCoupon = item.metadata?.coupon;
        this.showDialog = true;

        return;
      }
      if (
        this.desktopSalesBanner &&
        this.desktopSalesBanner.is_active === false
      ) {
        this.id = item.id;
        this.selectedCoupon = item.metadata?.coupon;
        this.showDialog = true;
      } else {
        this.$toast.error(
          'To edit the discount price of every package manually except lifetime package, kindly deactivate the Banner sales first.'
        );
      }
    }
  }
};
</script>

<style scoped></style>
