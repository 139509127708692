import { getUser } from '@/utils/local';
import FlashcardForm from '@/views/roadmap/flashcards/FlashcardForm.vue';
import FlashcardView from '@/views/roadmap/flashcards/FlashcardView.vue';
import ChapterForm from '@/views/roadmap/chapters/ChapterForm.vue';
import ChapterView from '@/views/roadmap/chapters/ChapterView.vue';
import FlaggedQuestionsView from '@/views/roadmap/flagged_questions/FlaggedQuestionsView.vue';
import SuggestionView from '@/views/roadmap/suggestions/SuggestionView.vue';
import StatsDetails from '@/views/roadmap/stats/StatsDetails.vue';

const roadmapRoutes = [
  getUser()?.scopes.includes('roadmap-chapters:view')
    ? {
        name: 'Roadmap-chapters',
        path: '/roadmap-chapters',
        component: ChapterView
      }
    : null,
  getUser()?.scopes.includes('roadmap-chapters:new') ||
  getUser()?.scopes.includes('roadmap-chapters:edit')
    ? {
        name: 'Roadmap-new-chapter',
        path: '/roadmap-chapter',
        component: ChapterForm
      }
    : null,
  getUser()?.scopes.includes('roadmap-flashcards:view')
    ? {
        name: 'Roadmap-flashcards',
        path: '/roadmap-flashcards',
        component: FlashcardView
      }
    : null,
  getUser()?.scopes.includes('roadmap-flashcards:new') ||
  getUser()?.scopes.includes('roadmap-flashcards:edit')
    ? {
        name: 'Roadmap-new-flashcard',
        path: '/roadmap-flashcard',
        component: FlashcardForm
      }
    : null,

  getUser()?.scopes.includes('roadmap-flagged-questions:view')
    ? {
        name: 'Roadmap-flagged-questions',
        path: '/roadmap-flagged-questions',
        component: FlaggedQuestionsView
      }
    : null,
  getUser()?.scopes.includes('roadmap-suggestion:view')
    ? {
        name: 'Roadmap-suggestion',
        path: '/roadmap-suggestions',
        component: SuggestionView
      }
    : null,
  getUser()?.scopes.includes('roadmap-stats:view')
    ? {
        name: 'Roadmap-stats',
        path: '/roadmap-stats',
        component: StatsDetails
      }
    : null
];

export const roadmapRouter = roadmapRoutes.filter(function (x) {
  return x !== null;
});
