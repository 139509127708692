<template>
  <div>
    <div class="generator">
      <p class="generator__title span-2">
        Generate new Passage Question (Beta)
      </p>

      <v-text-field v-model="query.topic" dense label="Topic name" outlined />

      <v-select
        v-model="query.difficulty"
        :items="difficultyLevels"
        :rules="[required('Select the difficulty level')]"
        dense
        class="mr-2"
        item-text="title"
        item-value="value"
        label="Difficulty Level"
        outlined
        @change="query.difficulty = $event"
      />

      <v-textarea
        v-model="prompt"
        dense
        label="Prompt"
        outlined
        class="span-2"
        :rules="[required('Prompt is required')]"
        append-icon="mdi-refresh"
        @click:append="
          prompt = `Make an ${query.difficulty} MCAT passage about ${query.topic} with five paragraphs written at the college graduate level.`
        "
        auto-grow
      />

      <div class="d-flex span-2 ml-auto mb-4">
        <v-btn
          color="primary"
          elevation="0"
          @click="generatePassageQuestion(false)"
          >Generate</v-btn
        >
      </div>

      <loading-dialog v-model="loading" :message="loadingMsg" />
      <ErrorDialog v-model="error" :error="errorVal" @value="$router.back()" />
    </div>

    <PassageQuestionsPreview
      class="span-2"
      :payload="payload"
      :query="query"
      @error="validationFailed"
    />
  </div>
</template>

<script>
import { required } from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '@/components/ErrorDialog';
import PassageQuestionsPreview from '@/components/questions/PassageQuestionsPreview.vue';

export default {
  name: 'PassageQuestionIdeas',
  components: { ErrorDialog, LoadingDialog, PassageQuestionsPreview },

  data: () => ({
    data: null,
    prompt: '',
    query: {
      topic: 'Biology',
      difficulty: 'extreme tough'
    },
    loading: false,
    loadingMsg: 'Generating Passage Question...',
    error: false,
    errorVal: {}
  }),

  mounted() {
    this.prompt = `Make an ${this.query.difficulty} MCAT passage about ${this.query.topic} with five paragraphs written at the college graduate level.`;
  },

  computed: {
    payload() {
      return this.data || {};
    },

    difficultyLevels() {
      return [
        { title: 'Easy', value: 'easy' },
        { title: 'Tough', value: 'extreme tough' }
      ];
    }
  },

  watch: {
    query: {
      handler() {
        this.prompt = `Make an ${this.query.difficulty} MCAT passage about ${this.query.topic} with five paragraphs written at the college graduate level.`;
      },
      deep: true
    }
  },

  methods: {
    required,

    validationFailed() {
      this.loadingMsg = 'Trying again...';
      this.generateQuestion(true);
    },

    async generatePassageQuestion(isTryAgain) {
      if (!isTryAgain) {
        this.loadingMsg = 'Generating Passage Question...';
      }

      this.loading = true;

      await fetch(
        'https://us-central1-kings-of-the-curve-sonal.cloudfunctions.net/getOpenAIResponse/kings-of-the-curve-sonal/us-central1/getOpenAIResponse?type=passages',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            prompt: this.prompt
          })
        }
      )
        .then(async (response) => {
          const data = await response.json();

          this.data = data?.result[0];
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error('Failed to generate passage question');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async submit() {
      await this.generatePassageQuestion();
    }
  }
};
</script>

<style scoped lang="scss">
.generator {
  display: grid;
  max-width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  background-color: white;
  border-radius: 8px;
  padding: 20px 20px 0;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);

  &__title {
    font-weight: normal !important;
    font-size: 27px;
    padding-bottom: 20px;
    text-align: center !important;
    font-family: google-sans, sans-serif;
  }
}
</style>
