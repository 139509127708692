<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab>Active Categories</v-tab>
        <v-tab>InActive Categories</v-tab>
      </v-tabs>

      <data-table
        :loader="loadData"
        :headers="headers"
        title="Question Media"
        :allow-add="
          getUser() ? getUser().scopes.includes('question-media:new') : false
        "
        allow-filters
        @filter="showFilter = true"
        @add-new="addNew"
        :default-footer="false"
        :show-search="false"
        @done="$router.back()"
        delete-message="Are you sure you want to delete this question's media? Keep in mind that it will also delete the media files from the gallery."
        :delete-handler="
          getUser()
            ? getUser().scopes.includes('question-media:delete')
              ? deleteMedia
              : null
            : null
        "
        :edit-handler="
          getUser()
            ? getUser().scopes.includes('question-media:edit')
              ? edit
              : null
            : null
        "
      >
        <template #primary-action>
          <div style="width: 400px; margin-right: 20px; max-width: 100%">
            <v-text-field
              v-model="search"
              label="Search by keywords"
              dense
              hide-details
              outlined
              clearable
              append-icon="mdi-magnify"
              @click:append="searchData"
              @change="searchData"
            />
          </div>

          <v-btn
            elevation="0"
            class="mr-3"
            @click="reRunAllMedia"
            outlined
            color="primary"
            >Re-Run All Media</v-btn
          >
        </template>
        <template #extra-actions="item">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                color="blue"
                style="cursor: pointer"
                @click="reRunMedia(item.item)"
                >mdi-autorenew</v-icon
              >
            </template>
            <span>Rerun</span>
          </v-tooltip>
        </template>
        <template #images="{ item }">
          <span v-if="item.images && item.images.length > 0">{{
            fetchImage(item.images[0])
          }}</span>
          <span v-else-if="item.videos && item.videos.length > 0">{{
            fetchImage(item.videos[0])
          }}</span>
          <span v-else-if="item.pdfs && item.pdfs.length > 0">{{
            fetchImage(item.pdfs[0])
          }}</span>
          <span v-else>No Media</span>
        </template>
        <template #keywords="{ item }">
          <v-chip
            class="ma-1"
            v-for="(keyword, i) of item.keywords.slice(0, 2)"
            :key="i"
            small
            >{{ keyword }}</v-chip
          >
        </template>
        <template #questions="{ item }">
          <span v-if="item.questions">
            <v-chip
              class="ma-1"
              v-for="(question, i) of item.questions.slice(0, 1)"
              :key="i"
              small
              >{{ question.statement.substring(0, 75) }}
              {{ question.statement.length > 75 ? '...' : '' }}</v-chip
            >
          </span>
        </template>

        <template v-slot:custom-footer>
          <div class="v-data-footer d-flex justify-center align-center">
            <v-pagination
              :value="currentPage + 1"
              class="my-2"
              @input="loadMoreMedia"
              total-visible="10"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :length="totalPages + 1"
            />
            <v-text-field
              v-model="pageInput"
              type="number"
              min="1"
              :max="totalPages + 1"
              outlined
              dense
              hide-details
              style="max-width: 80px"
              placeholder="Page #"
              v-on:keydown.enter="loadMoreMedia(parseInt(pageInput), true)"
            />
          </div>
        </template>
      </data-table>
    </v-card>

    <v-dialog v-model="showFilter" max-width="500">
      <v-card>
        <v-card-title class="headline"> Filter </v-card-title>
        <v-card-text>
          <v-select
            v-model="category"
            :items="categories"
            item-text="name"
            item-value="id"
            label="Category"
            dense
            outlined
            return-object
          ></v-select>
          <v-select
            v-model="subCategory"
            :items="category && category.subCategories"
            item-text="name"
            item-value="id"
            label="Sub Category"
            dense
            outlined
            return-object
            :disabled="!category"
          ></v-select>
          <v-select
            v-model="mediaType"
            :items="[
              { text: 'Images', value: 1 },
              { text: 'Videos', value: 2 },
              { text: 'PDFs', value: 3 }
            ]"
            item-text="text"
            item-value="value"
            label="Media Type"
            dense
            outlined
          ></v-select>

          <v-select
            v-model="QCountSort"
            :items="[
              { text: 'None', value: null },
              { text: 'Ascending', value: 'QCountAsc' },
              { text: 'Descending', value: 'QCountDesc' }
            ]"
            item-text="text"
            item-value="value"
            label="Questions Count Sort"
            dense
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions class="pb-4 px-6">
          <v-btn color="primary" outlined @click="clearFilter()">Clear</v-btn>

          <v-spacer></v-spacer>
          <v-btn text @click="showFilter = false">Close</v-btn>
          <v-btn
            dark
            color="primary"
            elevation="0"
            @click="
              searchData();
              showFilter = false;
            "
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoadingDialog v-model="loading" message="Loading..." />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import { QuestionMediaService } from '@/services/question_media-service';
import { storage } from '@/plugins/firebase';
import LoadingDialog from '@/components/LoadingDialog';
import { NotificationsService } from '@/services/notifications-service';
import { CategoryService } from '../../services/category-service';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    tab: 0,
    isActiveQuestionMedia: true,
    showFilter: false,
    pageInput: '',
    loading: false,
    categoryLoading: false,
    perPage: 10,
    totalPages: 0,
    currentPage: 0,
    search: '',
    mediaType: null,
    QCountSort: null,
    category: null,
    subCategory: null,
    service: new QuestionMediaService(),
    notificationService: new NotificationsService(),
    categoryService: new CategoryService(),
    categories: [],

    headers: [
      {
        text: 'Image',
        value: 'images',
        width: 120
      },
      {
        text: 'Keywords',
        value: 'keywords',
        sortable: false,
        width: '30%'
      },
      {
        text: 'Questions',
        value: 'questions',
        sortable: false,
        width: '30%'
      },
      {
        text: 'Questions Count',
        value: 'questions_count',
        sortable: false,
        width: 40
      }
    ]
  }),

  watch: {
    async $route() {
      // Reload Data when route hash change for pagination
      this.refresh();
    },

    tab() {
      this.tab === 0
        ? (this.isActiveQuestionMedia = true)
        : (this.isActiveQuestionMedia = false);
      this.refresh();
    }
  },

  methods: {
    getUser,

    async fetchCategories() {
      this.categoryLoading = true;
      this.categories = this.isActiveQuestionMedia
        ? await this.categoryService.fetchAll()
        : await this.categoryService.fetchAllInactive();
      this.categoryLoading = false;
    },

    clearFilter() {
      this.category = null;
      this.subCategory = null;
      this.mediaType = null;
      this.QCountSort = null;

      this.searchData();
      this.showFilter = false;
    },

    searchData() {
      this.$router.replace({
        path: '/question-medias',
        hash: '#1',
        query: {
          mediaType: this.mediaType,
          QCountSort: this.QCountSort,
          category: this.category ? this.category.id : null,
          subCategory: this.subCategory ? this.subCategory.id : null
        }
      });

      this.refresh();
    },

    refresh() {
      document.getElementById('refresh').click();
    },

    async loadMoreMedia(value, byPageInput = false) {
      // Update route hash for pagination
      if (value > 0 && value <= this.totalPages + 1) {
        await this.$router.replace({
          path: '/question-medias',
          hash: '#' + value,
          query: {
            mediaType: this.mediaType,
            QCountSort: this.QCountSort,
            category: this.category ? this.category.id : null,
            subCategory: this.subCategory ? this.subCategory.id : null
          }
        });
      }
      if (byPageInput === false) {
        this.pageInput = '';
      }
    },

    addNew() {
      this.$router.push('/question-media');
    },

    edit(item) {
      this.$router.push(
        `/question-media?id=${item.id}${this.tab === 1 ? '&inactive=true' : ''}`
      );
    },

    async deleteMedia(item) {
      await this.service.delete(item);
      this.$toast.success('Question Media Deleted Successfully');
    },

    async loadData() {
      // Fetch route hash for pagination
      this.fetchRouteHash();

      await this.fetchCategories();

      if (this.$route.query.mediaType) {
        this.mediaType = parseInt(this.$route.query.mediaType);
      }

      if (this.$route.query.QCountSort) {
        this.QCountSort = this.$route.query.QCountSort;
      }

      if (this.$route.query.category) {
        this.category = this.categories.find(
          (c) => c.id == this.$route.query.category
        );

        if (this.$route.query.subCategory) {
          console.log(this.category.subCategories);
          console.log(this.$route.query.subCategory);
          this.subCategory = this.category.subCategories.find(
            (c) => c.id == this.$route.query.subCategory
          );
        }
      }

      let res = await this.service.paginate(
        this.isActiveQuestionMedia,
        this.perPage,
        this.currentPage,
        this.search,
        this.mediaType,
        this.QCountSort,
        this.category ? this.category.id : null,
        this.subCategory ? this.subCategory.id : null
      );

      if (res && res.data) {
        this.totalPages = res.total_pages;
        this.currentPage = res.page;
        return res.data.map((item) => {
          item.questions_count = item.questions.length;
          return item;
        });
      } else {
        this.totalPages = 0;

        return [];
      }
    },

    fetchRouteHash() {
      if (this.$route.hash === '') {
        this.currentPage = 0;
      } else {
        let hash = parseInt(this.$route.hash.replace('#', ''));
        this.currentPage = hash - 1;
        if (
          hash <= 0 ||
          (this.currentPage > this.totalPages && this.totalPages > 0)
        ) {
          this.$router.replace({ path: '/question-medias', hash: '#1' });
        }
      }
    },

    fetchImage(img) {
      const name = storage.refFromURL(img).name;
      return name.substring(
        0,
        name.indexOf('~') !== -1 ? name.indexOf('~') : name.length
      );
    },

    async reRunMedia(item) {
      this.loading = true;
      await this.service.update(item);
      this.loading = false;
      document.getElementById('refresh').click();
    },

    async reRunAllMedia() {
      const data =
        await this.notificationService.fetchMcatMetricsNotifications();
      const isAlreadyRunning = data?.question_media_rerun;
      if (isAlreadyRunning) {
        this.$toast.error(
          'Already In Process You will receive email when its done'
        );
        return;
      }
      if (confirm('Warning! Are you sure?')) {
        this.loading = true;
        await this.service.reRunMedia();
        this.loading = false;
        document.getElementById('refresh').click();
      }
    }
  }
};
</script>

<style scoped></style>
