var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleForm',{attrs:{"onSubmit":_vm.submit},on:{"done":function($event){return _vm.$router.back()}}},[_c('p',{staticClass:"span-2 form__title"},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Update Student Group' : 'Create New Student Group')+" ")]),_c('v-text-field',{staticClass:"span-2",attrs:{"label":"Name","dense":"","rules":[_vm.required('A name must be provided')],"outlined":"","persistent-hint":""},model:{value:(_vm.studentGroup.name),callback:function ($$v) {_vm.$set(_vm.studentGroup, "name", $$v)},expression:"studentGroup.name"}}),(_vm.getUser().scopes.includes('studentGroups:all'))?_c('v-autocomplete',{staticClass:"span-2",attrs:{"items":_vm.admins,"item-text":"text","item-value":"value","loading":_vm.adminsLoading,"rules":[_vm.required('An admin must be selected')],"label":"Admins","multiple":"","dense":"","outlined":"","persistent-hint":""},model:{value:(_vm.studentGroup.admins),callback:function ($$v) {_vm.$set(_vm.studentGroup, "admins", $$v)},expression:"studentGroup.admins"}}):_vm._e(),(!_vm.isEdit)?_c('v-switch',{attrs:{"label":"Bulk Creation"},model:{value:(_vm.isBulkCreation),callback:function ($$v) {_vm.isBulkCreation=$$v},expression:"isBulkCreation"}}):_vm._e(),_c('br'),(_vm.isBulkCreation)?_c('div',{staticClass:"span-2"},[_c('v-btn',{attrs:{"color":"primary","width":"120"}},[_c('label',{staticClass:"py-2 px-2",staticStyle:{"cursor":"pointer"},attrs:{"for":"file"}},[_c('v-icon',{staticClass:"v-btn__pre-icon",attrs:{"size":"16"}},[_vm._v("mdi-upload")]),_vm._v(" Upload CSV ")],1)]),(_vm.file)?_c('div',{staticClass:"file-name"},[(_vm.file)?_c('span',[_vm._v(_vm._s(_vm.file.name)+" "),_c('v-icon',{attrs:{"size":"18","color":"red"},on:{"click":function () {
              _vm.$refs.inputRef.reset();
            }}},[_vm._v(" mdi-delete ")])],1):_vm._e()]):_vm._e()],1):_vm._e(),_c('v-file-input',{ref:"inputRef",staticClass:"d-none",attrs:{"id":"file","accept":".csv"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),(!_vm.isBulkCreation)?_c('v-text-field',{staticClass:"span-2",attrs:{"label":"Search User by Email","dense":"","append-icon":"mdi-magnify","outlined":"","persistent-hint":"","loading":_vm.userLoading},on:{"click:append":_vm.getSearchUser,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getSearchUser.apply(null, arguments)}},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}}):_vm._e(),(_vm.searchUserResult || _vm.userError)?_c('div',{staticClass:"span-2 d-flex justify-space-between"},[_c('p',[_vm._v(" "+_vm._s(_vm.searchUserResult ? 'User Found: ' : 'No User Found')+" "),_c('span',{staticStyle:{"font-weight":"400","color":"#6c757d"}},[_vm._v(" "+_vm._s(_vm.searchUserResult.email))])]),_c('v-btn',{attrs:{"elevation":"0","small":"","color":"primary","disabled":!_vm.searchUserResult},on:{"click":function($event){if (
          !_vm.studentGroup.user_ids.find(
            function (user) { return user.email === _vm.searchUserResult.email; }
          )
        ) {
          _vm.studentGroup.user_ids.push({
            email: _vm.searchUserResult.email,
            id: _vm.searchUserResult.id
          });
        }

        _vm.searchUserResult = null;
        _vm.userError = false;}}},[_vm._v(" Add User ")])],1):_vm._e(),_vm._l((_vm.studentGroup.user_ids),function(user){return _c('div',{key:user.id,staticClass:"my-2 span-2 d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(_vm.studentGroup.user_ids.indexOf(user) + 1)+". "+_vm._s(user.email))]),_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.viewUserDetails(user)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-eye ")])],1),_c('v-btn',{attrs:{"x-small":"","elevation":"0","color":"error"},on:{"click":function($event){_vm.studentGroup.user_ids.splice(_vm.studentGroup.user_ids.indexOf(user), 1)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-delete ")])],1)],1)])}),_c('loading-dialog',{attrs:{"message":"Fetching Student Group Data"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }