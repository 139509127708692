<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Category' : 'Add a new Category' }}
    </p>

    <v-img
      v-if="single_icon || category.single_icon"
      :src="singelImgObjectUrl() ? singelImgObjectUrl() : category.single_icon"
      class="span-2 mb-6"
      max-height="200px"
      contain
      @load="singleImgLoading = false"
    >
      <template v-slot:placeholder>
        <div class="w-full h-full d-flex justify-center align-center">
          <v-progress-circular
            v-if="singleImgLoading"
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
    </v-img>

    <v-file-input
      v-model="single_icon"
      accept="image/png, image/jpeg, image/bmp"
      class="span-2"
      outlined
      dense
      :label="
        category.single_icon
          ? category.single_icon
              .split('%2F')
              .pop()
              .split('?')[0]
              .replace(/%20/g, ' ')
          : 'Select Icon'
      "
      prepend-icon="mdi-image"
      placeholder="Select an icon"
      :rules="
        !isEdit &&
        (category.isMultiplayer ||
          (restrictions &&
            (restrictions.includes(6) || restrictions.includes(9))))
          ? [required('You must select an icon')]
          : []
      "
    >
    </v-file-input>

    <v-card-text class="span-1">
      <p>Multiplayer:</p>
    </v-card-text>
    <v-checkbox
      v-model="category.isMultiplayer"
      label="Yes"
      class="span-1"
      color="primary"
      hide-details
    />

    <div class="span-2">
      <v-select
        v-model="restrictions"
        :items="restrictionGameModes"
        label="Select game mode restrictions"
        multiple
        small-chips
        outlined
        :disabled="!restrictionGameModes"
      ></v-select>
    </div>

    <div v-if="category.isMultiplayer" class="span-2">
      <p>Multiplayer Icons:</p>

      <v-img
        :src="
          multiple_icons[0]
            ? createImgObjectUrl(multiple_icons[0])
            : category.multiple_icons[0]
        "
        class="span-1 mb-6"
        max-height="200px"
        contain
        @load="multipleImg1Loading = false"
      >
        <template v-slot:placeholder>
          <div class="w-full h-full d-flex justify-center align-center">
            <v-progress-circular
              v-if="multipleImg1Loading"
              indeterminate
              size="64"
              color="primary"
            ></v-progress-circular>
          </div> </template
      ></v-img>

      <v-file-input
        v-model="multiple_icons[0]"
        accept="image/png, image/jpeg, image/bmp"
        outlined
        dense
        label="Locked Icon"
        prepend-icon="mdi-image"
        placeholder="Select Locked Icon"
        :rules="isEdit ? [] : [required('You must select an icon')]"
      >
      </v-file-input>

      <v-img
        :src="
          multiple_icons[1]
            ? createImgObjectUrl(multiple_icons[1])
            : category.multiple_icons[1]
        "
        class="span-1 mb-6"
        max-height="200px"
        contain
        @load="multipleImg2Loading = false"
      >
        <template v-slot:placeholder>
          <div class="w-full h-full d-flex justify-center align-center">
            <v-progress-circular
              v-if="multipleImg2Loading"
              indeterminate
              size="64"
              color="primary"
            ></v-progress-circular>
          </div> </template
      ></v-img>

      <v-file-input
        v-model="multiple_icons[1]"
        accept="image/png, image/jpeg, image/bmp"
        outlined
        dense
        label="Selected Icon"
        prepend-icon="mdi-image"
        placeholder="Select selected icon"
        :rules="isEdit ? [] : [required('You must select an icon')]"
      >
      </v-file-input>

      <v-img
        :src="
          multiple_icons[2]
            ? createImgObjectUrl(multiple_icons[2])
            : category.multiple_icons[2]
        "
        class="span-1 mb-6"
        max-height="200px"
        contain
        @load="multipleImg3Loading = false"
      >
        <template v-slot:placeholder>
          <div class="w-full h-full d-flex justify-center align-center">
            <v-progress-circular
              v-if="multipleImg3Loading"
              indeterminate
              size="64"
              color="primary"
            ></v-progress-circular>
          </div> </template
      ></v-img>

      <v-file-input
        v-model="multiple_icons[2]"
        accept="image/png, image/jpeg, image/bmp"
        outlined
        dense
        label="Unlocked Icon"
        prepend-icon="mdi-image"
        placeholder="Select unlocked icon"
        :rules="isEdit ? [] : [required('You must select an icon')]"
      >
      </v-file-input>
    </div>

    <v-text-field
      v-model="category.name"
      dense
      label="Category Name"
      :rules="[required('You must write a name')]"
      class="span-2"
      outlined
    />

    <sub-category-picker
      v-model="category.subCategories"
      style="margin-top: -25px; margin-left: 25px"
    />

    <v-card-text class="span-1">
      <p>Category Status:</p>
    </v-card-text>
    <v-checkbox
      v-model="category.status"
      label="Active"
      class="span-1"
      color="primary"
      hide-details
    />

    <loading-dialog v-model="loading" message="Loading..." />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { restrictionGameModes } from '@/utils/local';
import { required } from '@/utils/validators';
import SubCategoryPicker from '../../components/questions/SubCategoryPicker';
import { CategoryService } from '@/services/category-service';
import LoadingDialog from '../../components/LoadingDialog';
import { storage } from '@/plugins/firebase';

export default {
  components: { LoadingDialog, SubCategoryPicker, SimpleForm },

  data: () => ({
    singleImgLoading: true,
    multipleImg1Loading: true,
    multipleImg2Loading: true,
    multipleImg3Loading: true,
    isEdit: false,
    loading: false,
    service: new CategoryService(),

    multipleIconPath: null,

    restrictions: null,

    restrictionGameModes,

    single_icon: null,
    multiple_icons: [],

    selectedSubCategories: [],

    category: {
      status: true,
      isMultiplayer: true,
      single_icon: null,
      multiple_icons: [],
      subCategories: [],
      included_in: null
    }
  }),

  mounted() {
    this.loadCategory();
  },

  methods: {
    required,

    singelImgObjectUrl() {
      if (this.single_icon) {
        return URL.createObjectURL(this.single_icon);
      }
    },

    createImgObjectUrl(file) {
      if (file) {
        return URL.createObjectURL(file);
      }
    },

    async loadCategory() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.category = await this.service.fetchOne(this.$route.query.id);

      this.restrictions = this.category.included_in;

      this.multipleIconPath = this.category.multiple_icons;

      this.category.multiple_icons = [];
      if (
        this.multipleIconPath &&
        !this.multipleIconPath.includes(
          'https://firebasestorage.googleapis.com'
        )
      ) {
        this.category.multiple_icons[0] = await storage
          .ref(`${this.multipleIconPath}/locked`)
          .getDownloadURL();
        this.category.multiple_icons[1] = await storage
          .ref(`${this.multipleIconPath}/selected`)
          .getDownloadURL();
        this.category.multiple_icons[2] = await storage
          .ref(`${this.multipleIconPath}/unlocked`)
          .getDownloadURL();
      }

      this.loading = false;
    },

    preCheck(context) {
      if (!context.validate()) return false;
      let count = 0;
      if (
        this.category.subCategories &&
        this.category.subCategories.length > 0
      ) {
        this.category.subCategories.forEach((item) => {
          if (!item.id || !item.name) {
            count++;
          }
        });
      }
      if (count > 0) {
        context.reportError({
          title: 'Invalid Category Data',
          description: 'One or more subcategory is not selected.'
        });
        return false;
      }

      return true;
    },

    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          context.changeLoadingMessage('Updating Category');
          try {
            if (this.category.subCategories.length == 0) {
              context.reportError({
                title: 'Invalid Category Data',
                description:
                  'At least one subcategory must be selected in order to create a category.'
              });
              return false;
            }

            if (this.single_icon) {
              const fileRef = storage.ref(
                `/category_icons/single/${this.category.name}`
              );
              await fileRef.put(this.single_icon);
              this.category.single_icon = await fileRef.getDownloadURL();
            }

            if (this.category.isMultiplayer && this.multiple_icons.length > 0) {
              for (let i = 0; i < this.multiple_icons.length; i++) {
                let iconName = '';
                switch (i) {
                  case 0:
                    iconName = 'locked';
                    break;
                  case 1:
                    iconName = 'selected';
                    break;
                  case 2:
                    iconName = 'unlocked';
                    break;
                }

                const fileRef = storage.ref(
                  `/category_icons/multiple/${this.category.name}/${iconName}`
                );
                await fileRef.put(this.multiple_icons[i]);
                this.category.multiple_icons = fileRef.fullPath;
              }

              this.category.multiple_icons = this.category.multiple_icons
                .split('/')
                .slice(0, -1)
                .join('/');
            } else {
              this.category.multiple_icons = this.multipleIconPath;
            }

            this.selectedSubCategories = this.category.subCategories;
            this.category.subCategories = this.category.subCategories.map(
              (item) => item.id
            );
            this.category.included_in = this.restrictions;
            await this.service.update(this.category);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error while updating Category',
              description: e.response?.data?.error || e.data?.error
            });

            this.category.subCategories = this.selectedSubCategories;
            return false;
          }
        } else {
          context.changeLoadingMessage('Creating A New Category');
          try {
            if (this.category.subCategories.length == 0) {
              context.reportError({
                title: 'Invalid Category Data',
                description:
                  'At least one subcategory must be selected in order to create a category.'
              });
              return false;
            }

            if (this.single_icon) {
              const fileRef = storage.ref(
                `/category_icons/single/${this.category.name}`
              );
              await fileRef.put(this.single_icon);
              this.category.single_icon = await fileRef.getDownloadURL();
            }

            if (this.category.isMultiplayer && this.multiple_icons.length > 0) {
              for (let i = 0; i < this.multiple_icons.length; i++) {
                let iconName = '';
                switch (i) {
                  case 0:
                    iconName = 'locked';
                    break;
                  case 1:
                    iconName = 'selected';
                    break;
                  case 2:
                    iconName = 'unlocked';
                    break;
                }

                const fileRef = storage.ref(
                  `/category_icons/multiple/${this.category.name}/${iconName}`
                );
                await fileRef.put(this.multiple_icons[i]);
                this.category.multiple_icons = fileRef.fullPath;
              }

              this.category.multiple_icons = this.category.multiple_icons
                .split('/')
                .slice(0, -1)
                .join('/');
            } else {
              this.category.multiple_icons = '';
            }

            this.category.subCategories = this.category.subCategories.map(
              (item) => item.id
            );

            this.category.included_in = this.restrictions;
            await this.service.create(this.category);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error while creating Category',
              description: e.response?.data?.error || e.data?.error
            });
            return false;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}

.file-pickers {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto;
}
</style>
