<template>
  <v-dialog :value="flag" width="470" @input="$emit('value', false)">
    <SimpleForm
      :disabled="disabled"
      :onSubmit="submit"
      class="my-0"
      @done="$emit('value', false)"
    >
      <p class="span-2 form__title">
        {{ isEdit ? 'Update Institution' : 'Add a new Institution' }}
      </p>

      <div
        class="span-2 d-flex justify-center align-center"
        :class="{ 'mb-2': showImageError }"
      >
        <image-upload
          :hasError="showImageError"
          :image_obj="old_image"
          class="mx-auto"
          @uploadedImage="getUploadedImage"
        />
      </div>

      <v-text-field
        v-model="institution.name"
        :rules="[required('You must write institution name')]"
        class="span-2 modified-message"
        dense
        label="Institution Statement"
        outlined
      />

      <v-select
        v-model="institution.alpha_two_code"
        :items="alpha_two_code"
        :rules="[required('You must select a institution country code')]"
        class="span-2"
        dense
        item-text="text"
        item-value="value"
        label="Country Code"
        outlined
      />

      <div class="span-2">
        <div class="d-flex align-center justify-space-between my-3">
          <h4>Domains</h4>
          <v-btn color="primary" @click="addDomain()">Add Domain </v-btn>
        </div>
        <v-text-field
          v-for="(domain, i) of institution.domains"
          :key="i"
          v-model="domain.name"
          :append-icon="institution.domains.length > 1 ? 'mdi-close' : ''"
          :label="'Domain ' + (i + 1)"
          :rules="[required()]"
          dense
          outlined
          @click:append="removeDomain(i)"
        />
      </div>

      <div class="span-2">
        <div class="d-flex align-center justify-space-between my-3">
          <h4>Web Pages</h4>
          <v-btn color="primary" @click="addWebpage()">Add Web Page </v-btn>
        </div>
        <v-text-field
          v-for="(webPage, i) of institution.web_pages"
          :key="i"
          v-model="webPage.name"
          :append-icon="institution.web_pages.length > 1 ? 'mdi-close' : ''"
          :label="'Web Page ' + (i + 1)"
          :rules="[required()]"
          dense
          outlined
          @click:append="removeWebpage(i)"
        />
      </div>

      <loading-dialog v-model="loading" message="Loading Institution Data" />
    </SimpleForm>
  </v-dialog>
</template>

<script>
import SimpleForm from '../../components/Form';
import ImageUpload from '@/components/ImageUpload';
import { required } from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import { getUser } from '@/utils/local';
import { InstitutionsService } from '@/services/institutions-service';
import { storage } from '@/plugins/firebase';

export default {
  name: 'InstitutionForm',
  components: { LoadingDialog, SimpleForm, ImageUpload },

  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    institution: {
      type: Object,
      default: () => ({
        domains: [{}],
        web_pages: [{}]
      })
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: false,
    service: new InstitutionsService(),

    alpha_two_code: [
      {
        text: 'US',
        value: 'US'
      },
      {
        text: 'CA',
        value: 'CA'
      }
    ],

    // only for image
    showImageError: false,
    disabled: false,
    image: null,
    displayImage: null
  }),

  computed: {
    old_image() {
      return {
        url: this.displayImage
      };
    }
  },

  mounted() {
    // this.loadInstitution();
    // if (this.institution) {
    //   this.displayImage = this.institution.logo;
    // }
  },

  watch: {
    institution: {
      handler() {
        if (this.institution) {
          this.displayImage = this.institution.logo;
        }
      }
    }
  },

  methods: {
    getUser,
    required,

    getUploadedImage(_image_obj) {
      if (_image_obj) {
        this.image = _image_obj.file;
        this.showImageError = false;
        this.disabled = false;
      } else {
        this.showImageError = true;
        this.disabled = true;
      }
    },

    async submit(context) {
      if (this.preCheck(context)) {
        context.changeLoadingMessage('Please Wait...');
        if (!this.isEdit) {
          // validate Image
          if (!this.image) {
            this.showImageError = true;
            return false;
          }
        }

        if (this.image) {
          const fileName = this.image.name.split('.')[0];

          // Upload File
          let reference = storage.ref('institutions/logo/' + fileName);
          await reference.put(this.image);

          // Get download url from firebase storage and add to newMedia object
          this.displayImage = await storage
            .ref('institutions/logo/')
            .child(fileName)
            .getDownloadURL();
        }

        const data = { ...this.institution };
        data.logo = this.displayImage || '';
        data.domains = [];
        data.web_pages = [];
        this.institution.domains.forEach((item) => {
          data.domains.push(item.name);
        });
        this.institution.web_pages.forEach((item) => {
          data.web_pages.push(item.name);
        });
        data.is_verified = true;

        if (this.isEdit) {
          context.changeLoadingMessage('Updating Institution');
          try {
            await this.service.update(data);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating Institution',
              description: e.toString()
            });
            return false;
          }
        } else {
          try {
            context.changeLoadingMessage('Creating New Institution');
            await this.service.create(data);
            return true;
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating Institution',
              description: e.toString()
            });
            return false;
          }
        }
      }
    },
    preCheck(context) {
      if (!context.validate()) return false;

      return true;
    },
    removeDomain(i) {
      if (this.institution.domains.length > 1) {
        this.institution.domains.splice(i, 1);
      }
    },
    addDomain() {
      if (this.institution.domains.length > 0) {
        this.institution.domains.push({});
      }
    },
    removeWebpage(i) {
      if (this.institution.web_pages.length > 1) {
        this.institution.web_pages.splice(i, 1);
      }
    },
    addWebpage() {
      if (this.institution.web_pages.length > 0) {
        this.institution.web_pages.push({});
      }
    }
  }
};
</script>

<style scoped></style>
