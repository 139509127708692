import { render, staticRenderFns } from "./QuestionMediaForm.vue?vue&type=template&id=4de2aac6&scoped=true&"
import script from "./QuestionMediaForm.vue?vue&type=script&lang=js&"
export * from "./QuestionMediaForm.vue?vue&type=script&lang=js&"
import style0 from "./QuestionMediaForm.vue?vue&type=style&index=0&id=4de2aac6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de2aac6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VCheckbox,VChip,VCombobox,VDivider,VIcon,VSelect})
