import {getUser} from "@/utils/local";
import QOTDView from "@/views/scheduled_qotd/QOTDView.vue";
import QOTDForm from "@/views/scheduled_qotd/QOTDForm.vue";

export const scheduledQOTDRoutes = [
    getUser()?.scopes.includes('scheduledQotd:view') ?
        {
            name: 'ScheduledQOTD',
            path: '/scheduled-qotds',
            component: QOTDView
        } : null,
    getUser()?.scopes.includes('scheduledQotd:edit') ?
        {
            name: 'ScheduledQOTD',
            path: '/scheduled-qotd',
            component: QOTDForm
        } : null,
];

export const scheduledQOTDRouter = scheduledQOTDRoutes.filter(function (x) {
    return x !== null;
})

