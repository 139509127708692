<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Flashcards"
      :allow-add="
        getUser() ? getUser().scopes.includes('flashcards:new') : false
      "
      @add-new="addNew"
      @done="$router.back()"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('flashcards:delete')
            ? service.delete
            : null
          : null
      "
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('flashcards:edit')
            ? edit
            : null
          : null
      "
    >
      <template #primary-action>
        <v-btn @click="openUploadFlashcards" elevation="0" class="mr-3"
          >Import</v-btn
        >
      </template>
      <template #deck="{ item }">
        <p class="ma-0">{{ item.deck.name }}</p>
      </template>
      <template #difficulty="{ item }">
        <p v-if="item.difficulty === 0" class="ma-0">Easy</p>
        <p v-else-if="item.difficulty === 1" class="ma-0">Medium</p>
        <p v-else class="ma-0">Hard</p>
      </template>
    </data-table>
    <v-dialog persistent width="50%" v-model="showFileUpload">
      <v-card class="px-5 py-7">
        <div v-if="!isUploading">
          <v-card-title class="px-0"
            >{{
              isUploaded ? 'Flashcards Imported' : 'Import Flashcards By CSV'
            }}
          </v-card-title>
          <v-form ref="import">
            <div v-if="isUploaded">
              <div class="d-flex py-5">
                <v-chip class="mr-2" color="primary"
                  >Total: {{ this.flashcards.length }}</v-chip
                >
                <v-chip class="mr-2" color="success"
                  >Completed: {{ this.added }}
                </v-chip>
                <v-chip class="mr-2" color="error"
                  >Rejected: {{ this.flashcards.length - this.added }}</v-chip
                >
              </div>
              <div class="mb-2">
                <ul
                  style="color: red"
                  v-for="(error, i) of importErrors"
                  :key="i"
                >
                  <li>{{ error.text }} at entry # {{ error.item }}</li>
                </ul>
              </div>
              <div class="d-flex justify-end">
                <v-btn @click="cancelUpload" class="mr-2">OK</v-btn>
                <v-btn @click="newImport" class="ml-2" color="primary"
                  >New Import</v-btn
                >
              </div>
            </div>
            <div v-else>
              <p>
                Download a
                <a
                  href="/flashcards_template.csv"
                  download="flashcards_template.csv"
                  >sample CSV template</a
                >
                to see an example of the format required.
              </p>
              <p>
                Download
                <a href="javascript:void(0)" @click="download">Decks</a>
                reference sheet to refer to their ids.
              </p>
              <p>Use Space to add multiple images.</p>
              <p>Type can only be Text or Image</p>
              <p>Question Type can only be Plain or Cloze Deletion</p>
              <v-file-input
                :rules="[required()]"
                v-model="uploadFile"
                outlined
                dense
                label="Upload CSV"
                prepend-icon=""
                accept=".csv"
                @change="bulkUpload"
              />
              <div class="d-flex justify-end">
                <div>
                  <v-btn @click="cancelUpload" class="mr-2">Cancel</v-btn>
                  <v-btn @click="uploadFlashcards" class="ml-2" color="primary"
                    >Upload</v-btn
                  >
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div v-else class="pa-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate class="mr-3" />
          <p class="ma-0">Importing Flashcards...</p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import { FlashcardsService } from '@/services/flashcards-service';
import Papa from 'papaparse';
import { required } from '@/utils/validators';
import { DecksService } from '@/services/deck-service';

export default {
  components: { DataTable },

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new FlashcardsService(),
    deckService: new DecksService(),
    headers: [
      {
        text: 'Question',
        value: 'frontText'
      },
      {
        text: 'Answer',
        value: 'backText'
      },
      {
        text: 'Deck',
        value: 'deck'
      }
    ],

    showFileUpload: false,

    decks: [],
    allSubs: [],
    uploadFile: null,
    isUploaded: false,
    isUploading: false,
    flashcards: [],
    importErrors: [],
    added: 0
  }),

  methods: {
    getUser,
    required,
    edit(item) {
      this.$router.push(`/flashcard?id=${item.id}`);
    },
    addNew() {
      this.$router.push('/flashcard');
    },
    async loadData() {
      this.decks = await this.deckService.fetchAll();
      return this.service.fetchAll();
    },
    openUploadFlashcards() {
      this.showFileUpload = true;
    },
    bulkUpload(item) {
      if (item) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const result = Papa.parse(data.toString(), {
            header: true,
            transformHeader: function (header) {
              return header.toLowerCase();
            },
            skipEmptyLines: true
          });
          this.flashcards = result.data;
        };
        reader.readAsText(item);
      }
    },
    async checkIfImageExists(url) {
      const data = await fetch(url);
      return data.ok;
    },
    async uploadFlashcards() {
      if (this.$refs.import.validate()) {
        this.added = 0;
        this.isUploading = true;
        this.isUploaded = false;
        this.importErrors = [];
        for (let i = 0; i < this.flashcards.length; i++) {
          let item = this.flashcards[i];
          let error = false;
          const index = this.flashcards.indexOf(item);
          const flashcard = {};
          if (item.deck) {
            const deck = this.decks.find((x) => x.id === item.deck);
            if (deck) {
              flashcard.deck = deck.id;
            } else {
              this.importErrors.push({
                item: index + 2,
                text: 'Flashcard Deck is wrong'
              });
              error = true;
            }
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Flashcard Deck is missing'
            });
            error = true;
          }
          if (item.type && (item.type === 'Image' || item.type === 'Text')) {
            if (item.type === 'Text') {
              if (
                item['question-type'] &&
                (item['question-type'] === 'Plain' ||
                  item['question-type'] === 'Cloze Deletion')
              ) {
                if (item['question-type'] === 'Plain') {
                  if (item.question) {
                    flashcard.frontText = item.question;
                  } else {
                    this.importErrors.push({
                      item: index + 2,
                      text: 'Flashcard Question is missing or wrong'
                    });
                    error = true;
                  }
                  if (item.answer) {
                    flashcard.backText = item.answer;
                  } else {
                    this.importErrors.push({
                      item: index + 2,
                      text: 'Flashcard Question Answer is missing or wrong'
                    });
                    error = true;
                  }
                } else if (item['question-type'] === 'Cloze Deletion') {
                  if (item.question) {
                    flashcard.frontText = item.question;
                  } else {
                    this.importErrors.push({
                      item: index + 2,
                      text: 'Flashcard Question is missing or wrong'
                    });
                    error = true;
                  }
                  if (item.answer) {
                    flashcard.backText = item.answer;
                  }
                  flashcard.ISClosedDeletion = true;
                }
                flashcard['question-type'] = item['question-type'];
              } else {
                this.importErrors.push({
                  item: index + 2,
                  text: 'Flashcard Question Type is missing or wrong'
                });
                error = true;
              }
              let backImages = [];
              if (item['back-images']) {
                backImages = item['back-images'].split(' ');
              }
              flashcard.back_images = backImages;
            } else if (item.type === 'Image') {
              let frontImages = [];
              let backImages = [];

              if (item['front-images']) {
                frontImages = item['front-images'].split(' ');
                for (let i = 0; i < frontImages.length; i++) {
                  const isOk = await this.checkIfImageExists(frontImages[i]);
                  if (!isOk) {
                    frontImages.splice(i, 1);
                    this.importErrors.push({
                      item: index + 2,
                      text: 'Flashcard Front Image is not valid'
                    });
                    error = true;
                  }
                }
                flashcard.front_images = frontImages;
              } else {
                this.importErrors.push({
                  item: index + 2,
                  text: 'Flashcard Front Images are missing'
                });
                error = true;
              }
              if (item['back-images']) {
                backImages = item['back-images'].split(' ');
                for (let i = 0; i < backImages.length; i++) {
                  const isOk = await this.checkIfImageExists(backImages[i]);
                  if (!isOk) {
                    backImages.splice(i, 1);
                    this.importErrors.push({
                      item: index + 2,
                      text: 'Flashcard Back Image is not valid'
                    });
                    error = true;
                  }
                }
                flashcard.back_images = backImages;
              } else {
                this.importErrors.push({
                  item: index + 2,
                  text: 'Flashcard Back Images are missing'
                });
                error = true;
              }
            }
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Flashcard Type is missing or wrong'
            });
            error = true;
          }
          flashcard.createdAt = new Date();
          flashcard.from_admin = true;
          console.log(error);
          if (!error) {
            await this.service
              .create(flashcard)
              .then(() => {
                this.added += 1;
                return true;
              })
              .catch((e) => {
                this.importErrors.push({
                  item: index + 2,
                  text: e
                });
              });
          }
        }
        this.isUploading = false;
        this.isUploaded = true;
      }
    },
    cancelUpload() {
      if (this.isUploaded) {
        document.getElementById('refresh').click();
      }
      this.uploadFile = null;
      this.isUploaded = false;
      this.isUploading = false;
      this.showFileUpload = false;
    },
    newImport() {
      this.flashcards = [];
      this.uploadFile = null;
      this.importErrors = [];
      this.isUploading = false;
      this.isUploaded = false;
    },
    async download() {
      let name, blob;
      const csv = this.toCSV(this.decks);
      name = 'decks.csv';
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'Deck Name,Deck ID,Is Premium,Is live\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        array[i].name = array[i].name.replaceAll(',', ' ');
        line +=
          array[i].name +
          ',' +
          array[i].id +
          ',' +
          array[i].is_premium +
          ',' +
          array[i].is_live;
        line += '\r\n';

        str += line;
      }

      return str;
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
