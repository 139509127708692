var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Active Categories")]),_c('v-tab',[_vm._v("InActive Categories")])],1),_c('data-table',{key:_vm.tab,attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Chapters","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('roadmap-chapters:view') : false,"allow-filters":"","delete-handler":_vm.getUser()
          ? _vm.getUser().scopes.includes('roadmap-chapters:delete')
            ? _vm.service.delete
            : null
          : null,"edit-handler":_vm.getUser()
          ? _vm.getUser().scopes.includes('roadmap-chapters:edit')
            ? _vm.edit
            : null
          : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()},"filter":_vm.openFilter},scopedSlots:_vm._u([{key:"category",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCategoryName(item.category))+" ")]}},{key:"active",fn:function(ref){
          var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","readonly":"","hide-details":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.showRearragementDialog),callback:function ($$v) {_vm.showRearragementDialog=$$v},expression:"showRearragementDialog"}},[_c('v-card',{staticStyle:{"padding":"40px"}},[_c('v-form',{ref:"rearragementDialog"},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Move Chapter at","items":_vm.indexes,"item-text":"label","rules":[_vm.required()]},model:{value:(_vm.newIndex),callback:function ($$v) {_vm.newIndex=$$v},expression:"newIndex"}}),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.saveOrder}},[_vm._v("Submit ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"40%"},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('v-card',[_c('v-card-title',[_vm._v("Filter By Field")]),_c('div',{staticClass:"px-6 pt-2 pb-6"},[_c('v-select',{attrs:{"items":_vm.categories,"clearable":"","item-text":"name","item-value":"id","dense":"","label":"Category","outlined":"","placeholder":"Select Category"},model:{value:(_vm.filterSelectedAttr.category),callback:function ($$v) {_vm.$set(_vm.filterSelectedAttr, "category", $$v)},expression:"filterSelectedAttr.category"}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.cancelFilter}},[_vm._v("Cancel")]),_c('div',[_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.filterClear}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.applyFilter}},[_vm._v("Apply ")])],1)],1)],1)],1)],1),_c('loading-dialog',{attrs:{"message":"Updating ..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }