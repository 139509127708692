<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Passage Question Prompts"
      :allow-add="
        getUser() ? getUser().scopes.includes('passage-prompts:new') : false
      "
      @add-new="addNew"
      @done="$router.back()"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('passage-prompts:delete')
            ? service.delete
            : null
          : null
      "
      :edit-handler="
        getUser()
          ? getUser().scopes.includes('passage-prompts:edit')
            ? edit
            : null
          : null
      "
    >
      <template #prompt="{ item }">
        <v-tooltip bottom max-width="800" color="primary">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{
                item.prompt.length > 100
                  ? item.prompt.substring(0, 100) + '...'
                  : item.prompt
              }}
            </span>
          </template>
          <span>{{ item.prompt }}</span>
        </v-tooltip>
      </template>
    </data-table>

    <loading-dialog v-model="loadingDialog" message="Sending Notification" />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { getUser } from '@/utils/local';
import { PassagePromptsService } from '@/services/passage-prompts-service';

export default {
  components: { LoadingDialog, DataTable },

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new PassagePromptsService(),

    headers: [
      {
        text: 'Title',
        value: 'title'
      },
      {
        text: 'Prompt',
        value: 'prompt'
      }
    ]
  }),

  methods: {
    getUser,
    edit(item) {
      this.$router.push(`/passage-prompt?id=${item.id}`);
    },
    addNew() {
      this.$router.push('/passage-prompt');
    },
    loadData() {
      return this.service.fetchAll();
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
