<template>
  <div>
    <div class="generator">
      <p class="generator__title span-2">Generate new Question (Beta)</p>

      <v-text-field
        v-model="query.topic"
        dense
        label="Topic name"
        outlined
        class=""
      />

      <v-text-field
        v-model="query.subTopic"
        dense
        label="Sub-topic name"
        outlined
        class=""
      />

      <div class="d-flex">
        <v-select
          v-model="query.type"
          :items="types"
          :rules="[required('Select the question type')]"
          class="mr-2"
          dense
          item-text="title"
          item-value="value"
          label="Type"
          outlined
          @change="query.type = $event"
        />

        <v-select
          v-model="query.count"
          :items="questionCounts"
          :rules="[required('Select a Count')]"
          dense
          item-text="title"
          item-value="value"
          label="Question Count"
          outlined
          @change="query.count = $event"
        />
      </div>

      <div class="d-flex">
        <v-select
          v-model="query.difficulty"
          :items="difficultyLevels"
          :rules="[required('Select the difficulty level')]"
          dense
          class="mr-2"
          item-text="title"
          item-value="value"
          label="Difficulty Level"
          outlined
          @change="query.difficulty = $event"
        />
      </div>

      <div class="d-flex span-2">
        <v-textarea
          v-model="prompt"
          dense
          label="Prompt"
          outlined
          append-icon="mdi-refresh"
          @click:append="
            prompt = `write ${query.count} ${query.difficulty} ${query.type} MCAT question on ${query.topic} written at the college graduate level. write an extensive explanation about ${query.subTopic} and why the correct answer is correct.`
          "
          auto-grow
        />
      </div>

      <div class="d-flex span-2 ml-auto mb-4">
        <v-btn color="primary" @click="generateQuestion(false)" elevation="0"
          >Generate</v-btn
        >
      </div>

      <loading-dialog v-model="loading" :message="loadingMsg" />
      <ErrorDialog v-model="error" :error="errorVal" @value="$router.back()" />
    </div>

    <QuestionsPreview
      class="span-2"
      :payload="payload"
      :query="query"
      @error="validationFailed"
    />
  </div>
</template>

<script>
import { required } from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '@/components/ErrorDialog';
// import { openAiApiKey } from '@/utils/local';
// import { Configuration, OpenAIApi } from 'openai';
import QuestionsPreview from '@/components/questions/QuestionsPreview.vue';

export default {
  name: 'QuestionIdeas',
  components: { ErrorDialog, LoadingDialog, QuestionsPreview },

  data: () => ({
    data: null,
    prompt: '',
    query: {
      topic: 'Biology',
      subTopic: 'Cells',
      count: 10,
      type: 'mcqs',
      options: 4,
      difficulty: 'extreme tough'
    },
    loading: false,
    loadingMsg: 'Generating Question...',
    error: false,
    errorVal: {}
  }),

  watch: {
    'query.type'(newValue) {
      this.query['options'] = newValue === 'mcq' ? 4 : 1;
    },

    query: {
      handler() {
        this.prompt = `write ${this.query.count} ${this.query.difficulty} ${this.query.type} MCAT question on ${this.query.topic} written at the college graduate level. write an extensive explanation about ${this.query.subTopic} and why the correct answer is correct.`;
      },
      deep: true
    }
  },

  computed: {
    payload() {
      return this.data || [];
    },

    questionCounts() {
      return [
        { title: '1', value: 1 },
        { title: '2', value: 2 },
        { title: '3', value: 3 },
        { title: '4', value: 4 },
        { title: '5', value: 5 },
        { title: '6', value: 6 },
        { title: '7', value: 7 },
        { title: '8', value: 8 },
        { title: '9', value: 9 },
        { title: '10', value: 10 }
      ];
    },

    types() {
      return [
        { title: 'MCQ', value: 'mcqs' },
        { title: 'Choices', value: 'choices' }
      ];
    },

    difficultyLevels() {
      return [
        { title: 'Easy', value: 'easy' },
        { title: 'Tough', value: 'extreme tough' }
      ];
    }
  },

  mounted() {
    this.prompt = `write ${this.query.count} ${this.query.difficulty} ${this.query.type} MCAT question on ${this.query.topic} written at the college graduate level. write an extensive explanation about ${this.query.subTopic} and why the correct answer is correct.`;
  },

  methods: {
    required,

    validationFailed() {
      this.loadingMsg = 'Trying again...';
      this.generateQuestion(true);
    },

    async generateQuestion(isTryAgain) {
      if (!isTryAgain) {
        this.loadingMsg = 'Generating Question...';
      }

      this.loading = true;

      const response = await fetch(
        'https://us-central1-kings-of-the-curve-sonal.cloudfunctions.net/getOpenAIResponse/kings-of-the-curve-sonal/us-central1/getOpenAIResponse?type=' +
          this.query.type,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            prompt: this.prompt
          })
        }
      );

      const data = await response.json();

      if (data && data.result) {
        this.data = data.result;
      } else {
        this.data = data;
      }

      this.loading = false;
    },

    async submit() {
      await this.generateQuestion();
    }
  }
};
</script>

<style scoped lang="scss">
.generator {
  display: grid;
  max-width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  background-color: white;
  border-radius: 8px;
  padding: 20px 20px 0;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);

  &__title {
    font-weight: normal !important;
    font-size: 27px;
    padding-bottom: 20px;
    text-align: center !important;
    font-family: google-sans, sans-serif;
  }
}
</style>
