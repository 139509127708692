<template>
  <v-card>
<!--    <v-tabs-->
<!--        class="mb-4"-->
<!--        v-model="tab"-->
<!--        color="primary"-->
<!--    >-->
<!--      <v-tab v-for="(type, index) in types" :key="index">-->
<!--        {{ type }}-->
<!--      </v-tab>-->
<!--    </v-tabs>-->

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <all-notifications/>
      </v-tab-item>

      <v-tab-item>
        <mcat-metrics-notifications/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AllNotifications from '../../components/notifications/AllNotifications'
import McatMetricsNotifications from '../../components/notifications/McatMetricsNotifications'

export default {
  name: 'Notifications',
  components: {AllNotifications, McatMetricsNotifications},

  data: () => ({
    tab: 0,
    types: ['All', 'MCAT Metrics'],
  }),

};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
