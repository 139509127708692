import { render, staticRenderFns } from "./NotificationsView.vue?vue&type=template&id=69be607f&scoped=true&"
import script from "./NotificationsView.vue?vue&type=script&lang=js&"
export * from "./NotificationsView.vue?vue&type=script&lang=js&"
import style0 from "./NotificationsView.vue?vue&type=style&index=0&id=69be607f&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69be607f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VTabItem,VTabsItems})
