var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.getUser().scopes.includes('studentGroups:all') ? _vm.headers1 : _vm.headers2,"title":"Student Groups","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('studentGroups:new') : false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('studentGroups:delete')
          ? _vm.deleteItem
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('studentGroups:edit')
          ? _vm.edit
          : null
        : null,"view-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('studentGroups:view')
          ? _vm.view
          : null
        : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"extra-actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary","title":"Leaderboard"},on:{"click":function($event){return _vm.showLeaderboard(item)}}},[_vm._v(" mdi-trophy ")]),_c('v-icon',{attrs:{"small":"","color":"primary","title":"Stats"},on:{"click":function($event){return _vm.showStats(item)}}},[_vm._v(" mdi-chart-bar ")])]}},{key:"createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"admins",fn:function(ref){
        var item = ref.item;
return _vm._l((item.admins),function(admin,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(admin)+" ")])})}}])}),_c('loading-dialog',{attrs:{"message":"Sending Notification"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }