var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-tabs',{on:{"change":function($event){_vm.filterSelectedAttr = { category: '' }}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Active Categories")]),_c('v-tab',[_vm._v("InActive Categories")])],1),_c('data-table',{key:_vm.dataTableKey,attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Flashcards","allow-add":_vm.getUser()
          ? _vm.getUser().scopes.includes('roadmap-flashcards:view')
          : false,"allow-filters":"","delete-handler":_vm.getUser()
          ? _vm.getUser().scopes.includes('roadmap-flashcards:delete')
            ? _vm.service.deleteFlashcard
            : null
          : null,"edit-handler":_vm.getUser()
          ? _vm.getUser().scopes.includes('roadmap-flashcards:edit')
            ? _vm.edit
            : null
          : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()},"filter":_vm.openFilter}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('v-card',[_c('v-card-title',[_vm._v("Filter By Field")]),_c('div',{staticClass:"px-6 pt-2 pb-4"},[_c('v-select',{attrs:{"items":_vm.categories,"clearable":"","item-text":"name","item-value":"id","dense":"","label":"Category","outlined":"","placeholder":"Select Category"},model:{value:(_vm.filterSelectedAttr.category),callback:function ($$v) {_vm.$set(_vm.filterSelectedAttr, "category", $$v)},expression:"filterSelectedAttr.category"}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"mr-2",attrs:{"elevation":"0"},on:{"click":_vm.cancelFilter}},[_vm._v("Cancel")]),_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.filterClear}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.applyFilter}},[_vm._v("Apply")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }