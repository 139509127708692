import axios from 'axios';

export class UserContributionService {
    async fetchAll(status) {
        return (await axios.get('/contributed-questions/new/status/' + status)).data;
    }
    async fetchOne(id) {
        return (await axios.get('/contributed-questions/new/' + id)).data;
    }
    async update(question) {
        return (await axios.patch('/contributed-questions/new', question)).data;
    }
    async updateStatus(status, id) {
        return (await axios.patch('/contributed-questions/new/'+ id + '/status/' + status)).data;
    }
}
