var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Roadmap Suggestions","allow-add":false,"view-handler":_vm.view},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"chapter",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chapter_id.name)+" ")]}},{key:"resolve",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateData(item)}},model:{value:(item.resolve),callback:function ($$v) {_vm.$set(item, "resolve", $$v)},expression:"item.resolve"}})]}},{key:"suggestion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.suggestion.length > 50 ? item.suggestion.substring(0, 50) + '...' : item.suggestion)+" ")]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}])}),(_vm.showDialog)?_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticStyle:{"padding":"20px","max-height":"250px","overflow":"scroll"}},[_c('h4',[_vm._v("Chapter:")]),_c('p',[_vm._v(_vm._s(_vm.currentSuggestion.chapter_id.name))]),_c('h4',[_vm._v("Suggestion:")]),_c('p',[_vm._v(_vm._s(_vm.currentSuggestion.suggestion))]),_c('h4',[_vm._v("Resolved:")]),_c('p',[_vm._v(_vm._s(_vm.currentSuggestion.resolve ? 'Yes' : 'No'))])])],1):_vm._e(),_c('loading-dialog',{attrs:{"message":"Updating ..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }