var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('data-table',{key:_vm.dataTableKey,attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Offers","allow-add":_vm.getUser().scopes.includes('iaps-offers:new'),"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('iaps-offers:delete')
          ? _vm.service.delete
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('iaps-offers:edit')
          ? _vm.edit
          : null
        : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([(!(_vm.$route.query.p && _vm.$route.query.p == 1))?{key:"primary-action",fn:function(){return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","elevation":"0","outlined":""},on:{"click":function($event){return _vm.toggleStatus(false)}}},[_vm._v(" Disable ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","elevation":"0","outlined":""},on:{"click":function($event){return _vm.toggleStatus(true)}}},[_vm._v(" Enable ")])]},proxy:true}:null,{key:"active",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":"","readonly":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"expiry",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expiry))+" ")]}}],null,true)}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.editLoading),callback:function ($$v) {_vm.editLoading=$$v},expression:"editLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }