<template>
  <div>
    <data-table
      :hasElevation="false"
      :allow-add="false"
      :headers="headers"
      :loader="loadData"
      title="Cheating Customers"
    >
      <template #primary-action>
        <v-btn
          v-if="
            getUser() ? getUser().scopes.includes('customers:export') : false
          "
          class="mr-3"
          color="primary"
          elevation="0"
          @click="exportCustomers()"
          >Export All
        </v-btn>
      </template>

      <template #actions="{ item }">
        <v-icon
          title="View Details"
          v-if="
            getUser() ? getUser().scopes.includes('customer:detail') : false
          "
          color="primary"
          small
          @click="detail(item)"
          >mdi-eye
        </v-icon>

        <v-icon
          title="Unban Customer"
          v-if="
            (getUser() ? getUser().scopes.includes('customer:unban') : false) &&
            item.banned
          "
          @click="unBan(item)"
          color="success"
          small
          >mdi-check-circle
        </v-icon>
      </template>

      <template #screenshot="{ item }">
        <v-checkbox
          hide-details
          dense
          v-html="item.screenshot.length"
          readonly
        />
      </template>

      <template #banned="{ item }">
        <v-checkbox v-model="item.banned" hide-details dense readonly />
      </template>
    </data-table>

    <loading-dialog v-model="loading" message="Loading..." />
  </div>
</template>
<script>
import DataTable from '../DataTable';
import { getUser } from '@/utils/local';
import { dateValidation, required } from '@/utils/validators';
import LoadingDialog from '@/components/LoadingDialog';
import moment from 'moment';
import { CustomerService } from '@/services/customer-service';

export default {
  name: 'CheatingCustomers',
  components: { LoadingDialog, DataTable },

  data: () => ({
    items: [],
    loading: false,
    headers: [
      {
        text: 'Display Name',
        value: 'name'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Screenshots',
        value: 'screenshot'
      },
      {
        text: 'Banned',
        value: 'banned'
      },
      {
        width: 120,
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ],
    service: new CustomerService()
  }),

  methods: {
    getUser,
    required,
    dateValidation,

    isBanned(item) {
      return (
        new Date(item.screenshot[item.screenshot.length - 1].penalty_date) >
        new Date()
      );
    },

    refresh() {
      let btns = document.getElementsByClassName('refresh');
      for (let i = 0; i < btns.length; i++) {
        btns[i].click();
      }
    },

    async loadData() {
      try {
        this.items = await this.service.fetchAllCheatingCustomers();
        this.items = this.items.map((item) => {
          if (item.count === 0) {
            item.banned = false;
          } else {
            item.banned = this.isBanned(item);
          }
          return item;
        });

        return this.items;
      } catch (e) {
        console.log(e, 'error');
      }
    },

    async unBan(user) {
      let confirmed = confirm('Are you sure you want to unban this customer?');
      if (confirmed) {
        await this.service.unbanCustomer(user.user_id);
        this.refresh();
      }
    },

    async exportCustomers() {
      this.loading = true;
      let csv = this.toCSV(this.items);
      const name = 'customers.csv';
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false;
    },
    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'Name,Email\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        if (array[i].name) {
          array[i].name = array[i].name.replaceAll(',', ' ');
          line += array[i].name;
        }
        line += ',' + array[i].email;
        line += '\r\n';

        str += line;
      }

      return str;
    },

    async detail(item) {
      window.open('/customer-detail?cheating_stats=true&id=' + item.user_id);
    },

    formatDate(date) {
      return moment(date).format('dddd, MMMM Do YYYY');
    }
  }
};
</script>

<style>
.v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  background: transparent !important;
  transform: none;
  transition: none;
}

.v-ripple__container {
  display: none !important;
}

.v-input--selection-controls__ripple,
.v-input--selection-controls .v-input__slot,
.v-input--selection-controls .v-radio {
  cursor: default;
}
</style>
