var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Institutions","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('institutions:new') : false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('institutions:delete')
          ? _vm.service.delete
          : null
        : null,"edit-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('institutions:edit')
          ? _vm.openEditor
          : null
        : null},on:{"add-new":_vm.openEditor,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"logo",fn:function(ref){
        var item = ref.item;
return [(item.logo)?_c('img',{style:({
          background: 'white',
          border: '2px solid white',
          borderRadius: '50px',
          objectFit: 'cover'
        }),attrs:{"src":item.logo,"alt":"Default Logo","height":"50","loading":"lazy","width":"50"}}):_c('p',{staticClass:"ma-0"},[_vm._v("No Logo")])]}},{key:"isVerified",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(item.is_verified),callback:function ($$v) {_vm.$set(item, "is_verified", $$v)},expression:"item.is_verified"}})]}},{key:"domains",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.domains.length)+" ")]}},{key:"primary-action",fn:function(){return [_c('v-checkbox',{staticClass:"mr-3",attrs:{"hide-details":"","label":"Show Unverified","dense":""},on:{"change":_vm.fetchData},model:{value:(_vm.unverified),callback:function ($$v) {_vm.unverified=$$v},expression:"unverified"}}),_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"0","color":"primary","outlined":""},on:{"click":_vm.mergeInstitutes}},[_vm._v("Merge")])]},proxy:true},{key:"extra-actions",fn:function(ref){
        var item = ref.item;
return [(
          !item.is_verified &&
          _vm.getUser() &&
          _vm.getUser().scopes.includes('institutions:edit')
        )?_c('v-icon',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.verifyInstitution(item)}}},[_vm._v("mdi-check ")]):_vm._e(),(
          item.is_verified &&
          _vm.getUser() &&
          _vm.getUser().scopes.includes('institutions:edit')
        )?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.unVerifyInstitution(item)}}},[_vm._v("mdi-close ")]):_vm._e(),_c('v-icon',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.getInstituteUsers(item)}}},[_vm._v("mdi-account-multiple")])]}}])}),_c('loading-dialog',{attrs:{"message":"Loading ..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('error-dialog',{attrs:{"error":_vm.errorVal},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}}),_c('InstitutionForm',{attrs:{"institution":_vm.institution,"is-edit":_vm.isEdit},on:{"value":_vm.closeEditor},model:{value:(_vm.editor),callback:function ($$v) {_vm.editor=$$v},expression:"editor"}}),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.usersDialog),callback:function ($$v) {_vm.usersDialog=$$v},expression:"usersDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('h3',[_vm._v("Users associated with "+_vm._s(_vm.instituteName))]),_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.userHeaders,"dense":""},scopedSlots:_vm._u([{key:"item.isPremium",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(item.is_premium),callback:function ($$v) {_vm.$set(item, "is_premium", $$v)},expression:"item.is_premium"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }