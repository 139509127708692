var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"hasElevation":false,"allow-add":false,"headers":_vm.headers,"loader":_vm.loadData,"title":"Cheating Customers"},scopedSlots:_vm._u([{key:"primary-action",fn:function(){return [(
          _vm.getUser() ? _vm.getUser().scopes.includes('customers:export') : false
        )?_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.exportCustomers()}}},[_vm._v("Export All ")]):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
        var item = ref.item;
return [(
          _vm.getUser() ? _vm.getUser().scopes.includes('customer:detail') : false
        )?_c('v-icon',{attrs:{"title":"View Details","color":"primary","small":""},on:{"click":function($event){return _vm.detail(item)}}},[_vm._v("mdi-eye ")]):_vm._e(),(
          (_vm.getUser() ? _vm.getUser().scopes.includes('customer:unban') : false) &&
          item.banned
        )?_c('v-icon',{attrs:{"title":"Unban Customer","color":"success","small":""},on:{"click":function($event){return _vm.unBan(item)}}},[_vm._v("mdi-check-circle ")]):_vm._e()]}},{key:"screenshot",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},domProps:{"innerHTML":_vm._s(item.screenshot.length)}})]}},{key:"banned",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(item.banned),callback:function ($$v) {_vm.$set(item, "banned", $$v)},expression:"item.banned"}})]}}])}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }