var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Passage Questions of the Day","allow-add":false,"delete-handler":null,"edit-handler":_vm.getUser()
      ? _vm.getUser().scopes.includes('scheduledQotd:edit')
        ? _vm.edit
        : null
      : null},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"day",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"font-weight-black body-1"},[_vm._v(_vm._s(item.day))])]}},{key:"heading",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",staticStyle:{"max-width":"300px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.heading)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.heading))])])]}},{key:"passage",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",staticStyle:{"max-width":"300px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.passage)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.passage))])])]}},{key:"updatedAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }