var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0 height-full"},[_c('div',{staticClass:"d-flex justify-space-between items-center mb-2"},[_c('v-card-title',{staticClass:"d-flex flex-column flex-md-row justify-start align-start",style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'gap: 10px')},[_c('span',{staticClass:"data-table__header"},[_vm._v("Video Section")])]),_c('div',{staticClass:"px-4"},[_c('v-btn',{staticClass:"mr-2 btn-text",attrs:{"color":"green","elevation":"0","disabled":_vm.loading},on:{"click":_vm.saveArrangedItems}},[_c('v-icon',{staticClass:"v-btn__pre-icon",attrs:{"small":""}},[_vm._v("mdi-content-save ")]),_vm._v("  "+_vm._s(_vm.loading ? 'Saving...' : 'Save Changes')+" ")],1),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.openMediaPickerModal}},[_vm._v(" "+_vm._s(_vm.items.length ? 'Manage Videos' : 'Add Videos')+" ")]),_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.$router.push('/video-section-modal')}}},[_vm._v("mdi-filter ")])],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"d-flex items-center justify-space-around border-bottom pb-3 px-2"},[_vm._l((_vm.headers),function(header){return _c('h2',{key:header,staticClass:"flex-1 column-header"},[_vm._v(" "+_vm._s(header)+" ")])}),_c('h2',{staticClass:"column-header"},[_vm._v("Actions")])],2),(_vm.items.length)?_c('Container',{on:{"drop":_vm.onDrop}},_vm._l((_vm.items),function(item,idx){return _c('Draggable',{key:item.id + idx},[_c('div',{class:((item.delete_item
                ? 'delete-row'
                : item.newly_added
                ? 'newly_added'
                : '') + "\n                d-flex items-center item-row border-bottom px-2")},[_c('p',{staticClass:"flex-1"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm.category.name))]),_c('p',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm.subCategory.name))]),_c('div',{staticClass:"mx-3 delete-btn"},[_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function () {
                    _vm.video = item;
                    _vm.show = true;
                  }}},[_vm._v("mdi-eye ")])],1)])])}),1):_vm._e(),(_vm.items.length === 0)?_c('div',{staticClass:"d-flex justify-center align-center height-full"},[_vm._v(" No Data Found ")]):_vm._e()],1)],1),(_vm.video)?_c('v-dialog',{attrs:{"persistent":"","width":"40%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.video.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('video',{attrs:{"width":"100%","height":"100%","controls":"","autoplay":"","src":_vm.video.url}})])],1)],1):_vm._e(),_c('MediaPicker',{attrs:{"id":"mediaPickerBtn","selectedData":_vm.selectedData,"select-complete-item":true},on:{"SetNewSelectedData":_vm.SetNewSelectedData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }