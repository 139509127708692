<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Passage Questions"
      :allow-add="false"
      allow-filters
      @filter="openFilter = true"
      :key="dataTableKey"
      @click:row="handleEvent"
    >
      <template v-slot:createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:passage="{ item }">
        {{ item.passage.substring(0, 100) }}
      </template>
      <template #premium="{ item }">
        <v-checkbox dense v-model="item.premium" readonly hide-details />
      </template>
      <template #active="{ item }">
        <v-checkbox dense v-model="item.active" readonly hide-details />
      </template>
    </data-table>

    <v-dialog v-model="openFilter" width="500">
      <v-card>
        <v-card-title>Filter</v-card-title>
        <v-card-text>
          <v-select
            v-model="category"
            :items="categories"
            item-text="name"
            return-object
            label="Category"
            outlined
            dense
            @change="subCategories = category.subCategories"
          ></v-select>
          <v-select
            v-model="subCategory"
            :items="subCategories"
            item-text="name"
            return-object
            label="Sub Category"
            outlined
            dense
            clearable
            @click:clear="subCategory = ''"
            :disabled="!category"
          ></v-select>

          <v-checkbox
            v-model="filterByPremium"
            label="Premium"
            hide-details
            dense
            class="mt-n2"
          ></v-checkbox>

          <v-checkbox
            v-model="filterByActive"
            label="Active"
            hide-details
            dense
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="clearFilters()" color="error" outlined>Clear</v-btn>

          <v-spacer></v-spacer>
          <v-btn @click="openFilter = false" color="primary" outlined
            >Cancel</v-btn
          >
          <v-btn @click="applyFilters()" color="primary" elevation="0"
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Loading..." />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import LoadingDialog from '@/components/LoadingDialog';
import moment from 'moment';
import { PassageQuestionsService } from '@/services/passage_questions-service';
import { QuestionsService } from '@/services/questions-service';
import { CategoryService } from '@/services/category-service';

export default {
  components: { LoadingDialog, DataTable },

  props: {
    isActiveCategory: {
      type: Boolean,
      default: true
    }
  },

  async mounted() {
    await this.loadCategories();
  },

  data: () => ({
    dataTableKey: 0,
    openFilter: false,
    loading: false,
    service: new PassageQuestionsService(),
    questionsService: new QuestionsService(),
    categoryService: new CategoryService(),

    headers: [
      {
        text: 'Passage Title',
        value: 'heading'
      },
      {
        text: 'Question Passage',
        value: 'passage',
        sortable: false
      },
      {
        text: 'Category',
        value: 'categoryName'
      },
      {
        text: 'Sub Category',
        value: 'subCategoryName'
      },
      {
        text: 'Premium',
        value: 'premium'
      },
      {
        text: 'Active',
        value: 'active'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      }
    ],

    filterByPremium: false,
    filterByActive: false,
    category: '',
    subCategories: [],
    subCategory: '',
    categories: [],
    items: []
  }),

  methods: {
    getUser,
    required,

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY - hh:mm A');
    },

    handleEvent(item) {
      this.$emit('click:row', item);
    },

    async loadCategories() {
      this.categories = this.isActiveCategory
        ? await this.categoryService.fetchAll()
        : await this.categoryService.fetchAllInactive();
    },

    clearFilters() {
      this.category = '';
      this.subCategory = '';
      this.filterByPremium = false;
      this.filterByActive = false;
      this.openFilter = false;
      this.dataTableKey += 1;
    },

    applyFilters() {
      this.openFilter = false;
      this.dataTableKey += 1;
    },

    async loadData() {
      this.items = await this.service.fetchAll({
        inactiveCategory: this.isActiveCategory === true ? false : true,
        category: this.category?.id,
        subCategory: this.subCategory?.id,
        premium: this.filterByPremium ? 'true' : '',
        active: this.filterByActive ? 'true' : ''
      });

      return (this.items = this.items.map((item) => {
        let category = this.categories.find((x) => x.id === item.category) || {
          name: 'No Category',
          subCategories: []
        };
        let subCategory = category.subCategories.find(
          (x) => x.id === item.sub_category
        ) || { name: 'No Sub-Category' };
        item.category = category;
        item.categoryName = category.name;
        item.sub_category = subCategory;
        item.subCategoryName = subCategory.name;

        return item;
      }));
    }
  }
};
</script>

<style scoped></style>
